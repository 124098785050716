import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks'
import Button from '../../../ui/molecules/button'
import Input from '../../../ui/molecules/input'
import { SelectCustom } from '../../../ui/molecules/select'
import Toggle from '../../../ui/molecules/toggle'
import { normativaCategories } from '../../../utils'
import { EntryProtocol } from '../../protocol/entry'
import { EntryProtocolAttachmentCreation, setEPAttBio, setEPAttCoding, setEPAttDeliberationGeneric, setEPAttDeliberationGG26, setEPAttDeliberationM24, setEPAttDescription, setEPAttGenerics, setEPAttGlobalGap, setEPAttSqnpi, setEPDeliberationSQNPI } from '../../protocol/entry/slice'
import '../styles.scss'

export function AttachmentRight() {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)

    return (
        <div style={{ padding: '0px 24px 24px 24px' }}>
            <div className="text-left pb-3 pt-5">
                <span className="rb-title" >
                    Allegati
                </span>
            </div>

            <div className='pb-3'>
                <Input
                    label={'Descrizione'}
                    placeholder="Specifica descrizione..."
                    value={entryProtocolState.entryProtocolAttachmentCreationRequest.description}
                    defaultValue={entryProtocolState.entryProtocolAttachmentCreationRequest.description}
                    onChangeText={(text) => { dispatch(setEPAttDescription(text)) }}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Codifica'}
                    placeholder="Specifica codifica..."
                    value={entryProtocolState.entryProtocolAttachmentCreationRequest.coding}
                    defaultValue={entryProtocolState.entryProtocolAttachmentCreationRequest.coding}
                    onChangeText={(text) => { dispatch(setEPAttCoding(text)) }}
                />
            </div>

            <div className='pb-3'>
                <div className="text-left mb-3">
                    <span className="input-label">Presente in Cartella Aziendale</span>
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='Agricoltura biologica'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.bio}
                        onChange={(value) => { dispatch(setEPAttBio(value)) }}
                    />
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='Global G.A.P.'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.globalGap}
                        onChange={(value) => { dispatch(setEPAttGlobalGap(value)) }}
                    />
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='SQNPI'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.sqnpi}
                        onChange={(value) => { dispatch(setEPAttSqnpi(value)) }}
                    />
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='Generica'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.generics}
                        onChange={(value) => { dispatch(setEPAttGenerics(value)) }}
                    />
                </div>
            </div>

            <div className='pb-3'>
                <div className="text-left mb-3">
                    <span className="input-label">Delibera</span>
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='M24 - Agricoltura Biologica'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.deliberationM24}
                        onChange={(value) => { dispatch(setEPAttDeliberationM24(value)) }}
                    />
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='GG26 - Global G.A.P.'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.deliberationGG26}
                        onChange={(value) => { dispatch(setEPAttDeliberationGG26(value)) }}
                    />
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='SQNPI'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.deliberationSQNPI}
                        onChange={(value) => { dispatch(setEPDeliberationSQNPI(value)) }}
                    />
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='Generica'
                        checked={entryProtocolState.entryProtocolAttachmentCreationRequest.deliberationGeneric}
                        onChange={(value) => { dispatch(setEPAttDeliberationGeneric(value)) }}
                    />
                </div>
            </div>

            <div>
                <Button
                    variant='solid'
                    color='orange'
                    iconPosition='off'
                    size='md'
                    label='Aggiungi Allegato'
                    onClick={() => {
                        dispatch(EntryProtocolAttachmentCreation(entryProtocolState.entryProtocolAttachmentCreationRequest))
                    }
                    }
                />
            </div>

        </div>
    )
}