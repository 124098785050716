import { addTechinicianUserDTO } from "../businessTechnician/dto";
import { EditUserRequestDTO, FindAllUsersFilters, UserDTO, UserEditCreationDTO, } from "./dto";
import { UsersServiceImpl } from "./serviceImpl";

export interface UsersService {
    createUser(data: UserEditCreationDTO): Promise<void>
    findUserById(id: string): Promise<UserDTO>
    RemoveUser(id: string): Promise<void>
    editUser(id: string, data: UserEditCreationDTO): Promise<void>
    activateUser(id: string, data: UserEditCreationDTO): Promise<void>
    getUsers(filters: FindAllUsersFilters): Promise<any>
    linkBusinessTech(data: addTechinicianUserDTO): Promise<any>
    RemoveBusinessTechnician(id: string, techId: string): Promise<any>
    FindMe(): Promise<void>
}

export function NewUsersService(): UsersService {
    return new UsersServiceImpl();
}
