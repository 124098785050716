import axios from "axios";
import { CDXM026DTO, CDXM026DTOResponse, GetAllCDXM026ResponseDTO } from "./dto";
import { CDXM026Service } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class CDXM026ServiceImpl implements CDXM026Service {
    
    public GetAllCDXM026(): Promise<GetAllCDXM026ResponseDTO> {
        return axios.get("/api/pm-m026", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetCDXM026ById(id: string): Promise<CDXM026DTOResponse> {
        return axios.get("/api/pm-m026/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public CreateCDXM026(data: CDXM026DTO): Promise<void> {
        return axios.post("/api/pm-m026",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateCDXM026(data: CDXM026DTO, id: string): Promise<void> {
        return axios.put("/api/pm-m026/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteCDXM026(id: string): Promise<void> {
        return axios.delete("/api/pm-m026/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
}