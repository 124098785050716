import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { useNavigate } from "react-router-dom";
import { formatterDate } from "../../utils";
import { SpinnerComponent } from "../../lib/spinner";
import { ActiveIcon } from "../../ui/icons/activeIcon";
import { InactiveIcon } from "../../ui/icons/inactiveIcon";
import { Dropdown } from "../../ui/organisms/dropdown";
import { EditIcon } from "../../ui/icons/edit";
import { TrashIcon } from "../../ui/icons/trash";
import { GetBusinessTechnicianById } from "./slice";


export function ListBusinessTech() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const businessTechinician = useAppSelector(state => state.businessTechinician)
    const [showList, setShowList] = useState<boolean>(false);

    useEffect(() => {
        if (businessTechinician.businessTechinicianResponseStatus === 'successfully') {
            setShowList(true)
        }
    }, [businessTechinician.businessTechinicianResponseStatus]);

    if (businessTechinician.businessTechinicianResponseStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (

        <div  >
            <table id="customers-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr className="bg-brandPrimary-600">
                        <th style={{ borderTopLeftRadius: '8px' }}>Nominativo</th>
                        <th>Cod. Fiscale</th>
                        <th>P.IVA</th>
                        <th>Regione</th>
                        <th>Data Ingresso</th>
                        <th>Data Uscita</th>
                        <th style={{ textAlign: 'center' }}>Status</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {businessTechinician.businessTechinicianResponse?.data.map(tech =>
                        <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                        >
                            <td className="text-blue-800 robotoBold text-left">
                                {tech.companyName}
                            </td>
                            <td className="text-neutral-700" style={{ fontFamily: 'Manrope', fontSize: '14px', fontWeight: '600', lineHeight: '20px' }}>
                                {tech.fiscalCode}
                            </td>
                            <td className="text-neutral-700">
                                {tech.vat}
                            </td>
                            <td className="text-neutral-700">
                                {tech.countryRegion}
                            </td>
                            <td className="text-neutral-700">
                                {formatterDate(tech.activationDate)}
                            </td>
                            <td className="text-neutral-700">
                                {formatterDate(tech.deactivationDate)}
                            </td>

                            <td className="text-neutral-700">
                                {
                                    !tech.deactivationDate ?
                                        (
                                            <ActiveIcon></ActiveIcon>
                                        )
                                        :
                                        (
                                            <InactiveIcon></InactiveIcon>
                                        )
                                }
                            </td>

                            <td className="w-[20px] cursor-pointer">
                                <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                                    <Dropdown items={[
                                        {
                                            label: 'Modifica',
                                            icon: <EditIcon color={""} size={0} />,
                                            onClick: () => {
                                                dispatch(GetBusinessTechnicianById(tech.id))
                                                navigate('/editTecnicoAziendale/' + tech.id)
                                            }

                                        },
                                        {
                                            label: 'Disattiva',
                                            icon: <TrashIcon color={""} size={0} />,
                                            onClick: () => {
                                                //navigate('/edit-customer/' + customer.userId)
                                            }
                                        }

                                    ]} />
                                </div>
                            </td>

                        </tr>
                    )}
                </tbody>

            </table>
        </div >

    )
}