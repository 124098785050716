import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { Fragment } from "react"
import Button from "../../../ui/molecules/button";
import { SelectCustom } from "../../../ui/molecules/select";
import { destinationOffice, entryMeansOptions, sot, yearOptions } from "../../../utils";
import Input from "../../../ui/molecules/input";
import { GetDistinta, setAttachmentsReport, setDestinationOfficeReport, setEndNumberReport, setEntryChannelReport, setHeadQuarterReport, setStartNumberReport, setYearReport } from "../entry/slice";

interface Props {
    close: () => void;
    attachments: boolean
}   

export function FormDistintaEntrata(props: Props) {
    const dispatch = useAppDispatch()
    if(props.attachments) {
        dispatch(setAttachmentsReport(true))
    }
    else {
        dispatch(setAttachmentsReport(false))
    }
    
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>

                <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Sede/Ufficio</span>
                        </div>
                        <SelectCustom
                            placeholder={'Sede/Ufficio'}
                            options={sot}
                            onChange={(value) => dispatch(setHeadQuarterReport(value))}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            placeholder={'Anno'}
                            options={yearOptions}
                            onChange={(value) => dispatch(setYearReport(value))}
                        />
                    </div>
                </div>
                <div className="formAnagrafica mt-4" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <Input
                            label={'N. Protocollo iniziale'}
                            placeholder="Specificare numero"
                            onChangeText={(text) => { dispatch(setStartNumberReport(text)) }}
                        />

                    </div>

                    <div style={{ flex: '0.5' }}>
                        <Input
                            label={'N. Protocollo finale'}
                            placeholder="Specificare numero"
                            onChangeText={(text) => { dispatch(setEndNumberReport(text)) }}
                        />

                    </div>
                </div>

                <div className="formAnagrafica mt-4" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Mezzo Entrata</span>
                        </div>
                        <SelectCustom
                            placeholder={'Mezzo Entrata'}
                            options={entryMeansOptions}
                            onChange={(value) => dispatch(setEntryChannelReport(value)) }
                        />

                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Destinatario consegna (Sede / Ufficio / WS)</span>
                        </div>
                        <SelectCustom
                            placeholder={'Destinatario'}
                            options={destinationOffice}
                            onChange={(value) => dispatch(setDestinationOfficeReport(value)) }
                        />
                    </div>
                </div>

                <div className="mt-4" style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                    <Button
                        size="md"
                        variant="outline"
                        iconPosition="off"
                        label="Annulla"
                        color="dimgrey"
                        onClick={() => {
                            props.close();
                        }}
                    />
                    <div className="pl-3">
                        <Button
                            size="md"
                            variant="solid"
                            iconPosition="off"
                            label="Stampa"
                            color="orange"
                            onClick={() => {
                                dispatch(GetDistinta(entryProtocolState.reportRequestFilters))
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}