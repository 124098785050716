import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { ContractUpdate, ContractValidation, GetAllContracts, resetContractErrors, resetContractUpdateRequest, resetContractUpdateStatus, resetContractValidationStatus, setHeadQuarter, setKmIndennity, setNumber, setUpdateContractType, setUpdateEndingDate, setUpdateKmIndennity, setUpdateNumContract, setUpdateProtocolIdDeparture, setUpdateRevisionType, setUpdateStartingDate, setUpdateStipulate, setYearContract } from "../slice";
import { SelectCustom } from "../../../../ui/molecules/select";
import { CustomOptions, headquartersOptions, revisioneOptions, typologyOptions, yearOptions } from "../../../../utils";
import Input from "../../../../ui/molecules/input";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../../../../ui/icons/calendar";
import { format } from "date-fns";
import { CustomRadio } from "../../../../ui/molecules/radioButton";
import Button from "../../../../ui/molecules/button";
import { GetByIdPersonalManagement } from "../../slice";
import RadioButtonGroup from "../../../../ui/molecules/RadioButtonGroup";
import { Calendar } from "../../../../ui/molecules/calendar";
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch";

interface Props {
    close: () => void
}

export function ContrattiPopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const contractsState = useAppSelector(state => state.PMContracts)
    const PMState = useAppSelector(state => state.personalManagement)
    const [selectedOption, setSelectedOption] = useState<CustomOptions | null>(CustomOptions.NO);


    const handleOptionChange = (value: CustomOptions) => {
        setSelectedOption(value);
        if (value === 'YES') {
            dispatch(setUpdateKmIndennity(true))
        }
        else {
            dispatch(setUpdateKmIndennity(false))
        }
    };

    useEffect(() => {
        if(contractsState.contractUpdateRequest.kmIndennity) {
            setSelectedOption(CustomOptions.YES)
        } else {
            setSelectedOption(CustomOptions.NO)
        }
    }, [])

    useEffect(() => {
        if(contractsState.contractValidationStatus === 'successfully') {
            dispatch(resetContractValidationStatus())
            dispatch(resetContractErrors())
            dispatch(ContractUpdate({body: contractsState.contractUpdateRequest, id: contractsState.contractIdToUpdate}));
        }
    }, [contractsState.contractValidationStatus])

    useEffect(() => {
        if(contractsState.contractUpdateStatus === 'successfully') {
            dispatch(resetContractUpdateRequest())
            dispatch(resetContractUpdateStatus())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
            props.close()
        }
        
    }, [contractsState.contractUpdateStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>

                {/* FIRST ROW */}
                <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Tipologia</span>
                        </div>
                        <SelectCustom
                            error={contractsState.contractErrors.contractType}
                            errorLabel="Tipologia mancante"
                            placeholder={'Seleziona tipologia...'}
                            options={typologyOptions}
                            onChange={(value) => dispatch(setUpdateContractType(value))}
                            defaultValue={contractsState.contractUpdateRequest.contractType}
                            value={contractsState.contractUpdateRequest.contractType}
                            />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <Input
                            error={contractsState.contractErrors.numContract}
                            supportingText={contractsState.contractErrors.numContract ? 'Numero del contratto mancante' : undefined}
                            label={'N. contratto'}
                            placeholder="Digita numer contratto..."
                            value={contractsState.contractUpdateRequest.numContract}
                            defaultValue={contractsState.contractUpdateRequest.numContract}
                            onChangeText={(text) => {dispatch(setUpdateNumContract(text))}}
                            />
                    
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Revisione</span>
                        </div>
                        <SelectCustom
                            error={contractsState.contractErrors.revisionType}
                            errorLabel="Tipologia revisione mancante"
                            placeholder={'Seleziona...'}
                            options={revisioneOptions}
                            onChange={(value) => dispatch(setUpdateRevisionType(value))}
                            defaultValue={contractsState.contractUpdateRequest.revisionType}
                            value={contractsState.contractUpdateRequest.revisionType}
                            />
                    
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data stipula</span>
                        </div>
                        <Calendar
                            selected={contractsState.contractUpdateRequest.stipulate}
                            onChange={(date) => {
                                dispatch(setUpdateStipulate(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data inizio</span>
                        </div>
                        <Calendar
                            selected={contractsState.contractUpdateRequest.starting}
                            onChange={(date) => {
                                dispatch(setUpdateStartingDate(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data fine</span>
                        </div>
                        <Calendar
                            selected={contractsState.contractUpdateRequest.ending}
                            onChange={(date) => {
                                dispatch(setUpdateEndingDate(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.25', textAlign: 'left' }}>
                        <span className="input-label">Indennità chilometrica nel raggio di 50 km</span>
                    </div>
                    <div style={{ flex: '0.75', textAlign: 'left'  }}>
                    <div className="input-label" style={{ flex: '0.75', textAlign: 'left'  }}>
                        <RadioButtonGroup
                            name={'km_indennity'}
                            options={Object.values(CustomOptions)}
                            selectedValue={selectedOption}
                            onChange={handleOptionChange}
                            optionLabels={{
                                [CustomOptions.YES]: 'SI',
                                [CustomOptions.NO]: 'NO',
                            }}
                        />
                    </div>
                    </div>
                </div>

                {/* FOURTH ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{flex: '1'}}>
                        <ProtocolSearch
                            type="departure"
                            defaultValue={contractsState.contractUpdateRequest.protocolIdDeparture}
                            onSelect={(value) => dispatch(setUpdateProtocolIdDeparture(value))} 
                            />
                    </div>
                </div>

            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetContractErrors())
                        dispatch(resetContractUpdateRequest());
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(ContractValidation(contractsState.contractUpdateRequest))
                        }}
                        />
                </div>
            </div>
        </Fragment>
    )
}