import { Fragment } from "react/jsx-runtime"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { useEffect, useState } from "react"
import { SelectCustom } from "../../../../ui/molecules/select";
import { RowActivityMultiSelect, activityOptions, global_standards, global_standards_options_functions, headquartersOptions, normativa, regioniList, regioniListOperativeAreas, yearOptions } from "../../../../utils";
import DatePicker from "react-datepicker";
import Input from "../../../../ui/molecules/input";
import { CalendarIcon } from "../../../../ui/icons/calendar";
import MultiSelectCheckbox from "../../../../ui/molecules/multiSelectCheckbox";
import TextArea from "../../../../ui/molecules/textArea";
import Button from "../../../../ui/molecules/button";
import { FunctionUpdate, setUpdateAreeOperative, setUpdateDateNote, setUpdateEnding, setUpdateFunction, setUpdateMinisterialNote, setUpdateNote, setUpdateProtocolMinisterialRequestId, setUpdateProtocolRequestId, setUpdateRowsActivityGG, setUpdateScheme, setUpdateStarting } from "../slice";
import { format } from "date-fns";
import { RowActivity } from "./rowActivity";
import { Calendar } from "../../../../ui/molecules/calendar";
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch";


interface Props {
    close: () => void;
}

export function FunzioniPopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const functionsState = useAppSelector(state => state.PMFunctions)
    const PMState = useAppSelector(state => state.personalManagement)
    const [selectedArea, setSelectedArea] = useState<{ label: string; value: string }[]>([]);
    const [typeScheme, setTypeScheme] = useState<string | undefined>('');

    const [rows, setRows] = useState<RowActivityMultiSelect[]>([
        { ggStandard: "", ggIfaOption: "", startingActivity: "", endingActivity: "" },
    ]);

    useEffect( () => {
        if(functionsState.functionUpdateRequest.operativesAreas.length > 0) {
            setSelectedArea(functionsState.functionUpdateRequest.operativesAreas as any)
        }
        if(functionsState.functionUpdateRequest.functionActivities) {
            setRows(functionsState.functionUpdateRequest.functionActivities as unknown as RowActivityMultiSelect[]);
            dispatch(setUpdateRowsActivityGG(functionsState.functionUpdateRequest.functionActivities));
        }
    }, [])

    const handleChangeGG = (
        index: number,
        field: keyof RowActivityMultiSelect,
        value: string
    ) => {
        const updatedRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                return {
                    ...row,
                    [field]: value
                };
            }
            return row;
        });
    
        setRows(updatedRows);
        dispatch(setUpdateRowsActivityGG(updatedRows));
    };

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Funzione</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona funzione...'}
                            options={activityOptions}
                            onChange={(value) => dispatch(setUpdateFunction(value))}
                            defaultValue={functionsState.functionUpdateRequest.assignedFunction}
                            value={functionsState.functionUpdateRequest.assignedFunction}
                            error={functionsState.functionsErrors.assignedFunction}
                            errorLabel="Specificare funzione"
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data Inizio</span>
                        </div>
                        <Calendar
                            selected={functionsState.functionUpdateRequest.startingFunction}
                            onChange={(date) => {
                                dispatch(setUpdateStarting(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data Fine</span>
                        </div>
                        <Calendar
                            selected={functionsState.functionUpdateRequest.endingFunction}
                            onChange={(date) => {
                                dispatch(setUpdateEnding(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>
                </div>
                <div className="mt-4" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>

                        <div className="text-left">
                            <span className="input-label">Schema</span>
                        </div>
                        <SelectCustom
                            placeholder={'Schema'}
                            options={normativa}
                            onChange={(value) => {
                                setTypeScheme(value)
                                dispatch(setUpdateScheme(value))
                            }
                            }
                            defaultValue={functionsState.functionUpdateRequest.norm}
                            value={functionsState.functionUpdateRequest.norm}
                            error={functionsState.functionsErrors.norm}
                            errorLabel="Specificare norma"
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Aree Operative</span>
                        </div>
                        <MultiSelectCheckbox
                            onChange={(e) => {
                                setSelectedArea(e.value)
                                dispatch(setUpdateAreeOperative(e.value))
                            }}
                            value={selectedArea}
                            option={regioniListOperativeAreas}
                            placeholder="Aree Operative"
                            selectAllLabel="Italia"
                        />
                    </div>
                </div>
                {
                    functionsState.functionUpdateRequest.norm === 'NORMA_BIO' &&
                    <div className="mt-4">
                        <RowActivity editRows={true} />
                    </div>
                }

                {
                    functionsState.functionUpdateRequest.norm === 'GLOBAL_GAP' &&
                    <div className="mt-4">
                        <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Attività</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Seleziona Attività...'}
                                    options={global_standards}
                                    onChange={(e) => { if (e) { handleChangeGG(0, "ggStandard", e) } }}
                                    defaultValue={rows[0]['ggStandard']}
                                    value={rows[0]['ggStandard']}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Opzione di certificazione</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Seleziona Opzione...'}
                                    options={global_standards_options_functions}
                                    onChange={(e) => { if (e) { handleChangeGG(0, "ggIfaOption", e) } }}
                                    defaultValue={rows[0]['ggIfaOption']}
                                    value={rows[0]['ggIfaOption']}
                                />
                            </div>

                        </div>
                    </div>
                }

                <div className="mt-4">
                    <p className="popupSectionTitle">Gestione Autorizzazioni Ministeriali</p>
                </div>
                <div className="text-left mt-4">
                    <span className="input-label">Protocollo Richiesta Codex (Partenza)</span>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{flex: '1'}}>
                        <ProtocolSearch
                            type="departure"
                            defaultValue={functionsState.functionUpdateRequest.protocolIdRequestDeparture}
                            errorLabel={functionsState.functionsErrors.protocolIdRequestDeparture ? 'Protocollo mancante' : undefined}
                            onSelect={(value) => dispatch(setUpdateProtocolRequestId(value))} 
                            />
                    </div>

                </div>

                <div className="text-left mt-4">
                    <span className="input-label">Protocollo Codex Ricevuta Ministero (Entrata)</span>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{flex: '1'}}>
                        <ProtocolSearch
                            type="entry"
                            defaultValue={functionsState.functionUpdateRequest.protocolIdMinisterialReceivedEntry}
                            errorLabel={functionsState.functionsErrors.protocolIdMinisterialReceivedEntry ? 'Protocollo mancante' : undefined}
                            onSelect={(value) => dispatch(setUpdateProtocolMinisterialRequestId(value))} 
                            />
                    </div>

                </div>

                <div className="mt-4" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Nota Ministeriale</span>
                        </div>
                        <Input
                            placeholder="Nota Ministeriale"
                            onChangeText={(text) => dispatch(setUpdateMinisterialNote(text))}
                            value={functionsState.functionUpdateRequest.ministerialNote}
                            defaultValue={functionsState.functionUpdateRequest.ministerialNote}
                            error={functionsState.functionsErrors.ministerialNote}
                            supportingText={functionsState.functionsErrors.ministerialNote ? "Inserire nota" : undefined}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data Nota</span>
                        </div>
                        {/* <DatePicker locale="it" dateFormat="d/MM/yyyy"
                            selected={functionsState.functionUpdateRequest.noteDate}
                            customInput={<Input supportingText={functionsState.functionsErrors.noteDate ? "Inserire data inizio" : undefined} error={functionsState.functionsErrors.noteDate} startIcon={<CalendarIcon size={20} color={'blue'} />} />}
                            onChange={
                                (date) => {
                                    if (date !== null) {
                                        dispatch(setUpdateDateNote(format(new Date(date), 'yyyy-MM-dd')))
                                    }
                                }
                            }
                        /> */}
                        <Calendar
                            selected={functionsState.functionUpdateRequest.noteDate}
                            onChange={(date) => {
                                dispatch(setUpdateDateNote(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>

                </div>

                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            onChangeText={(text) => dispatch(setUpdateNote(text))}
                            value={functionsState.functionUpdateRequest.note}
                            defaultValue={functionsState.functionUpdateRequest.note}
                        />
                    </div>

                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                    <Button
                        size="md"
                        variant="outline"
                        iconPosition="off"
                        label="Annulla"
                        color="dimgrey"
                        onClick={() => {

                            props.close();
                        }}
                    />
                    <div className="pl-2">
                        <Button
                            size="md"
                            variant="solid"
                            iconPosition="off"
                            label="Modifica"
                            color="orange"
                            onClick={() => {
                                if (functionsState.functionUpdateRequest.id !== undefined) {
                                    dispatch(FunctionUpdate(
                                        {
                                            data: functionsState.functionUpdateRequest,
                                            id: functionsState.functionUpdateRequest.id
                                        }
                                    ))
                                    props.close();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}