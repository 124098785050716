import { Fragment } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { RequirementDetailDTO } from "../../../../pages/gestionePersonale/boxRequisiti/dataService/dto";
import { formatterDate } from "../../../../utils";
import { EditIcon } from "../../../icons/edit";
import { MinusIcon } from "../../../icons/minusIcon";
import { TrashIcon } from "../../../icons/trash";
import Button from "../../../molecules/button";
import './style.scss'
import { useEffect, useState } from "react";
import { PopupForm } from "../../popupForm";
import { RequirementDelete, RequirementDetailDelete, resetRequirementDetailCreationRequest, resetRequirementDetailDeleteStatus, resetRequirementDetailErrors, resetRequirementDetails, resetRequirementDetailUpdateRequest, setReqDetailPersonalManagementId, setReqDetailRequirementId, setRequirementDetailIdToUpdate, setUpdateReqDetailDetail, setUpdateReqDetailEnding, setUpdateReqDetailNote, setUpdateReqDetailProtocols, setUpdateReqDetailStarting } from "../../../../pages/gestionePersonale/boxRequisiti/slice";
import { RequirementDetailPopupAdd } from "../../../../pages/gestionePersonale/boxRequisiti/boxPopupReqDetail/add";
import { RequirementDetailPopupEdit } from "../../../../pages/gestionePersonale/boxRequisiti/boxPopupReqDetail/edit";
import { GetByIdPersonalManagement } from "../../../../pages/gestionePersonale/slice";

interface Props {
    details: RequirementDetailDTO[]
    requirementId: string
}

export function DetailTable(props: Props) {
    const {details, requirementId} = props
    const dispatch = useAppDispatch()
    const requirementState = useAppSelector(state => state.PMRequirement)
    const PMState = useAppSelector(state => state.personalManagement)
    const [addPopupShow, setAddPopupShow] = useState<boolean>(false)
    const [editPopupShow, setEditPopupShow] = useState<boolean>(false)

    const handleOnEdit = (rd: RequirementDetailDTO) => {
        dispatch(setUpdateReqDetailStarting(rd.starting))
        dispatch(setUpdateReqDetailEnding(rd.ending))
        dispatch(setUpdateReqDetailDetail(rd.detail))
        dispatch(setUpdateReqDetailProtocols(rd.protocols))
        dispatch(setUpdateReqDetailNote(rd.note))
        dispatch(setRequirementDetailIdToUpdate(rd.id))
        setEditPopupShow(true)
    }

    useEffect(() => {
        if(requirementState.requirementDetailDeleteStatus === 'successfully') {
            dispatch(resetRequirementDetailDeleteStatus())
            dispatch(resetRequirementDetails())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [requirementState.requirementDetailDeleteStatus])

    return (
        <Fragment>
            <table className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr className="table-head">
                        <th style={{width: '10%'}}>DAL</th>
                        <th style={{width: '10%'}}>AL</th>
                        <th style={{width: '50%'}}>DETTAGLI</th>
                        <th style={{width: '20%'}}>RIFERIMENTO PROTOCOLLI</th>
                        <th style={{width: '10%', textAlign: 'right'}}>
                            <Button
                                size="sm"
                                iconPosition="off"
                                variant="outline-#2"
                                color="orange"
                                label="Aggiungi nuovo"
                                onClick={() => {
                                    dispatch(setReqDetailRequirementId(requirementId))
                                    dispatch(setReqDetailPersonalManagementId(PMState.currentPersonalManagementId))
                                    setAddPopupShow(true)
                                }}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        details.map(d => 
                            <tr
                            className="table-content-text table-content"
                            key={'mau'}
                            >
                                <td style={{color: '#314D51', paddingTop: 10}}>{formatterDate(d.starting)}</td>
                                <td style={{color: '#314D51', paddingTop: 10}}>{formatterDate(d.ending)}</td>
                                <td style={{color: '#4D797F', paddingTop: 10, maxWidth: '1000px'}}>{d.detail}</td>
                                <td style={{color: '#4D797F', paddingTop: 10}}>{d.protocols}</td>
                                <td className="sub-options" style={{justifyContent: 'flex-end', paddingTop: 10}}>
                                    <button onClick={() => handleOnEdit(d)}>
                                        <EditIcon color='orange' size={20} />
                                    </button>
                                    <button onClick={() => dispatch(RequirementDetailDelete(d.id!))} className='ml-3'>
                                        <TrashIcon color='red' size={20}/>
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            {
                addPopupShow && (
                    <PopupForm title="Nuovo dettaglio requisito" close={() => {
                        dispatch(resetRequirementDetailCreationRequest())
                        dispatch(resetRequirementDetailErrors())
                        setAddPopupShow(false)
                    }}>
                        <RequirementDetailPopupAdd close={() => setAddPopupShow(false)} />
                    </PopupForm>
                )
            }
            {
                editPopupShow && (
                    <PopupForm title="Modifica dettaglio requisito" close={() => {
                        dispatch(resetRequirementDetailUpdateRequest())
                        dispatch(resetRequirementDetailErrors())
                        setEditPopupShow(false)
                    }}>
                        <RequirementDetailPopupEdit close={() => setEditPopupShow(false)} />
                    </PopupForm>
                )
            }
        </Fragment>
    )
}