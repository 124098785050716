import Select from "react-select";
import { colors } from "../../colors";
import { SelectProps } from "./dto";
import './style.css';

export function SelectCustom(props: SelectProps) {
    const handleOnChange = (value: string | undefined) => {
        if(props.onChange === undefined)
            return
        if(value === undefined)
            props.onChange('')
        else
            props.onChange(value)

    }
    return (
        <>
            <Select
                
                isDisabled={props.disabled}
                placeholder={props.placeholder}
                value={props.options.filter(({ value }) => value === props.value)[0]}
                options={ props.disabledOptions ?  props.options.filter( (option) =>  !props.disabledOptions?.includes(option)  ) : props.options }
                onChange={(e) => { handleOnChange(e?.value) }}
                defaultValue={props.options.filter(({ value }) => value === props.defaultValue)}
                styles={{

                    container: (baseStyles) => ({
                        ...baseStyles,
                        width: '100%',
                        textAlign: 'left',
                        fontSize: '14px',
                        color: '#314D51',
                        fontFamily: 'Manrope',
                        fontWeight: '500',
                        
                    }),
                    placeholder: (baseStyles) => ({
                        color: '#5D9298',
                        gridArea: '1/1/2/3',
                        marginLeft: '2px',
                        marginRight: '2px',
                        boxSizing: 'border-box',
                        fontSize: '14px',
                        fontFamily: 'Manrope',
                        fontWeight: '500'
                    }),
                    singleValue: (baseStyles) => ({
                        color: '#314D51',
                        gridArea: '1/1/2/3',
                        marginLeft: '2px',
                        marginRight: '2px',
                        boxSizing: 'border-box',
                        fontSize: '14px',
                        fontFamily: 'Manrope',
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '44px',
                        width: '100%',
                        borderRadius: '8px',
                        fontSize: '14px',
                        color: '#314D51',
                        border: props.error ? '1px solid ' + colors.red[300] : state.isFocused ? '1px solid ' + colors.neutral[300] : '1px solid ' + colors.neutral[300],
                        fontFamily: 'Manrope',
                        boxShadow: '0px 1px 2px 0px #162A641F',
                        fontWeight: '500',
                        padding: '0px 12px'
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: 0,
                        color: '#314D51',
                        
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        display: 'none'
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        color: state.isFocused ? colors.neutral[500] : colors.neutral[500]
                    }),
                }}
                classNames={{
                    control: (state) => 'hover:border-neutral-300 !important focus:border-neutral-300 !important border-neutral-300 !important text-white',
                    placeholder: () => 'text-text-sm text-blue-800'
                }}
            />
            {
                props.error &&
                <span className="input-supporting-text" style={{color: '#fd665b', justifyContent:'left', display:'flex'}}>{props.errorLabel}</span>
            }

        </>
    )
}