import { useEffect, useState } from "react"
import { SingleCheckbox } from "../../../../ui/molecules/SingleCheckbox"
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch"
import TextArea from "../../../../ui/molecules/textArea"
import Button from "../../../../ui/molecules/button"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { CompanyFolderCreation, CompanyFolderValidation, GetAllCompanyFolders, resetCompanyFolderValidationStatus, resetSingleCFDetailCreationStatus, setCFDetailCreationRequestDeliveryOnHeadquarter, setCFDetailCreationRequestEntryProtocolId, setCFDetailCreationRequestNote } from "../../slice"

interface Props {
    close: () => void
}

export function AddProtocolPopup(props: Props) {
    const dispatch = useAppDispatch()
    const cartellaAziendaleState = useAppSelector(state => state.cartellaAziendale)
    const [delivery, setDelivery] = useState<boolean>(false)

    useEffect(() => {
        if(cartellaAziendaleState.companyFolderValidationStatus === 'successfully') {
            dispatch(resetCompanyFolderValidationStatus())
            dispatch(CompanyFolderCreation(cartellaAziendaleState.singleCFDetailCreationRequest))
        }
    }, [cartellaAziendaleState.companyFolderValidationStatus])

    useEffect(() => {
        if(cartellaAziendaleState.singleCFDetailCreationStatus === 'successfully') {
            dispatch(resetSingleCFDetailCreationStatus())
            dispatch(GetAllCompanyFolders({
                customerId: cartellaAziendaleState.getAllCompanyFoldersFilters.customerId,
                itemsPerPage: 0,
                page: 0
            }))
            props.close()
        }
    }, [cartellaAziendaleState.singleCFDetailCreationStatus])

    return (
        <div>
            <div className="bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{borderTopWidth: 1}}>
                
                <div className='pb-5'>
                    <SingleCheckbox
                        checked={delivery}
                        setChecked={(value) => {
                            setDelivery(value)
                            dispatch(setCFDetailCreationRequestDeliveryOnHeadquarter(value))
                        }}
                        label='Consegna originale in Sede centrale'
                    />
                </div>

                <div className='pb-5'>
                    <ProtocolSearch
                        type='entry'
                        errorLabel={cartellaAziendaleState.singleCFDetailErrors.entryProtocolId ? 'Protocollo mancante' : undefined}
                        onSelect={(value) => {
                            dispatch(setCFDetailCreationRequestEntryProtocolId(value))
                        }} />
                </div>

                <div className="pb-5">
                    <div className="text-left">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        onChangeText={(text) => {
                            dispatch(setCFDetailCreationRequestNote(text))
                        }} />
                </div>

            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(CompanyFolderValidation(cartellaAziendaleState.singleCFDetailCreationRequest))
                        }}
                        />
                </div>
            </div>
        </div>
    )
}