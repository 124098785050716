import axios from "axios";
import { DocumentService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import { GetAllDocumentsFilterDTO, GetAllDocumentsDTO, DocumentDTO } from "./dto";

export class DocumentServiceImpl implements DocumentService {

    // CREATE
    public CreateDocument(body: FormData): Promise<void> {
        return axios.post("/api/document",
            body,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateDocument(body: FormData, id: string): Promise<void> {
        return axios.put("/api/document/" + id,
            body,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllDocuments(filters: GetAllDocumentsFilterDTO): Promise<GetAllDocumentsDTO> {
        return axios.get("/api/document", {
            params: {
                customer: filters.customer !== '' ? filters.customer : undefined,
                itemsPerPage: filters.itemsPerPage,
                order: filters.order,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                year: filters.year !== '' ? filters.year : undefined
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetDocumentById(id: string): Promise<DocumentDTO> {
        return axios.get("/api/document/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public DeleteDocument(id: string): Promise<void> {
        return axios.delete("/api/document/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
}