import { useEffect, useState } from "react";
import { SingleCheckbox } from "../../../../ui/molecules/SingleCheckbox";
import TextArea from "../../../../ui/molecules/textArea";
import Button from "../../../../ui/molecules/button";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CompanyFolderUpdate, GetAllCompanyFolders, resetSingleCFDetailUpdateStatus, setUpdateCFDetailRequestDeliveryOnHeadquarter, setUpdateCFDetailRequestNote } from "../../slice";

interface Props {
    close: () => void
}

export function EditProtocolPopup(props: Props) {
    const dispatch = useAppDispatch()
    const cartellaAziendaleState = useAppSelector(state => state.cartellaAziendale)
    const [delivery, setDelivery] = useState<boolean>(false)

    useEffect(() => {
        if(cartellaAziendaleState.singleCFDetailUpdateRequest.deliveredOnHeadquarter === undefined)
            return

        cartellaAziendaleState.singleCFDetailUpdateRequest.deliveredOnHeadquarter ? setDelivery(true) : setDelivery(false)
    }, [])

    useEffect(() => {
        if(cartellaAziendaleState.singleCFDetailUpdateStatus === 'successfully') {
            dispatch(resetSingleCFDetailUpdateStatus())
            dispatch(GetAllCompanyFolders({
                customerId: cartellaAziendaleState.getAllCompanyFoldersFilters.customerId,
                itemsPerPage: 0,
                page: 0
            }))
            props.close()
        }
    }, [cartellaAziendaleState.singleCFDetailUpdateStatus])

    return (
        <div>
            <div className="bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{borderTopWidth: 1}}>

                <div className='pb-5'>
                    <SingleCheckbox
                        checked={delivery}
                        setChecked={(value) => {
                            setDelivery(value)
                            dispatch(setUpdateCFDetailRequestDeliveryOnHeadquarter(value))
                        }}
                        label='Consegna originale in Sede centrale'
                    />
                </div>

                <div className="pb-5">
                    <div className="text-left">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        value={cartellaAziendaleState.singleCFDetailUpdateRequest.note}
                        onChangeText={(text) => {
                            dispatch(setUpdateCFDetailRequestNote(text))
                        }} />
                </div>

            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(CompanyFolderUpdate({body: cartellaAziendaleState.singleCFDetailUpdateRequest, id: cartellaAziendaleState.singleCFIdToUpdate}))
                        }}
                        />
                </div>
            </div>
        </div>
    )
}