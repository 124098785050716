import { createSlice } from "@reduxjs/toolkit"

interface EntryAttachmentManagementState {
    selectedTab: number
}

const initialState: EntryAttachmentManagementState = {
    selectedTab: 0
}

const EntryAttachmentManagementSlice = createSlice({
    name: 'EntryAttachmentManagement/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        }
    }
})

export const {
    setSelectedTab
} = EntryAttachmentManagementSlice.actions

export default EntryAttachmentManagementSlice.reducer