import clsx from "clsx"
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { BellIcon } from "../icons/bell"
import { MailIcon } from "../icons/mail"
import { MenuIcon } from "../icons/menu"
import { SettingsIcon } from "../icons/settings"
import Avatar from "../molecules/avatar"
import Button from "../molecules/button"
import Breadcrumb from "../organisms/breadcrumb"
import SideNav from "../organisms/navbar"
import { MenuStatus } from "./dto"
import { setMenuStatus } from "./slice"
import { MenuItems } from "../organisms/navbar/dto"
import { Header } from "../organisms/header"
import { keycloak } from "../../lib/keycloak"
import { FolderIcon } from "../icons/FolderIcon"
import { useNavigate } from "react-router-dom"
import { GestPersonaleIcon } from "../icons/gestPersonaleIcon"

interface Props {
    children: ReactNode
    headerChildren?: ReactElement
    headerLabel?: string
    breadcrumbItems: string[]
    menuItem: MenuItems
    rightBar?: boolean
    overflow?: string
    noPaddingLat?: boolean
    headerIcon?: ReactElement
    customerName?: string | undefined
    customerCode?: string | undefined
}

export function Layout(props: Props) {
    const { children, noPaddingLat } = props
    const avatarRef = useRef<HTMLDivElement | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false)
    const layoutState = useAppSelector(state => state.layout)
    const userState = useAppSelector(state => state.users)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleAvatarClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

    useEffect(() => {

        const handleScroll = () => {
            if (window.scrollY > (window.innerHeight / 2) - 150) {
                setShowScrollButton(true)
            } else {
                setShowScrollButton(false)
            }
        }
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [window.scrollY])


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (avatarRef.current && !avatarRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener("click", handleClickOutside)
        return () => document.removeEventListener("click", handleClickOutside)
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (avatarRef.current && !avatarRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`antialiased min-h-screen bg-white`}
            style={{ display: 'flex', flexDirection: 'row' }}>
            <div
                onMouseEnter={() => {
                    if (layoutState.menuStatus === MenuStatus.CLOSED) {
                        dispatch(setMenuStatus(MenuStatus.HOVER))
                    }
                }}
                onMouseLeave={() => {
                    if (layoutState.menuStatus === MenuStatus.HOVER) {
                        dispatch(setMenuStatus(MenuStatus.CLOSED))
                    }
                }}
                className={
                    clsx(
                        "border-neutral-200 transition-all hidden lg:block",
                        {
                            "w-[240px]": layoutState.menuStatus === MenuStatus.OPEN || layoutState.menuStatus === MenuStatus.HOVER,
                            "w-[72px]": layoutState.menuStatus === MenuStatus.CLOSED,
                            "absolute h-[100%] bg-bgGreen": layoutState.menuStatus === MenuStatus.HOVER
                        }
                    )
                }>
                <SideNav />
            </div>
            <div
                className={
                    clsx(
                        "border-neutral-200 transition-all lg:hidden",
                        {
                            "absolute h-[100%] w-[100%]": layoutState.menuStatus === MenuStatus.HOVER,
                            "hidden": layoutState.menuStatus !== MenuStatus.HOVER
                        }
                    )
                }>
                <div
                    className="w-[100%] h-[100%] backdrop-blur-sm transition-all"
                    onClick={() => {
                        dispatch(setMenuStatus(MenuStatus.CLOSED))
                    }}
                >
                    <div
                        className="w-[240px] h-[100%] bg-white opacity-100 transition-all"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <SideNav />
                    </div>
                </div>
            </div>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                className={
                    clsx(
                        "transition-all bg-brandPrimary-850",
                        {
                            'ml-[0px] lg:ml-[72px]': layoutState.menuStatus === MenuStatus.HOVER,
                            'ml-[0px]': layoutState.menuStatus !== MenuStatus.HOVER,
                        }
                    )
                }
            >
                <div className="flex flex-row items-center justify-between px-[24px] bg-bgGreen">
                    <div className="block lg:hidden py-[20px]">
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                dispatch(setMenuStatus(MenuStatus.HOVER))
                            }}
                        >
                            <MenuIcon color={""} size={24} />
                        </div>
                    </div>
                    <div className="hidden lg:flex h-[80px]">
                        <Breadcrumb path={props.breadcrumbItems}
                        />
                    </div>
                    <div className="flex flex-row gap-[8px] items-center">
                        <div className="flex flex-row gap-[4px]">
                            <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<FolderIcon />} onClick={() => navigate('/cartellaAziendale')} />
                            <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<GestPersonaleIcon color={""} size={0} />} onClick={() => navigate('/gestionePersonale')} />
                        </div>
                        <div className="h-[40px] w-[1px] bg-neutral-200" />

                        <div className="relative" ref={avatarRef}>
                            <div onClick={handleAvatarClick} className="cursor-pointer">
                                <Avatar type="single" size="sm" shape="circle" imageUrl="" altTextInitials={(userState.findMeResponse.name && userState.findMeResponse.surname) ? userState.findMeResponse.name[0] + userState.findMeResponse.surname[0] : ''} />
                            </div>

                            {isDropdownOpen && (
                                <div className="mt-2 w-48 border border-gray-200 rounded-md shadow-lg subMenuAvatar bg-brandPrimary-200">
                                    <button
                                        onClick={() => console.log()}
                                        className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                                    >
                                        Profilo
                                    </button>
                                    <button
                                        onClick={() =>{ keycloak.logout({ redirectUri: window.location.origin }); localStorage.clear();} }
                                        className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                                    >
                                        Logout
                                    </button>
                                </div>

                            )}
                        </div>
                    </div>
                </div>
                {
                    props.headerLabel !== undefined && (
                        <Header icon={props.headerIcon} label={props.headerLabel} customerCode={props.customerCode} customerName={props.customerName}>
                            {props.headerChildren}
                        </Header>
                    )

                }

                {
                    props.rightBar ? (
                        <div style={{ flexGrow: 1, padding: !children ? '24px' : '0px 0px 0px 0px', overflow: props.overflow }} className="bg-brandPrimary-200 border-t-[1px] border-t-neutral-200">
                            {
                                !children ?
                                    <div className="w-[100%] rounded-[12px] shadow-sm border-[1px] h-[100%] border-neutral-200" /> :
                                    children
                            }
                           
                        </div>
                    ) : (
                        <div style={{ flexGrow: 1, padding: !children ? '24px' : noPaddingLat === undefined ? '0px 24px' : '0px', overflow: 'auto' }} className="bg-brandPrimary-200 border-t-[1px] border-t-neutral-200">
                            {
                                !children ?
                                    <div className="w-[100%] rounded-[12px] shadow-sm border-[1px] h-[100%] border-neutral-200" /> :
                                    children
                            }
                           
                        </div>
                    )
                }

                {showScrollButton && (
                    <button
                        onClick={scrollToTop}
                        className="fixed bottom-5 right-5 bg-brandPrimary-500 text-white rounded-full p-4 shadow-lg hover:bg-brandPrimary-700 transition"
                        aria-label="Scroll to top"
                    >
                        ↑
                    </button>
                )}
            </div>
        </div>
    )
}