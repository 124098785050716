import { Router } from "@remix-run/router"
import { useEffect, useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { DashboardView } from "../../pages/dashboard"
import { keycloak } from "../keycloak"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { setMenuItems } from "../../ui/layout/slice"
import { Users } from "../../pages/users/users"
import { User } from "../../pages/users/user"
import { EditUser } from "../../pages/users/edit-user"
import { Clienti } from "../../pages/clienti"
import { NewCliente } from "../../pages/clienti/newCliente"
import { TecnicoAziendale } from "../../pages/businessTechnician"
import { EditCustomer } from "../../pages/clienti/editCustomer"
import { AddNewTech } from "../../pages/businessTechnician/addNew"
import { EditTech } from "../../pages/businessTechnician/edit"
import { GestionePersonale } from "../../pages/gestionePersonale"
import { NewStaff } from "../../pages/gestionePersonale/NewStaff"
import { EditStaff } from "../../pages/gestionePersonale/editStaff"
import { Formazione } from "../../pages/gestionePersonale/formazione"
import { NewTraining } from "../../pages/gestionePersonale/boxFormazione/newFormazione"
import { EditTraining } from "../../pages/gestionePersonale/boxFormazione/editFormazione"
import { EntryProtocol } from "../../pages/protocol/entry"
import { DepartureProtocol } from "../../pages/protocol/departure"
import { ReportProtocol } from "../../pages/protocol/report"
import { AddEntryProtocol } from "../../pages/protocol/entry/add"
import { AddDepartureProtocol } from "../../pages/protocol/departure/add"
import { EditEntryProtocol } from "../../pages/protocol/entry/edit"
import { EditDepartureProtocol } from "../../pages/protocol/departure/edit"
import { GestioneDocumenti } from "../../pages/gestioneDocumenti"
import { PageNotFound } from "../../pages/pageNotFound"
import { RouteObject } from "react-router-dom";
import { GestioneAllegatiEntrata } from "../../pages/gestioneAllegati/entry"
import { GestioneAllegatiPartenza } from "../../pages/gestioneAllegati/departure"
import { CartellaAziendale } from "../../pages/cartellaAziendale"

const adminRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />),
    },
    {
        path: '/users',
        element: (<Users />)
    },
    {
        path: '/clienti',
        element: (<Clienti />)
    },
    {
        path: '/cliente',
        element: (<NewCliente />)
    },
    {
        path: '/tecnicoAziendale',
        element: (<AddNewTech />)
    },
    {
        path: '/editTecnicoAziendale/:id',
        element: (<EditTech />)
    },
    {
        path: '/tecnico-aziendale',
        element: (<TecnicoAziendale />)
    },
    {
        path: '/user',
        element: (<User />)
    },
    {
        path: '/edit-user/:id',
        element: (<EditUser />)
    },
    {
        path: '/edit-customer/:id',
        element: (<EditCustomer />)
    },
    {
        path: '/gestionePersonale',
        element: (<GestionePersonale />)
    },
    {
        path: '/formazione',
        element: (<Formazione />)
    },
    {
        path: '/nuovoFormazione',
        element: (<NewTraining />)
    },
    {
        path: '/editFormazione',
        element: (<EditTraining />)
    },
    {
        path: '/nuovoPersonale',
        element: (<NewStaff />)
    },
    {
        path: '/editPm/:id',
        element: (<EditStaff />)
    },
    {
        path: '/entryProtocol',
        element: (<EntryProtocol />)
    },
    {
        path: '/departureProtocol',
        element: (<DepartureProtocol />)
    },
    {
        path: '/reportProtocol',
        element: (<ReportProtocol />)
    },
    {
        path: '/addEntryProtocol',
        element: (<AddEntryProtocol />)
    },
    {
        path: '/editEntryProtocol',
        element: (<EditEntryProtocol />)
    },
    {
        path: '/addDepartureProtocol',
        element: (<AddDepartureProtocol />)
    },
    {
        path: '/editDepartureProtocol',
        element: (<EditDepartureProtocol />)
    },
    {
        path: '/gestioneDocumenti',
        element: (<GestioneDocumenti />)
    },
    {
        path: '*',
        element: (<PageNotFound />)
    },
    {
        path: '/gaEntrata',
        element: (<GestioneAllegatiEntrata />)
    },
    {
        path: '/gaPartenza',
        element: (<GestioneAllegatiPartenza />)
    },
    {
        path: '/cartellaAziendale',
        element: (<CartellaAziendale />)
    }
])

const userRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />),
    },
    {
        path: '/clienti',
        element: (<Clienti />)
    },
    {
        path: '/cliente',
        element: (<NewCliente />)
    },
    {
        path: '/edit-customer/:id',
        element: (<EditCustomer />)
    },
    {
        path: '/gestionePersonale',
        element: (<GestionePersonale />)
    },
    {
        path: '/formazione',
        element: (<Formazione />)
    },
    {
        path: '/nuovoFormazione',
        element: (<NewTraining />)
    },
    {
        path: '/editFormazione',
        element: (<EditTraining />)
    },
    {
        path: '/nuovoPersonale',
        element: (<NewStaff />)
    },
    {
        path: '/editPm/:id',
        element: (<EditStaff />)
    },
    {
        path: '/entryProtocol',
        element: (<EntryProtocol />)
    },
    {
        path: '/departureProtocol',
        element: (<DepartureProtocol />)
    },
    {
        path: '/reportProtocol',
        element: (<ReportProtocol />)
    },
    {
        path: '/addEntryProtocol',
        element: (<AddEntryProtocol />)
    },
    {
        path: '/editEntryProtocol',
        element: (<EditEntryProtocol />)
    },
    {
        path: '/addDepartureProtocol',
        element: (<AddDepartureProtocol />)
    },
    {
        path: '/editDepartureProtocol',
        element: (<EditDepartureProtocol />)
    },
    {
        path: '/gestioneDocumenti',
        element: (<GestioneDocumenti />)
    },
    {
        path: '*',
        element: (<PageNotFound />)
    }
])


export function KeycloakRouter() {
    const roles = useAppSelector(state => state.keycloak.userRoles)

    const dispatch = useAppDispatch()

    const [router, setRouter] = useState<Router | undefined>(undefined)

    const routeConfig = {
        admin: {
            router: adminRouter,
            menuItems: [
                { item: MenuItems.DASHBOARD, section: 1 },
                { item: MenuItems.USERS, section: 1 },
                { item: MenuItems.CLIENTI, section: 1 },
                { item: MenuItems.TECNICO_AZIENDALE, section: 1 },
                { item: MenuItems.PROTOCOLLO, section: 2 },
                { item: MenuItems.GESTIONE_DOCUMENTI, section: 2 },
                { item: MenuItems.GESTIONE_PERSONALE, section: 3 },
            ],
        },
        internalUser: {
            router: userRouter,
            menuItems: [
                { item: MenuItems.DASHBOARD, section: 1 },
                { item: MenuItems.USERS, section: 1 },
                { item: MenuItems.CLIENTI, section: 1 },
                { item: MenuItems.PROTOCOLLO, section: 2 },
                { item: MenuItems.GESTIONE_DOCUMENTI, section: 2 },
                { item: MenuItems.GESTIONE_PERSONALE, section: 3 },
            ],
        },
    };


    useEffect(() => {
        if (roles !== undefined && roles.length > 0 && keycloak.token !== undefined) {
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('Amm')) {
                const config = routeConfig.admin;
                setRouter(config.router);
                dispatch(setMenuItems(config.menuItems));
            }
            else if (
                keycloak.hasRealmRole('FST') || keycloak.hasRealmRole('RST')
                || keycloak.hasRealmRole('RSCo') || keycloak.hasRealmRole('FUCO')
                || keycloak.hasRealmRole('RSCe') || keycloak.hasRealmRole('FUCE')
                || keycloak.hasRealmRole('UCA') || keycloak.hasRealmRole('VAL')
                || keycloak.hasRealmRole('SM') || keycloak.hasRealmRole('CSI')
                || keycloak.hasRealmRole('RSOT') || keycloak.hasRealmRole('CSOT')
                || keycloak.hasRealmRole('CSM') || keycloak.hasRealmRole('OCD') || keycloak.hasRealmRole('RD')
            ) {
                const config = routeConfig.internalUser;
                setRouter(config.router);
                dispatch(setMenuItems(config.menuItems));
            }
            
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('Amm') || keycloak.hasRealmRole('FST') || keycloak.hasRealmRole('RST') || keycloak.hasRealmRole('RSCo')) {
                setRouter(adminRouter)
                dispatch(setMenuItems([
                    { item: MenuItems.DASHBOARD, section: 1 },
                    { item: MenuItems.USERS, section: 1 },
                    { item: MenuItems.CLIENTI, section: 1 },
                    { item: MenuItems.TECNICO_AZIENDALE, section: 1 },
                    { item: MenuItems.PROTOCOLLO, section: 2 },
                    { item: MenuItems.GESTIONE_DOCUMENTI, section: 2 },
                    { item: MenuItems.CARTELLA_AZIENDALE, section: 2 },
                    { item: MenuItems.GESTIONE_ALLEGATI, section: 2 },
                    { item: MenuItems.GESTIONE_PERSONALE, section: 3 },
                ]))
            } 
            else {
                return
            }
        }
    }, [roles, keycloak.token])

    if (router === undefined) {
        return (<div>loading</div>)
    }

    return (
        <RouterProvider router={router} />
    );
}