import React, { useEffect, useState } from "react";
import "./Input.css";
import { InputProps, SearchType } from "./Input.types";
import { GetCustomerByCodeDTO, GetCustomerByCompanyNameResponseDTO, GetCustomerResponseDTO } from "../../../pages/clienti/dto";
import { GetCustomersByCode, GetCustomersByCompanyName, resetGetCustomersByCodeStatus, resetGetCustomersByCompanyName, setExistingCode, setTakeover } from "../../../pages/clienti/slice";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { setCDXClient } from "../../../pages/gestionePersonale/boxCDXM026/slice";
import { formatterDate } from "../../../utils";

// Supponiamo che i dati siano forniti tramite un array

const Input: React.FC<InputProps> = ({ ...props }) => {
    const dispatch = useAppDispatch()
    const [showResults, setShowResults] = useState<boolean>(false); // Stato per la riga selezionata
    const [searchResults, setSearchResults] = useState<GetCustomerByCodeDTO[] | GetCustomerByCompanyNameResponseDTO[]>([]); // Stato per i risultati della ricerca
    const [responseResults, setResponseResults] = useState<GetCustomerByCodeDTO[] | GetCustomerByCompanyNameResponseDTO[]>([]); // Stato per i risultati della ricerca
    const [selectedResult, setSelectedResult] = useState<string | null>(null); // Stato per la riga selezionata
    const [selectedResultMessage, setSelectedResultMessage] = useState<string | null>(null); // messaggio per la riga selezionata
    const clientiState = useAppSelector(state => state.clienti)

    const handleSelectResult = (result: GetCustomerByCodeDTO | GetCustomerByCompanyNameResponseDTO) => {
        setSelectedResult(result.id);
        setSelectedResultMessage(result.clientCode + " - " + result.companyName)
        if(props.searchType === SearchType.COMPANY_NAME)
            dispatch(setCDXClient(result.id))
        else if(props.searchType === SearchType.TAKEOVER)
            dispatch(setTakeover(result.id))
        else
            dispatch(setExistingCode(result.clientCode))
        setSearchResults([]);
        if (props.onSelect) {
            props.onSelect(result.id);
        }
    };

    useEffect(() => {
        setSelectedResult(null);
        setShowResults(false)
        setSelectedResultMessage(null)
        setSelectedResult(null)
    }, [props.disabled])

    useEffect(() => {
        if (clientiState.getCustomersByCodeResponseStatus === 'successfully') {
            setSelectedResult(null);
            if (clientiState.getCustomersByCodeResponse !== undefined) {
                setResponseResults(clientiState.getCustomersByCodeResponse)
                setSearchResults(clientiState.getCustomersByCodeResponse)
                setShowResults(true)
            }
            dispatch(resetGetCustomersByCodeStatus('idle'))
        }
    }, [clientiState.getCustomersByCodeResponseStatus])

    useEffect(() => {
        if(clientiState.getCustomersByCompanyNameStatus === 'successfully' &&
            clientiState.getCustomersByCompanyNameResponse !== undefined
        ) {
            
            setSelectedResult(null);
            setResponseResults(clientiState.getCustomersByCompanyNameResponse)
            setSearchResults(clientiState.getCustomersByCompanyNameResponse)
            setShowResults(true)
            dispatch(resetGetCustomersByCompanyName())
        }
    }, [clientiState.getCustomersByCompanyNameStatus])



    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
            {props.label && <span className="input-label">{props.label}</span>}
            <div
                className="input-container"
                style={{
                    backgroundColor: props.disabled ? 'rgba(242, 242, 250, 1)' : 'white',
                    borderColor: props.error ? 'rgba(255, 198, 194, 1)' : 'rgba(203, 203, 235, 1)',
                }}
            >
                {props.startIcon && <div style={{ maxHeight: '20px' }}>{props.startIcon}</div>}

                <input
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    value={props.value}
                    onClick={props.onClick}
                    defaultValue={props.defaultValue}
                    onChange={(e) => {
                        if (e.target.value.length > 2) {
                            if(props.searchType === SearchType.COMPANY_NAME)
                                dispatch(GetCustomersByCompanyName(e.target.value))
                            else
                                dispatch(GetCustomersByCode(e.target.value))
                        }
                        else {
                            setShowResults(false)
                            setSelectedResultMessage(null)
                            setSelectedResult(null)
                        }

                        props.onChangeText && props.onChangeText(e.target.value);

                        if (responseResults.length > 0) {
                            const filteredResults = responseResults.filter((customer: GetCustomerByCodeDTO) =>
                                customer.clientCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
                                customer.companyName.toLowerCase().includes(e.target.value.toLowerCase())
                            );
                            setSearchResults(filteredResults)
                        }


                    }}
                    className={"input-value" + (props.error ? '-error' : '')}
                    style={{
                        border: 0,
                        outline: 0,
                        height: '20px',
                        backgroundColor: 'white',
                        width: '100%',
                        color: '#62628A',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        fontSize: '14px',
                    }}
                />
                {props.endIcon && <div style={{ maxHeight: '20px' }}>{props.endIcon}</div>}
            </div>
            {props.supportingText && (
                <span className="input-supporting-text" style={{ color: props.error ? 'rgba(253, 102, 91, 1)' : 'rgba(173, 173, 214, 1)' }}>
                    {props.supportingText}
                </span>
            )}
            {(searchResults.length > 0 && showResults) && (
                <ul className="search-results" style={{ listStyle: 'none', padding: 0, margin: 0, width: '100%', border: '1px solid rgba(203, 203, 235, 1)', borderRadius: '4px', backgroundColor: 'white', color:'#37565A' }}>
                    {searchResults.map((customer) => (
                        <li
                            key={customer.id}
                            onClick={() => handleSelectResult(customer)}
                            style={{
                                padding: '8px',
                                cursor: 'pointer',
                                fontSize:'14px',
                                color: customer.endingDate !== null ? 'rgb(206, 23, 17)' : '',
                                backgroundColor: selectedResult === customer.id ? 'rgba(203, 203, 235, 0.5)' : 'white',
                            }}
                        >
                            {customer.companyName} - {customer.countryRegion} - {customer.cuaa} - In {formatterDate(customer.startingDate)} Out {customer.endingDate ? formatterDate(customer.startingDate) : '-'}
                        </li>
                    ))}
                </ul>
            )}
            <div className="mt-3">
                <span className="label-search-code text-brandPrimary-700">{selectedResultMessage}</span>
            </div>

        </div>
    );
};

export default Input;
