import axios from "axios";

import { FunctionsService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import { FunctionsDTO } from "./dto";

export class FunctionsServiceImpl implements FunctionsService {

    public FunctionCreation(data: FunctionsDTO): Promise<void> {
        return axios.post("/api/pm-function",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public FunctionUpdate(data: FunctionsDTO , id: string): Promise<void> {
        return axios.put("/api/pm-function/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetFunctionById(id: string): Promise<FunctionsDTO> {
        return axios.get("/api/pm-function/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAllFunctions(): Promise<FunctionsDTO[]> {
        return axios.get("/api/pm-function", {
            params: {
                page: 0,
                itemsPerPage: 0
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data.data)
    }
    
    public DeleteFunction(id: string): Promise<void> {
        return axios.delete("/api/pm-function/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

}

