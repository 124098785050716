import { ListItemCA } from "./item/item";
import { useAppSelector } from "../../../lib/redux/hooks";

export function CartellaAziendaleActiveList() {
    const cartellaAziendaleState = useAppSelector(state => state.cartellaAziendale)

    return (
        <div>
            {
                cartellaAziendaleState.cartelleAziendali.map((ca, index) => (
                    <div className="pb-5">
                        <ListItemCA
                            id={ca.id || ''}
                            key={index}
                            historyView={false}
                            code={ca.code}
                            title={ca.attachmentName}
                            numOfDetails={cartellaAziendaleState.activeCFDetailsUpdateRequest.filter(det => det.entryProtocolAttachmentId === ca.id).length}
                            protocolIds={cartellaAziendaleState.activeCFDetailsUpdateRequest.filter(det => det.entryProtocolAttachmentId === ca.id).map(d => d.id)}
                        />
                    </div>
                ))
            }
        </div>
        
    )
}