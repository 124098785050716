import axios from "axios";
import { CompanyFolderDetailBulkUpdateDTO, CompanyFolderDetailCreationDTO, CompanyFolderDetailDTO, CompanyFolderDetailUpdateDTO, GetAllCompanyFoldersFiltersDTO } from "./dto";
import { CartellaAziendaleService } from "./service";
import { keycloak } from "../../../lib/keycloak";

export class CartellaAziendaleServiceImpl implements CartellaAziendaleService {

    public CreateCompanyFolderDetail(data: CompanyFolderDetailCreationDTO): Promise<void> {
        return axios.post("/api/company-folder",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateCompanyFolderDetail(data: CompanyFolderDetailUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/company-folder/edit/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateAllCompanyFolderDetails(data: CompanyFolderDetailBulkUpdateDTO[]): Promise<void> {
        return axios.put("/api/company-folder/bulk",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllCompanyFolderDetails(filters: GetAllCompanyFoldersFiltersDTO): Promise<CompanyFolderDetailDTO[]> {
        return axios.get("/api/company-folder", {
            params: {
                customerId: filters.customerId,
                historic: filters.historic,
                itemsPerPage: filters.itemsPerPage,
                sort: filters.sort,
                order: filters.order,
                page: filters.page
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data.data)
    }

    public GetCompanyFolderDetailById(id: string): Promise<CompanyFolderDetailDTO> {
        return axios.get("/api/company-folder/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public DeleteCompanyFolderDetail(id: string): Promise<void> {
        return axios.delete("/api/company-folder/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}