import clsx from "clsx"
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetFileById, GetFileObjectById } from "../../../objectStorage/slice";
import { DocumentIcon } from "../../icons/documentIcon";

interface Props {
    fileName: string
    fileId?: string
    otherInfo: string
    toUpdate?: boolean
    smallSize?: boolean
    download?: boolean
}

export function FileViewer(props: Props) {
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const dispatch = useAppDispatch()
    const handleOnClick = () => {
        if (props.fileId) {
            dispatch(GetFileById(props.fileId))
        }
    }

    useEffect(() => {
        if (objectStorageState.getFileByIdStatus === 'successfully') {
            const base64Data = objectStorageState.getFileByIdResponse?.base64;
            if (base64Data && base64Data !== '') {
                const byteCharacters = atob(base64Data);
                const byteNumbers = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
                const blob = new Blob([byteNumbers], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                // Apri una finestra popup con un titolo personalizzato
                if (!props.download) {
                    const newWindow = window.open('', '_blank', 'width=800,height=600');
                    if (newWindow) {
                        newWindow.document.title = props.fileName;
                        const iframe = newWindow.document.createElement('iframe');
                        iframe.style.width = '100%';
                        iframe.style.height = '100%';
                        iframe.src = url;
                        newWindow.document.body.appendChild(iframe);
                    }
                } else {
                    // for download
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = props.fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                }
            }
        }
    }, [objectStorageState.getFileByIdStatus, props.fileName]);



    return (
        <button className="p-2 cursor-pointer w-full rounded-lg border border-dashed hover:bg-brandPrimary-100"
            style={{
                display: "flex", alignItems: 'center', justifyContent: 'space-between',
                backgroundColor: !props.toUpdate ? '#d1f5d950' : '#fceba750',
                borderColor: !props.toUpdate ? '#84d997' : '#fae07a'
            }}
            onClick={() => { handleOnClick() }}>
            <div style={{ flex: '0.1' }}>
                <DocumentIcon />
            </div>
            <div style={{ flex: '0.5' }}>
                <span className={clsx("text-left text-neutral-800", { "font-medium": !props.smallSize, "text-sm": props.smallSize })}>{props.fileName}</span>
            </div>
            <div style={{ flex: '0.4' }}>
                <span className={clsx('text-left text-brandPrimary-500', { "font-medium": !props.smallSize, "text-sm": props.smallSize })}>{props.otherInfo}</span>
            </div>
            <div style={{ flex: '0.4' }}>
                <span className={clsx('text-left', { "font-medium": !props.smallSize, "text-sm": props.smallSize })} style={{ color: props.toUpdate ? '#c4a423' : '#65b577' }}>
                    {props.toUpdate ? 'Da caricare' : 'Caricato'}
                </span>
            </div>
        </button>
    )
}