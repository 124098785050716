import { CDXM026DTO, CDXM026DTOResponse, GetAllCDXM026ResponseDTO } from "./dto";
import { CDXM026ServiceImpl } from "./serviceImpl";

export interface CDXM026Service {
    GetAllCDXM026(): Promise<GetAllCDXM026ResponseDTO>
    GetCDXM026ById(id: string): Promise<CDXM026DTOResponse>
    CreateCDXM026(data: CDXM026DTO): Promise<void>
    UpdateCDXM026(data: CDXM026DTO, id: string): Promise<void>
    DeleteCDXM026(id: string): Promise<void>
}

export function NewCDXM026Service(): CDXM026Service {
    return new CDXM026ServiceImpl();
}