
import { Layout } from "../../ui/layout";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { ArrowLeftIcon } from "../../ui/icons/arrow-left";
import './style.scss';


export function PageNotFound() {
    const navigate = useNavigate()
   

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Pagina non trovata']}
            headerLabel="Pagina non trovata"
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => {
                    navigate('/')
                    }}>
                    <ArrowLeftIcon />
                </button>
            }
            
        >
            <div className="message mt-24">Pagina non trovata.</div>
           
        </Layout>
    )
}