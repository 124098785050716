import clsx from 'clsx';
import { ReactElement, cloneElement, useState } from 'react';
import { useAppSelector } from '../../../lib/redux/hooks';
import { colors } from '../../colors';
import { MenuStatus } from '../../layout/dto';
import { ChevronDownIcon } from '../../icons/chevronDown';
import { ChevronrightIcon } from '../../icons/chevronRight';

interface SubMenuItem {
  label: string;
  link: string;
}
interface Props {
  link: {
    name: string,
    href: string,
    icon: ReactElement
  },
  subMenu?: boolean
  subMenuItems?: SubMenuItem[]
}

export default function NavLinks(props: Props) {

  const { link, subMenu, subMenuItems } = props
  const pathname = window.location.pathname.split('/').filter((_, index) => index !== 0)[0]
  const linkIcon = link.icon;
  const [hover, setHover] = useState(false)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  //const toggleSubMenu = () => setIsSubMenuOpen(!isSubMenuOpen);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const layoutState = useAppSelector(state => state.layout)

  if (subMenu) {
    return (
      <div>
        <span
          style={{ marginLeft: 'auto' }}
          className={
            clsx(
              "flex h-[40px] grow items-center rounded-[8px] p-[8px] border-1 focus:outline-none focus:shadow-xs hover:shadow-xs text-sm font-medium text-brandPrimary-200 hover:text-neutral-600 md:flex-none",
              {
                'text-white': link.href.includes(pathname),
                'text-brandPrimary-50 hover:text-neutral-600': !link.href.includes(pathname),
                'justify-center': layoutState.menuStatus === MenuStatus.CLOSED,
                'justify-between': layoutState.menuStatus !== MenuStatus.CLOSED,
              }
            )}
          onClick={toggleSubMenu}>
          <span className='mainItemSubMenu'>
            {cloneElement(linkIcon, { color: hover && !link.href.includes(pathname) ? colors.neutral[600] : link.href.includes(pathname) ? colors.neutral[800] : colors.neutral[500] })}
            {link.name}
            {isSubMenuOpen ? <ChevronDownIcon size={18} color={'white'} /> : <ChevronDownIcon size={18} color={'white'} />}
          </span>
        </span>
        {subMenu && isSubMenuOpen && (
          <div
            className={`subMenu ${isSubMenuOpen ? 'open' : ''}`}
            style={{ paddingLeft: '16px', display: 'flex', flexDirection: 'column' }}>
            {subMenuItems?.map((label, index) => (
              <div key={index}>
                <a className={`linkSubMenu  ${index === subMenuItems.length - 1 ? '' : 'linkSubMenuBorder'}`} href={label.link}>
                  <ChevronrightIcon size={18} color={"#fff"} />
                  <span className='subItemMenu hover:text-neutral-600'>
                    {label.label}
                  </span>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
  else return (
    <a
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      key={link.name}
      href={link.href}

      className={
        clsx(
          "flex h-[40px] grow items-center rounded-[8px] p-[8px] border-1 focus:outline-none focus:shadow-xs hover:shadow-xs text-sm font-medium text-brandPrimary-200 hover:text-neutral-600 md:flex-none",
          {
            'text-white': link.href.includes(pathname),
            'text-brandPrimary-50 hover:text-neutral-600': !link.href.includes(pathname),
            'justify-center': layoutState.menuStatus === MenuStatus.CLOSED,
            'justify-between': layoutState.menuStatus !== MenuStatus.CLOSED,
          }
        )}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
        {cloneElement(linkIcon, { color: hover && !link.href.includes(pathname) ? colors.neutral[600] : link.href.includes(pathname) ? colors.neutral[800] : colors.neutral[500] })}
        {
          layoutState.menuStatus !== MenuStatus.CLOSED &&
          <p className="text-label-sm align-text-bottom font-medium">{link.name}</p>
        }
      </div>

      {/* <Pills size={'sm'} label={'4'} outline={false} emphasis={pathname === link.href ? false : true} color={hover || pathname === link.href ? 'blue' : 'gray'} /> */}
    </a>
  )
}
