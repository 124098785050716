import { ReactNode } from "react"
import { CloseIcon } from "../../icons/close"
import './styles.scss'

interface Props {
    title: string
    children: ReactNode
    showButtons?: boolean
    close: () => void
}

export function Alert(props: Props) {
    const { children } = props

    return (
        <div id="alert" className="alertAdd">
            <div className="alertElements">
                <div className="modal">
                    <div className='alertHeader pl-5 pr-5 bg-white' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', display: 'flex' }}>
                        <h5 className="heading">{props.title}</h5>
                        <button
                            onClick={() => props.close()}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div className='alert-content'>
                        <div className="pb-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}