import { Layout } from "../../../ui/layout";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { RightBar } from "../../../ui/organisms/rightBar";
import { AttachmentsListDeparture } from "./list";
import { AttachmentDepRight } from "./right";

export function GestioneAllegatiPartenza() {
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Allegati', 'Partenza']}
            noPaddingLat
            headerLabel="Gestione Allegati Partenza"
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <AttachmentsListDeparture />
                
                <div className="bg-brandPrimary-100" style={{ flex: 0.3, borderTopRightRadius: '20px' }}>
                    <RightBar>
                        <AttachmentDepRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
}