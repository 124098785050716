import axios from "axios";
import { DepartureProtocolService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import { DepartureProtocolAttachmentDTO, DepartureProtocolCreationUpdateDTO, DepartureProtocolDTO, DepartureProtocolObjectDTO, GetAllDepartureProtocolAttachmentsDTO, GetAllDepartureProtocolAttachmentsFiltersDTO, GetAllDepartureProtocolsDTO, GetAllDepartureProtocolsFiltersDTO, ReportRequestExcelDTO } from "./dto";
import { format } from "date-fns";

export class DepartureProtocolServiceImpl implements DepartureProtocolService {

    public DepartureProtocolCreation(data: DepartureProtocolCreationUpdateDTO): Promise<string> {
        return axios.post("/api/departure-protocol",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateDepartureProtocol(data: FormData, id: string): Promise<string> {
        return axios.put("/api/departure-protocol/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteDepartureProtocol(id: string): Promise<void> {
        return axios.delete("/api/departure-protocol/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllDepartureProtocols(filters: GetAllDepartureProtocolsFiltersDTO): Promise<GetAllDepartureProtocolsDTO> {
        return axios.get("/api/departure-protocol", {
            params: {
                attachment: filters.attachmentId !== '' ? filters.attachmentId : undefined,
                customer: filters.client !== '' ? filters.client : undefined,
                entryChannel: filters.departureMeans !== '' ? filters.departureMeans : undefined,
                from: filters.from ? format(filters.from, 'yyyy-MM-dd') : undefined,
                to: filters.to ? format(filters.to, 'yyyy-MM-dd') : undefined,
                headQuarterOffice: filters.headquarter !== '' ? filters.headquarter : undefined,
                object: filters.objectId !== '' ? filters.objectId : undefined,
                objectSpec: filters.objectSpec !== '' ? filters.objectSpec : undefined,
                sender: filters.receiver !== '' ? filters.receiver : undefined,
                year: filters.year !== '' ? filters.year : undefined,
                protocolDate: filters.protocolDate ? format(filters.protocolDate, 'yyyy-MM-dd') : undefined,
                sort: filters.sort,
                number: filters.number !== '' ? filters.number : undefined,
                itemsPerPage: filters.itemsPerPage,
                page: filters.page
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetDepartureProtocolById(id: string): Promise<DepartureProtocolDTO> {
        return axios.get("/api/departure-protocol/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAllDepartureProtocolObjects(): Promise<DepartureProtocolObjectDTO[]> {
        return axios.get("/api/departure-protocol-object", {
            params: {
                itemsPerPage: 0,
                page: 0
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data.data)
    }

    public GetDepartureProtocolObjectById(id: string): Promise<DepartureProtocolObjectDTO> {
        return axios.get("/api/departure-protocol-object/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAllDepartureProtocolAttachments(filters: GetAllDepartureProtocolAttachmentsFiltersDTO): Promise<GetAllDepartureProtocolAttachmentsDTO> {
        return axios.get("/api/departure-protocol-attachment", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                sort: filters.sort,
                order: filters.order,
                page: filters.page
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public DeleteDepartureAttachment(id: string): Promise<void> {
        return axios.delete("/api/departure-protocol-attachment/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetDepartureProtocolAttachmentById(id: string): Promise<DepartureProtocolAttachmentDTO> {
        return axios.get("/api/departure-protocol-attachment/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public RemoveFileFromProtocol(protocolId: string): Promise<void> {
        return axios.delete("/api/departure-protocol/remove-file/" + protocolId,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetProtDepartureExcel(filters: ReportRequestExcelDTO): Promise<any> {
        return axios.get("/api/download/departure-protocol-excel", {
            params: {
                from: filters.from ? format(filters.from, 'yyyy-MM-dd') : undefined,
                headQuarterOffice: filters.headQuarterOffice != '' ? filters.headQuarterOffice : undefined,
                to: filters.to ? format(filters.to, 'yyyy-MM-dd') : undefined,
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => {
            const base64String = response.data;

            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'protocollo.xls');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    public UploadAttachmentFile(data: FormData, id: string): Promise<void> {
        return axios.put("/api/departure-protocol/attachment-file/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => response.data);
    }

    public CreateDepartureProtocolAttachment(data: DepartureProtocolAttachmentDTO): Promise<void> {
        return axios.post("/api/departure-protocol-attachment",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateDepartureProtocolAttachment(data: DepartureProtocolAttachmentDTO, id: string): Promise<void> {
        return axios.put("/api/departure-protocol-attachment/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteDepartureProtocolAttachment(id: string): Promise<void> {
        return axios.delete("/api/departure-protocol-attachment/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

}