import React from "react";
import "./Toggle.css";
import { ToggleProps } from "./Toggle.types";

const Toggle: React.FC<ToggleProps> = ({
    ...props
}) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <label className="switch">
                <input disabled={props.disabled} onChange={(e) => props.onChange(e.currentTarget.checked)} checked={props.checked} type="checkbox" />
                <span className="slider round"></span>
            </label>
            {
                props.placeholder &&
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span id="ph">{props.placeholder}</span>
                    {
                        props.supportingText &&
                        <span className="slider-supportingtext">{props.supportingText}</span>
                    }
                </div>
            }
        </div>
    )
};

export default Toggle;