import './styles.scss'

interface Props {
    region: string
    client: string
    code: string
    activity?: string
    norm: string
}

export function ClientBanner(props: Props) {
    return (
        <div className='cb-box'>
            <div className="text-left">
                <div className="cb-bold-text">Regione </div>
                <div className="cb-text">{props.region}</div>
            </div>
            <div className="text-left">
                <div className="cb-bold-text">Cliente </div>
                <div className="cb-text">{props.client}</div>
            </div>
            <div className="text-left">
                <div className="cb-bold-text">Codice Cliente </div>
                <div className="cb-text">{props.code}</div>
            </div>
            <div className="text-left">
                <div className="cb-bold-text">Attività assoggettata </div>
                <div className="cb-text">
                    {!props.activity ? 'Nessuna' : props.activity}
                </div>
            </div>
            <div className="text-left">
                <div className="cb-bold-text">Norma di riferimento </div>
                <div className="cb-text">{props.norm}</div>
            </div>
        </div>
    )
}