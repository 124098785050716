import { useNavigate } from "react-router-dom";
import { keycloak } from "../../../lib/keycloak";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetAllEntryProtocolAttachments, GetAllEntryProtocolObjects, GetAllEntryProtocols, resetEntryProtocolCreationRequest, resetEntryProtocolCreationStatus, resetGetAllEPFilters, resetGetAllEntryProtocolAttachmentsStatus, resetGetAllEntryProtocolObjectsStatus, setGetAllEPFilterAttachmentId, setGetAllEPFilterClient, setGetAllEPFilterFrom, setGetAllEPFilterHeadquarter, setGetAllEPFilterNumber, setGetAllEPFilterObjectId, setGetAllEPFilterObjectSpec, setGetAllEPFilterSender, setGetAllEPFilterTo, setGetAllEPFilterYear, setGetAllEPFilterentryMeans } from "./slice";
import { EntryListProtocol } from "./list";
import { SelectCustom } from "../../../ui/molecules/select";
import { entryMeansOptions, OptionType, sot, yearOptions } from "../../../utils";
import Input from "../../../ui/molecules/input";
import { Calendar } from "../../../ui/molecules/calendar";


export function EntryProtocol() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const usersState = useAppSelector(state => state.users)
    useEffect(() => {
        dispatch(resetEntryProtocolCreationRequest())
        dispatch(resetEntryProtocolCreationStatus())
    }, [])

    useEffect(() => {
        if (usersState.findMeStatus === 'successfully') {
            if (usersState.findMeResponse.headQuarter != 'SC' && usersState.findMeResponse.role !== 'admin') {
                dispatch(setGetAllEPFilterHeadquarter(usersState.findMeResponse.headQuarter))
            }
        }
    }, [usersState.findMeStatus])

    const entryProtocolState = useAppSelector(state => state.entryProtocol)

    const [objects, setObjects] = useState<OptionType[]>([])
    const [attachments, setAttachments] = useState<OptionType[]>([])

    useEffect(() => {
        dispatch(GetAllEntryProtocolObjects())
        dispatch(GetAllEntryProtocolAttachments({
            itemsPerPage: 0,
            page: 0
        }))
    }, [])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolObjectsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolObjectsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolObjectsStatus())
            const data = entryProtocolState.getAllEntryProtocolObjectsResponse
            const newObjects: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setObjects(newObjects)
        }
    }, [entryProtocolState.getAllEntryProtocolObjectsStatus])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolAttachmentsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolAttachmentsStatus())
            const data = entryProtocolState.getAllEntryProtocolAttachmentsResponse
            const newAtts: OptionType[] = data.data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setAttachments(newAtts)
        }
    }, [entryProtocolState.getAllEntryProtocolAttachmentsStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Protocollo']}
            noPaddingLat
            headerLabel="Protocollo Entrata"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Protocollo Entrata" color={"orange"}
                        onClick={() => {
                            navigate('/addEntryProtocol')
                        }}
                    />

                </div>
            }
        >
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Sede/Ufficio</span>
                        </div>
                        <SelectCustom
                            placeholder={'Sede / Ufficio'}
                            options={sot}
                            disabled={usersState.findMeResponse.headQuarter != 'SC' && usersState.findMeResponse.role != 'admin'}
                            value={entryProtocolState.getAllEntryProtocolFilters.headquarter}
                            defaultValue={entryProtocolState.getAllEntryProtocolFilters.headquarter}
                            onChange={(value) => {
                                dispatch(setGetAllEPFilterHeadquarter(value))
                            }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Numero'}
                            value={entryProtocolState.getAllEntryProtocolFilters.number}
                            placeholder="Specifica Numero"
                            onChangeText={(text) => { dispatch(setGetAllEPFilterNumber(text)) }}
                        />

                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            key={entryProtocolState.getAllEntryProtocolFilters.year || 'default'}
                            placeholder={'Anno'}
                            value={entryProtocolState.getAllEntryProtocolFilters.year}
                            options={yearOptions}
                            onChange={(value) => dispatch(setGetAllEPFilterYear(value))}

                        />

                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Mezzo Entrata</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona'}
                            key={entryProtocolState.getAllEntryProtocolFilters.entryMeans || 'default'}
                            value={entryProtocolState.getAllEntryProtocolFilters.entryMeans}
                            options={entryMeansOptions}
                            onChange={(value) => dispatch(setGetAllEPFilterentryMeans(value))}

                        />

                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Dal</span>
                        </div>
                        <Calendar
                            onClear={(date) => dispatch(setGetAllEPFilterFrom(date))}
                            selected={entryProtocolState.getAllEntryProtocolFilters.from}
                            onChange={(date) => dispatch(setGetAllEPFilterFrom(date))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Al</span>
                        </div>
                        <Calendar
                            onClear={(date) => dispatch(setGetAllEPFilterTo(date))}
                            selected={entryProtocolState.getAllEntryProtocolFilters.to}
                            onChange={(date) => dispatch(setGetAllEPFilterTo(date))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Cliente'}
                            placeholder="Cliente"
                            value={entryProtocolState.getAllEntryProtocolFilters.client}
                            onChangeText={(text) => { dispatch(setGetAllEPFilterClient(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Mittente'}
                            placeholder="Mittente"
                            value={entryProtocolState.getAllEntryProtocolFilters.sender}
                            onChangeText={(text) => { dispatch(setGetAllEPFilterSender(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Allegato</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona Allegato'}
                            options={attachments}
                            key={entryProtocolState.getAllEntryProtocolFilters.attachmentId || 'default'}
                            value={entryProtocolState.getAllEntryProtocolFilters.attachmentId}
                            onChange={(value) => dispatch(setGetAllEPFilterAttachmentId(value))}

                        />

                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.49' }}>
                        <div className="text-left">
                            <span className="input-label">Oggetto</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona Oggetto'}
                            options={objects}
                            key={entryProtocolState.getAllEntryProtocolFilters.objectId || 'default'}
                            value={entryProtocolState.getAllEntryProtocolFilters.objectId}
                            onChange={(value) => dispatch(setGetAllEPFilterObjectId(value))}
                        />

                    </div>
                    <div style={{ flex: '0.5' }}>
                        <Input
                            label={'Specifiche Oggetto'}
                            placeholder="Specifiche"
                            
                            value={entryProtocolState.getAllEntryProtocolFilters.objectSpec}
                            onChangeText={(text) => { dispatch(setGetAllEPFilterObjectSpec(text)) }}
                        />
                    </div>
                </div>

                <div className="mt-2" style={{ display: 'flex', flex: 'wrap', gap: '24px', justifyContent: 'right', paddingRight: '20px' }}>
                    <Button
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"link"}
                        label="Pulisci"
                        color={"gray"}
                        onClick={() => dispatch(resetGetAllEPFilters())}
                    />
                    <Button
                        variant='solid'
                        color='orange'
                        size='sm'
                        iconPosition='off'
                        label='Cerca'
                        onClick={() => dispatch(GetAllEntryProtocols(entryProtocolState.getAllEntryProtocolFilters))}
                    />
                </div>
            </div>
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <EntryListProtocol />
            </div>
        </Layout>
    )
}