import { Fragment, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { activityOptions, OptionType, resultOptions, valutatoreOptions } from "../../../../utils"
import DatePicker from "react-datepicker"
import { CalendarIcon } from "../../../../ui/icons/calendar"
import Input from "../../../../ui/molecules/input"
import { EvaluationUpdate, EvaluationValidation, GetAllEvaluations, resetEvaluationError, resetEvaluationUpdateRequest, resetEvaluationUpdateStatus, resetEvaluationValidationStatus, setUpdateEvaluationActivityId, setUpdateEvaluationDate, setUpdateEvaluatorId, setUpdateNote, setUpdateResult } from "../slice"
import { format } from "date-fns"
import { SelectCustom } from "../../../../ui/molecules/select"
import TextArea from "../../../../ui/molecules/textArea"
import Button from "../../../../ui/molecules/button"
import { GetByIdPersonalManagement } from "../../slice"
import { Calendar } from "../../../../ui/molecules/calendar"

interface Props {
    close: () => void
}

export function ValutazioniPopupEdit(props: Props) {
    const evaluationState = useAppSelector(state => state.PMEvaluations)
    const PMState = useAppSelector(state => state.personalManagement)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (evaluationState.evaluationValidationStatus === 'successfully') {
            dispatch(resetEvaluationValidationStatus())
            dispatch(resetEvaluationError())
            dispatch(EvaluationUpdate({ body: evaluationState.evaluationUpdateRequest, id: evaluationState.evalIdToUpdate }));
        }
    }, [evaluationState.evaluationValidationStatus])

    useEffect(() => {
        if (evaluationState.evaluationUpdateStatus === 'successfully') {
            dispatch(resetEvaluationUpdateStatus())
            dispatch(resetEvaluationUpdateRequest())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
            props.close()
        }
    }, [evaluationState.evaluationUpdateStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>

                {/* FIRST ROW */}
                <div className="formAnagrafica " style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data valutazione</span>
                        </div>
                        <Calendar
                            selected={evaluationState.evaluationUpdateRequest.evaluationDate}
                            onChange={(date) => {
                                dispatch(setUpdateEvaluationDate(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Attività da valutare</span>
                        </div>
                        <SelectCustom
                            error={evaluationState.evaluationError.evaluationActivityId}
                            errorLabel="Attività mancante"
                            placeholder={'Attività da valutare'}
                            options={activityOptions}
                            onChange={(value) => dispatch(setUpdateEvaluationActivityId(value))}
                            defaultValue={evaluationState.evaluationUpdateRequest.evaluationActivityId}
                            value={evaluationState.evaluationUpdateRequest.evaluationActivityId}
                        />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Valutatore</span>
                        </div>
                        <SelectCustom
                            error={evaluationState.evaluationError.evaluatorId}
                            errorLabel="Valutatore mancante"
                            placeholder={'Valutatore'}
                            options={valutatoreOptions}
                            onChange={(value) => dispatch(setUpdateEvaluatorId(value))}
                            defaultValue={evaluationState.evaluationUpdateRequest.evaluatorId}
                            value={evaluationState.evaluationUpdateRequest.evaluatorId}
                        />

                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Esito</span>
                        </div>
                        <SelectCustom
                            error={evaluationState.evaluationError.result}
                            errorLabel="Esito mancante"
                            placeholder={'Esito'}
                            options={resultOptions}
                            onChange={(value) => dispatch(setUpdateResult(value))}
                            defaultValue={evaluationState.evaluationUpdateRequest.result}
                            value={evaluationState.evaluationUpdateRequest.result}
                        />

                    </div>
                </div>

                {/* THIRD ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Note sulla valutazione</span>
                        </div>
                        <TextArea
                            defaultValue={evaluationState.evaluationUpdateRequest.note}
                            value={evaluationState.evaluationUpdateRequest.note}
                            onChangeText={(text) => dispatch(setUpdateNote(text))} />
                    </div>

                </div>

            </div>
            {/* BUTTONS */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetEvaluationError())
                        dispatch(resetEvaluationUpdateRequest());
                        props.close();
                    }}
                />
                <div className="pl-2">
                    <Button
                        size="md"
                        variant="solid"
                        iconPosition="off"
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(EvaluationValidation(evaluationState.evaluationUpdateRequest))
                        }}
                    />
                </div>
            </div>
        </Fragment>
    )
}