import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import Toggle from "../../../ui/molecules/toggle";
import { normativaCategories } from "../../../utils";
import { DepartureProtocolAttachmentCreation, DepartureProtocolAttachmentUpdate, setPPAttCoding, setPPAttCodingUpdate, setPPAttDescription, setPPAttDescriptionUpdate } from "../../protocol/departure/slice";

interface Props {
    close: () => void
}

export function PopupDepartureEdit(props: Props) {
    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    return (
        <div>
            <div className="bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ borderTopWidth: 1 }}>

                <div className='pb-3' style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div className='pb-3' style={{ flex: '0.5' }}>
                        <Input
                            label={'Descrizione'}
                            placeholder="Specifica descrizione..."
                            defaultValue={departureProtocolState.departureProtocolAttachmentUpdateRequest.description}
                            onChangeText={(text) => {
                                dispatch(setPPAttDescriptionUpdate(text))
                            }}
                        />
                    </div>

                    <div className='pb-3' style={{ flex: '0.5' }}>
                        <Input
                            label={'Codifica'}
                            placeholder="Specifica codifica..."
                            defaultValue={departureProtocolState.departureProtocolAttachmentUpdateRequest.coding}
                            onChangeText={(text) => {
                                dispatch(setPPAttCodingUpdate(text))
                            }}
                        />
                    </div>
                </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                />
                <div className="pl-2">
                    <Button
                        size="md"
                        variant="solid"
                        iconPosition="off"
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            if (departureProtocolState.departureProtocolAttachmentUpdateRequest.id) {
                                dispatch(DepartureProtocolAttachmentUpdate({ body: departureProtocolState.departureProtocolAttachmentUpdateRequest, id: departureProtocolState.departureProtocolAttachmentUpdateRequest.id }))
                            }

                        }}
                    />
                </div>
            </div>
        </div>
    )
}