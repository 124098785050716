import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks'
import Button from '../../../ui/molecules/button'
import Input from '../../../ui/molecules/input'
import { SelectCustom } from '../../../ui/molecules/select'
import Toggle from '../../../ui/molecules/toggle'
import { normativaCategories } from '../../../utils'
import { DepartureProtocolAttachmentCreation, setPPAttCoding, setPPAttDescription } from '../../protocol/departure/slice'
import '../styles.scss'

export function AttachmentDepRight() {
    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    return (
        <div style={{ padding: '0px 24px 24px 24px' }}>
            <div className="text-left pb-3 pt-5">
                <span className="rb-title" >
                    Allegati
                </span>
            </div>

            <div className='pb-3'>
                <Input
                    label={'Descrizione'}
                    placeholder="Specifica descrizione..."
                    value={departureProtocolState.departureProtocolAttachmentCreationRequest.description}
                    onChangeText={(text) => {
                        dispatch(setPPAttDescription(text))
                    }}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Codifica'}
                    placeholder="Specifica codifica..."
                    value={departureProtocolState.departureProtocolAttachmentCreationRequest.coding}
                    onChangeText={(text) => {
                        dispatch(setPPAttCoding(text))
                    }}
                />
            </div>

            <div>
                <Button
                    variant='solid'
                    color='orange'
                    iconPosition='off'
                    size='md'
                    label='Aggiungi Allegato'
                    onClick={() => {
                        dispatch(DepartureProtocolAttachmentCreation(departureProtocolState.departureProtocolAttachmentCreationRequest))
                    }
                    }
                />
            </div>

        </div>
    )
}