import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { NewDepartureProtocolService } from "./dataService/service"
import { DepartureProtocolAttachmentDTO, DepartureProtocolAttachmentFileDTO, DepartureProtocolCreationUpdateDTO, DepartureProtocolDTO, DepartureProtocolErrorsDTO, DepartureProtocolFileAndAttachmentRequestDTO, DepartureProtocolObjAttErrorsDTO, DepartureProtocolObjectDTO, GetAllDepartureProtocolAttachmentsDTO, GetAllDepartureProtocolAttachmentsFiltersDTO, GetAllDepartureProtocolsDTO, GetAllDepartureProtocolsFiltersDTO, ReportRequestExcelDTO } from "./dataService/dto"
import { GetObjectByIdDTO } from "../../../objectStorage/dataService/dto";

interface DepartureProtocolState {
    departureProtocols: DepartureProtocolDTO[]
    departureProtocolCreationRequest: DepartureProtocolCreationUpdateDTO
    departureProtocolUpdateRequest: DepartureProtocolCreationUpdateDTO
    departureProtocolIdToUpdate: string
    fromCreationToUpdate: boolean
    departureProtocolObjectCreationRequest: DepartureProtocolObjectDTO
    departureProtocolObjectUpdateRequest: DepartureProtocolObjectDTO
    departureProtocolObjectIdToUpdate: string

    departureProtocolErrors: DepartureProtocolErrorsDTO
    departureProtocolObjAttErrors: DepartureProtocolObjAttErrorsDTO
    getAllDepartureProtocolAttachmentsFilters: GetAllDepartureProtocolAttachmentsFiltersDTO

    departureProtocolUpdateFileAndAtt: DepartureProtocolFileAndAttachmentRequestDTO[]
    updatedDepartureProtocolFileAndAtts?: DepartureProtocolAttachmentFileDTO[]

    reportExcelFilters: ReportRequestExcelDTO

    departureProtocolCreationStatus: PromiseStatuses
    departureProtocolUpdateStatus: PromiseStatuses
    departureProtocolDeleteStatus: PromiseStatuses
    getAllDepartureProtocolsStatus: PromiseStatuses
    getDepartureProtocolByIdStatus: PromiseStatuses

    departureProtocolObjectCreationStatus: PromiseStatuses
    departureProtocolObjectUpdateStatus: PromiseStatuses
    departureProtocolObjectDeleteStatus: PromiseStatuses
    getAllDepartureProtocolObjectsStatus: PromiseStatuses
    getDepartureProtocolObjectByIdStatus: PromiseStatuses
    departureProtocolRemoveFileStatus: PromiseStatuses

    departureProtocolValidationStatus: PromiseStatuses
    departureProtocolObjAttValidationStatus: PromiseStatuses

    departureProtocolAttachmentCreationStatus: PromiseStatuses
    departureProtocolAttachmentUpdateStatus: PromiseStatuses
    departureProtocolAttachmentDeleteStatus: PromiseStatuses
    getAllDepartureProtocolAttachmentsStatus: PromiseStatuses
    getDepartureProtocolAttachmentByIdStatus: PromiseStatuses

    departureProtocolUploadAttachmentFileStatus: PromiseStatuses

    fileToUpdate?: File
    updatedFile?: GetObjectByIdDTO

    departureProtocolAttachmentCreationRequest: DepartureProtocolAttachmentDTO
    departureProtocolAttachmentUpdateRequest: DepartureProtocolAttachmentDTO
    departureProtocolAttachmentIdToUpdate: string

    // RESPONSE
    departureProtocolCreationResponse?: string
    departureProtocolUpdateResponse?: string

    getAllDepartureProtocolsResponse?: GetAllDepartureProtocolsDTO
    getDepartureProtocolByIdResponse?: DepartureProtocolDTO

    getAllDepartureProtocolObjectsResponse?: DepartureProtocolObjectDTO[]
    getDepartureProtocolObjectByIdResponse?: DepartureProtocolObjectDTO

    getAllDepartureProtocolAttachmentsResponse?: GetAllDepartureProtocolAttachmentsDTO
    getDepartureProtocolAttachmentByIdResponse?: DepartureProtocolAttachmentDTO

    //filters
    getAllDepartureProtocolFilters: GetAllDepartureProtocolsFiltersDTO

}

const initialState: DepartureProtocolState = {
    departureProtocols: [],
    fromCreationToUpdate: false,
    reportExcelFilters: {},
    departureProtocolCreationRequest: {
        headQuarter: '',
        protocolDate: new Date(),
        receiver: '',
        customerIds: [],
        departureObjectId: '',
        send: new Date(),
        usersVisibility: false,
        departureAttachmentIds: [],
        userId: ''
    },
    departureProtocolUpdateRequest: {
        headQuarter: '',
        receiver: '',
        customerIds: [],
        departureObjectId: '',
        send: new Date(),
        usersVisibility: false,
        departureAttachmentIds: []
    },
    departureProtocolUpdateFileAndAtt: [],
    departureProtocolIdToUpdate: '',
    departureProtocolObjectCreationRequest: {
        description: '',
        coding: '',
        status: true
    },
    departureProtocolObjectUpdateRequest: {
        description: '',
        coding: '',
        status: true
    },
    departureProtocolErrors: {
        headQuarter: false,
        protocolDate: false,
        receiver: false,
        entryObjectId: false,
    },
    departureProtocolObjAttErrors: {
        coding: false,
        description: false
    },
    departureProtocolAttachmentCreationRequest: {
        description: '',
        coding: '',
        status: true,
    },
    departureProtocolAttachmentUpdateRequest: {
        description: '',
        coding: '',
        status: true,
    },
    getAllDepartureProtocolAttachmentsFilters: {
        itemsPerPage: 0,
        page: 0
    },
    departureProtocolAttachmentIdToUpdate: '',
    departureProtocolObjectIdToUpdate: '',
    departureProtocolCreationStatus: 'idle',
    departureProtocolUpdateStatus: 'idle',
    departureProtocolDeleteStatus: 'idle',
    getAllDepartureProtocolsStatus: 'idle',
    getDepartureProtocolByIdStatus: 'idle',

    departureProtocolObjectCreationStatus: 'idle',
    departureProtocolObjectUpdateStatus: 'idle',
    departureProtocolObjectDeleteStatus: 'idle',
    getAllDepartureProtocolObjectsStatus: 'idle',
    getDepartureProtocolObjectByIdStatus: 'idle',

    departureProtocolValidationStatus: 'idle',
    departureProtocolObjAttValidationStatus: 'idle',


    departureProtocolAttachmentCreationStatus: 'idle',
    departureProtocolAttachmentUpdateStatus: 'idle',
    departureProtocolAttachmentDeleteStatus: 'idle',
    getAllDepartureProtocolAttachmentsStatus: 'idle',
    getDepartureProtocolAttachmentByIdStatus: 'idle',
    departureProtocolRemoveFileStatus: 'idle',

    departureProtocolUploadAttachmentFileStatus: 'idle',

    //filters
    getAllDepartureProtocolFilters: {
        itemsPerPage: '50',
        page: 0
    }


}

export const DepartureProtocolCreation = createAsyncThunk(
    'protocol/Departure/Creation',
    async (body: DepartureProtocolCreationUpdateDTO, thunkApi): Promise<string> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.DepartureProtocolCreation(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DepartureProtocolUpdate = createAsyncThunk(
    'protocol/Departure/Update',
    async (request: { body: FormData, id: string }, thunkApi): Promise<string> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.UpdateDepartureProtocol(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DepartureProtocolDelete = createAsyncThunk(
    'protocol/Departure/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.DeleteDepartureProtocol(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllDepartureProtocols = createAsyncThunk(
    'protocol/Departure/GetAll',
    async (filters: GetAllDepartureProtocolsFiltersDTO, thunkApi): Promise<GetAllDepartureProtocolsDTO> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.GetAllDepartureProtocols(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetDepartureProtocolById = createAsyncThunk(
    'protocol/Departure/GetById',
    async (id: string, thunkApi): Promise<DepartureProtocolDTO> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.GetDepartureProtocolById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllDepartureProtocolObjects = createAsyncThunk(
    'protocol/Departure/Object/GetAll',
    async (thunkApi): Promise<DepartureProtocolObjectDTO[]> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.GetAllDepartureProtocolObjects()
    },
)

export const GetAllDepartureProtocolAttachments = createAsyncThunk(
    'protocol/Departure/Attachment/GetAll',
    async (filters: GetAllDepartureProtocolAttachmentsFiltersDTO ,thunkApi): Promise<GetAllDepartureProtocolAttachmentsDTO> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.GetAllDepartureProtocolAttachments(filters)
    },
)

export const DepartureProtocolAttachmentDelete = createAsyncThunk(
    'protocol/Departure/Attachment/Delete',
    async (id: string ,thunkApi): Promise<void> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.DeleteDepartureProtocolAttachment(id)
    },
)

export const GetDepartureProtocolAttachmentById = createAsyncThunk(
    'protocol/Departure/Attachment/GetById',
    async (id: string, thunkApi): Promise<DepartureProtocolAttachmentDTO> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.GetDepartureProtocolAttachmentById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetProtDepartureExcel = createAsyncThunk(
    'protocol/Departure/GetProtDepartureExcel',
    async (filters: ReportRequestExcelDTO, thunkApi): Promise<any> => {
        const departureProtocolService = NewDepartureProtocolService()
        return departureProtocolService.GetProtDepartureExcel(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DepartureProtocolAttachmentCreation = createAsyncThunk(
    'protocol/Departure/Attachment/Creation',
    async (body: DepartureProtocolAttachmentDTO, thunkApi): Promise<void> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.CreateDepartureProtocolAttachment(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DepartureProtocolAttachmentUpdate = createAsyncThunk(
    'protocol/Departure/Attachment/Update',
    async (request: { body: DepartureProtocolAttachmentDTO, id: string }, thunkApi): Promise<void> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.UpdateDepartureProtocolAttachment(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DepartureProtocolRemoveFile = createAsyncThunk(
    'protocol/Departure/RemoveFile',
    async (protocolId: string, thunkApi): Promise<void> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.RemoveFileFromProtocol(protocolId).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DepartureProtocolUploadAttachmentFile = createAsyncThunk(
    'protocol/Departure/UploadAttFile',
    async (request: { data: FormData, id: string }, thunkApi): Promise<void> => {
        const departureProtocolService = NewDepartureProtocolService()

        return departureProtocolService.UploadAttachmentFile(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)


export const DepartureProtocolValidation = createAsyncThunk(
    'protocol/Departure/validation',
    async (request: DepartureProtocolCreationUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetDepartureProtocolErrors())

        if (request.headQuarter === '') {
            thunkApi.dispatch(setValidateHeadquarter(true))
            isValid = false
        }

        if (request.protocolDate === null) {
            thunkApi.dispatch(setValidateProtocolDate(true))
            isValid = false
        }

        if (request.receiver === '') {
            thunkApi.dispatch(setValidateReceiver(true))
            isValid = false
        }

        if (request.departureObjectId === '') {
            thunkApi.dispatch(setValidateDepartureObjectId(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()

    }
)

export const DepartureProtocolObjAttValidation = createAsyncThunk(
    'protocol/Departure/ObjAtt//validation',
    async (request: DepartureProtocolAttachmentDTO | DepartureProtocolObjectDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetDepartureProtocolObjAttErrors())

        if (request.coding === '') {
            thunkApi.dispatch(setValidateCoding(true))
            isValid = false
        }

        if (request.description === '') {
            thunkApi.dispatch(setValidateDescription(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()

    }
)

const DepartureProtocol = createSlice({
    name: 'protocol/departureProtocolSlice',
    initialState,
    reducers: {

        resetDepartureProtocols: (state) => {
            state.departureProtocols = []
        },
        addDepartureProtocol: (state, action) => {
            state.departureProtocols.push(action.payload)
        },
        setDepartureProtocol: (state, action) => {
            state.departureProtocols = action.payload
        },

        setPPHeadquarter: (state, action) => {
            state.departureProtocolCreationRequest.headQuarter = action.payload
        },
        setPProtocolDate: (state, action) => {
            state.departureProtocolCreationRequest.protocolDate = action.payload
        },
        setPPNation: (state, action) => {
            state.departureProtocolCreationRequest.nation = action.payload
        },
        setPPRegion: (state, action) => {
            state.departureProtocolCreationRequest.region = action.payload
        },
        setPPReceiver: (state, action) => {
            state.departureProtocolCreationRequest.receiver = action.payload
        },
        setPPCustomerIds: (state, action) => {
            state.departureProtocolCreationRequest.customerIds = action.payload
        },
        setPPCustomersNotRegistered: (state, action) => {
            state.departureProtocolCreationRequest.customersNotRegistered = action.payload
        },
        setPPDepartureObjectId: (state, action) => {
            state.departureProtocolCreationRequest.departureObjectId = action.payload
        },
        setPPObjectSpec: (state, action) => {
            state.departureProtocolCreationRequest.objectSpec = action.payload
        },
        setPPTotalPage: (state, action) => {
            state.departureProtocolCreationRequest.totalPage = action.payload
        },
        setPPDepartureChannel: (state, action) => {
            state.departureProtocolCreationRequest.departureChannel = action.payload
        },
        setPPSend: (state, action) => {
            state.departureProtocolCreationRequest.send = action.payload
        },
        setPPUsersVisibility: (state, action) => {
            state.departureProtocolCreationRequest.usersVisibility = action.payload
        },
        setPPLinkExistingProtocol: (state, action) => {
            state.departureProtocolCreationRequest.linkExistingProtocol = action.payload
        },
        setPPUserId: (state, action) => {
            state.departureProtocolCreationRequest.userId = action.payload
        },
        setNumRaccomandata: (state, action) => {
            state.departureProtocolCreationRequest.numeroRaccomandata = action.payload
        },
        setDataBolgetta: (state, action) => {
            state.departureProtocolCreationRequest.dataBolgetta = action.payload
        },
        setDataRicezioneCompiutaGiacenza: (state, action) => {
            state.departureProtocolCreationRequest.dataRicezioneCompiutaGiacenza = action.payload
        },
        setDataRitornoErratoIndirizzo: (state, action) => {
            state.departureProtocolCreationRequest.dataRitornoErratoIndirizzo = action.payload
        },
        setNumeroRaccomandataErratoIndirizzo: (state, action) => {
            state.departureProtocolCreationRequest.numeroRaccomandataErratoIndirizzo = action.payload
        },
        setCorriere: (state, action) => {
            state.departureProtocolCreationRequest.corriere = action.payload
        },
        setRaccomandataMano: (state, action) => {
            state.departureProtocolCreationRequest.raccomandataMano = action.payload
        },
        setPostaElettronica: (state, action) => {
            state.departureProtocolCreationRequest.postaElettronica = action.payload
        },
        setDataReceive: (state, action) => {
            state.departureProtocolCreationRequest.receive = action.payload
        },
        setRaccomandataAr: (state, action) => {
            state.departureProtocolCreationRequest.raccomandataAr = action.payload
        },
        setPEC: (state, action) => {
            state.departureProtocolCreationRequest.postaElettronicaCertificata = action.payload
        },
        resetDepartureProtocolCreationRequest: (state) => {
            state.departureProtocolCreationRequest = {
                headQuarter: '',
                protocolDate: new Date(),
                nation: undefined,
                region: undefined,
                receiver: '',
                customerIds: [],
                customersNotRegistered: undefined,
                departureObjectId: '',
                objectSpec: undefined,
                totalPage: undefined,
                departureChannel: undefined,
                send: new Date(),
                usersVisibility: false,
                linkExistingProtocol: undefined,
                departureAttachmentIds: []
            }
        },

        // REQUEST (EP UPDATE)
        setUpdatePPHeadquarter: (state, action) => {
            state.departureProtocolUpdateRequest.headQuarter = action.payload
        },
        setUpdatePPNation: (state, action) => {
            state.departureProtocolUpdateRequest.nation = action.payload
        },
        setUpdatePPRegion: (state, action) => {
            state.departureProtocolUpdateRequest.region = action.payload
        },
        setUpdatePPProtocolDate: (state, action) => {
            state.departureProtocolUpdateRequest.protocolDate = action.payload
        },
        setUpdatepPSender: (state, action) => {
            state.departureProtocolUpdateRequest.receiver = action.payload
        },
        setUpdatePPCustomerIds: (state, action) => {
            state.departureProtocolUpdateRequest.customerIds = action.payload
        },
        setUpdatePPCustomersNotRegistered: (state, action) => {
            state.departureProtocolUpdateRequest.customersNotRegistered = action.payload
        },
        setUpdatePPDepartureObjectId: (state, action) => {
            state.departureProtocolUpdateRequest.departureObjectId = action.payload
        },
        setUpdatePPObjectSpec: (state, action) => {
            state.departureProtocolUpdateRequest.objectSpec = action.payload
        },
        setUpdatePPTotalPage: (state, action) => {
            state.departureProtocolUpdateRequest.totalPage = action.payload
        },
        setUpdatePPDepartureChannel: (state, action) => {
            state.departureProtocolUpdateRequest.departureChannel = action.payload
        },
        setUpdatePPSend: (state, action) => {
            state.departureProtocolUpdateRequest.send = action.payload
        },
        setUpdatePPUsersVisibility: (state, action) => {
            state.departureProtocolUpdateRequest.usersVisibility = action.payload
        },
        setUpdatePPLinkExistingProtocol: (state, action) => {
            state.departureProtocolUpdateRequest.linkExistingProtocol = action.payload
        },
        setUpdateNumRaccomandata: (state, action) => {
            state.departureProtocolUpdateRequest.numeroRaccomandata = action.payload
        },
        setUpdateDataBolgetta: (state, action) => {
            state.departureProtocolUpdateRequest.dataBolgetta = action.payload
        },
        setUpdateDataRicezioneCompiutaGiacenza: (state, action) => {
            state.departureProtocolUpdateRequest.dataRicezioneCompiutaGiacenza = action.payload
        },
        setUpdateDataRitornoErratoIndirizzo: (state, action) => {
            state.departureProtocolUpdateRequest.dataRitornoErratoIndirizzo = action.payload
        },
        setUpdateNumeroRaccomandataErratoIndirizzo: (state, action) => {
            state.departureProtocolUpdateRequest.numeroRaccomandataErratoIndirizzo = action.payload
        },
        setUpdateCorriere: (state, action) => {
            state.departureProtocolUpdateRequest.corriere = action.payload
        },
        setUpdateRaccomandataMano: (state, action) => {
            state.departureProtocolUpdateRequest.raccomandataMano = action.payload
        },
        setUpdatePostaElettronica: (state, action) => {
            state.departureProtocolUpdateRequest.postaElettronica = action.payload
        },
        setUpdateDataReceive: (state, action) => {
            state.departureProtocolUpdateRequest.receive = action.payload
        },
        setUpdateRaccomandataAr: (state, action) => {
            state.departureProtocolUpdateRequest.raccomandataAr = action.payload
        },
        setUpdatePEC: (state, action) => {
            state.departureProtocolUpdateRequest.postaElettronicaCertificata = action.payload
        },
        setUpdatePPDepartureAttachmentIds: (state, action) => {
            state.departureProtocolUpdateRequest.departureAttachmentIds = action.payload
        },
        addDefaultUpdatePPDepartureAttachmentIds: (state) => {
            state.departureProtocolUpdateRequest.departureAttachmentIds.push('')
        },
        addUpdatePPDepartureAttachmentId: (state, action) => {
            state.departureProtocolUpdateRequest.departureAttachmentIds.push(action.payload)
        },
        setUpdatePPDepartureAttachmentIdsByIndex: (state, action) => {
            state.departureProtocolUpdateRequest.departureAttachmentIds[action.payload.index] = action.payload.data
        },
        setDepartureProtocolIdToUpdate: (state, action) => {
            state.departureProtocolIdToUpdate = action.payload
        },
        setFromCreationToUpdate: (state, action) => {
            state.fromCreationToUpdate = action.payload
        },

        // FILE & ATT
        addPPFileAndAtt: (state, action) => {
            state.departureProtocolUpdateFileAndAtt.push(action.payload)
        },
        setPPFileAndAtt: (state, action) => {
            state.departureProtocolUpdateFileAndAtt = action.payload
        },
        resetPPFileAndAtt: (state) => {
            state.departureProtocolUpdateFileAndAtt = []
        },
        setUpdatedPPAttachmentFile: (state, action) => {
            state.updatedDepartureProtocolFileAndAtts = action.payload
        },

        // ERRORS
        setValidateHeadquarter: (state, action) => {
            state.departureProtocolErrors.headQuarter = action.payload
        },
        setValidateProtocolDate: (state, action) => {
            state.departureProtocolErrors.protocolDate = action.payload
        },
        setValidateReceiver: (state, action) => {
            state.departureProtocolErrors.receiver = action.payload
        },
        setValidateDepartureObjectId: (state, action) => {
            state.departureProtocolErrors.entryObjectId = action.payload
        },
        resetDepartureProtocolErrors: (state) => {
            state.departureProtocolErrors = {
                headQuarter: false,
                protocolDate: false,
                receiver: false,
                entryObjectId: false,
            }
        },

        // REQUEST (EP ATT CREATION)
        setPPAttDescription: (state, action) => {
            state.departureProtocolAttachmentCreationRequest.description = action.payload
        },
        setPPAttCoding: (state, action) => {
            state.departureProtocolAttachmentCreationRequest.coding = action.payload
        },
        resetPPAttCreationRequest: (state) => {
            state.departureProtocolAttachmentCreationRequest = {
                description: '',
                coding: '',
                status: true,
            }
        },

        setPPAttDescriptionUpdate: (state, action) => {
            state.departureProtocolAttachmentUpdateRequest.description = action.payload
        },
        setPPAttCodingUpdate: (state, action) => {
            state.departureProtocolAttachmentUpdateRequest.coding = action.payload
        },
        resetPPAttRequestUpdate: (state) => {
            state.departureProtocolAttachmentUpdateRequest = {
                description: '',
                coding: '',
                status: true,
            }
        },

        setAttachtoUpdate: (state, action) => {
            state.departureProtocolAttachmentUpdateRequest = action.payload
        },

        // FILE
        setFileToUpdate: (state, action) => {
            state.fileToUpdate = action.payload
        },
        setUpdatedFile: (state, action) => {
            state.updatedFile = action.payload
        },

        resetDepartureProtocolUpdateRequest: (state) => {
            state.departureProtocolUpdateRequest = {
                headQuarter: '',
                nation: undefined,
                region: undefined,
                receiver: '',
                customerIds: [],
                customersNotRegistered: undefined,
                departureObjectId: '',
                objectSpec: undefined,
                totalPage: undefined,
                departureChannel: undefined,
                send: new Date(),
                usersVisibility: false,
                linkExistingProtocol: undefined,
                departureAttachmentIds: []
            }
        },

        // ERRORS (OBJ/ATT)
        setValidateCoding: (state, action) => {
            state.departureProtocolObjAttErrors.coding = action.payload
        },
        setValidateDescription: (state, action) => {
            state.departureProtocolObjAttErrors.description = action.payload
        },
        resetDepartureProtocolObjAttErrors: (state) => {
            state.departureProtocolObjAttErrors = {
                coding: false,
                description: false
            }
        },

        // REQUEST (EP OBJ CREATION)
        setPPObjDescription: (state, action) => {
            state.departureProtocolObjectCreationRequest.description = action.payload
        },
        setPPObjCoding: (state, action) => {
            state.departureProtocolObjectCreationRequest.coding = action.payload
        },
        resetPPObjCreationRequest: (state) => {
            state.departureProtocolObjectCreationRequest = {
                description: '',
                coding: '',
                status: true
            }
        },
        // FILTERS
        setGetAllPPFilterHeadquarter: (state, action) => {
            state.getAllDepartureProtocolFilters.headquarter = action.payload
        },
        setGetAllPPFilterNumber: (state, action) => {
            state.getAllDepartureProtocolFilters.number = action.payload
        },
        setGetAllPPFilterYear: (state, action) => {
            state.getAllDepartureProtocolFilters.year = action.payload
        },
        setGetAllPPFilterdepartureMeans: (state, action) => {
            state.getAllDepartureProtocolFilters.departureMeans = action.payload
        },
        setGetAllPPFilterFrom: (state, action) => {
            state.getAllDepartureProtocolFilters.from = action.payload
        },
        setGetAllPPFilterTo: (state, action) => {
            state.getAllDepartureProtocolFilters.to = action.payload
        },
        setGetAllPPFilterProtocolDate: (state, action) => {
            state.getAllDepartureProtocolFilters.protocolDate = action.payload
        },
        setGetAllPPFilterClient: (state, action) => {
            state.getAllDepartureProtocolFilters.client = action.payload
        },
        setGetAllPPFilterSender: (state, action) => {
            state.getAllDepartureProtocolFilters.receiver = action.payload
        },
        setGetAllPPFilterAttachmentId: (state, action) => {
            state.getAllDepartureProtocolFilters.attachmentId = action.payload
        },
        setGetAllPPFilterObjectId: (state, action) => {
            state.getAllDepartureProtocolFilters.objectId = action.payload
        },
        setGetAllPPFilterObjectSpec: (state, action) => {
            state.getAllDepartureProtocolFilters.objectSpec = action.payload
        },
        setGetAllPPFilterPage: (state, action) => {
            state.getAllDepartureProtocolFilters.page = action.payload
        },
        resetGetAllPPFilters: (state) => {
            state.getAllDepartureProtocolFilters = {
                number: '',
                client: '',
                receiver: '',
                objectSpec: '',
                itemsPerPage: '50',
                page: 0
            }
        },

        // FILTERS ATTACHMENTS
        setGetAllPPAttItemsPerPage: (state, action) => {
            state.getAllDepartureProtocolAttachmentsFilters.itemsPerPage = action.payload
        },
        setGetAllPPAttSort: (state, action) => {
            state.getAllDepartureProtocolAttachmentsFilters.sort = action.payload
        },
        setGetAllPPAttOrder: (state, action) => {
            state.getAllDepartureProtocolAttachmentsFilters.order = action.payload
        },
        setGetAllPPAttPage: (state, action) => {
            state.getAllDepartureProtocolAttachmentsFilters.page = action.payload
        },
        resetGetAllPPAtt: (state) => {
            state.getAllDepartureProtocolAttachmentsFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },
        resetGetAllPPFiltersDocuments: (state) => {
            state.getAllDepartureProtocolFilters = {
                number: '',
                receiver: '',
                objectSpec: '',
                objectId:'',
                itemsPerPage: '50'
            }
            
        },

        // FILTERS EXCEL 
        setHeadQuarterReportExcelDeparture: (state, action) => {
            state.reportExcelFilters.headQuarterOffice = action.payload
        },
        setFromReportExcelDeparture: (state, action) => {
            state.reportExcelFilters.from = action.payload
        },
        setToReportExcelDeparture: (state, action) => {
            state.reportExcelFilters.to = action.payload
        },

        // STATUS
        resetDepartureProtocolCreationStatus: (state) => {
            state.departureProtocolCreationStatus = 'idle'
        },
        resetDepartureProtocolUpdateStatus: (state) => {
            state.departureProtocolUpdateStatus = 'idle'
        },
        resetDepartureProtocolDeleteStatus: (state) => {
            state.departureProtocolDeleteStatus = 'idle'
        },
        resetGetAllDepartureProtocolsStatus: (state) => {
            state.getAllDepartureProtocolsStatus = 'idle'
        },
        resetGetDepartureProtocolByIdStatus: (state) => {
            state.getDepartureProtocolByIdStatus = 'idle'
        },
        resetDepartureProtocolObjectCreationStatus: (state) => {
            state.departureProtocolObjectCreationStatus = 'idle'
        },
        resetDepartureProtocolObjectUpdateStatus: (state) => {
            state.departureProtocolObjectUpdateStatus = 'idle'
        },
        resetDepartureProtocolObjectDeleteStatus: (state) => {
            state.departureProtocolObjectDeleteStatus = 'idle'
        },
        resetGetAllDepartureProtocolObjectsStatus: (state) => {
            state.getAllDepartureProtocolObjectsStatus = 'idle'
        },
        resetGetDepartureProtocolObjectByIdStatus: (state) => {
            state.getDepartureProtocolObjectByIdStatus = 'idle'
        },
        resetDepartureProtocolAttachmentCreationStatus: (state) => {
            state.departureProtocolAttachmentCreationStatus = 'idle'
        },
        resetDepartureProtocolAttachmentUpdateStatus: (state) => {
            state.departureProtocolAttachmentUpdateStatus = 'idle'
        },
        resetDepartureProtocolAttachmentDeleteStatus: (state) => {
            state.departureProtocolAttachmentDeleteStatus = 'idle'
        },
        resetGetAllDepartureProtocolAttachmentsStatus: (state) => {
            state.getAllDepartureProtocolAttachmentsStatus = 'idle'
        },
        resetGetDepartureProtocolAttachmentByIdStatus: (state) => {
            state.getDepartureProtocolAttachmentByIdStatus = 'idle'
        },
        resetDepartureProtocolValidationStatus: (state) => {
            state.departureProtocolValidationStatus = 'idle'
        },
        resetDepartureProtocolRemoveFileStatus: (state) => {
            state.departureProtocolRemoveFileStatus = 'idle'
        },
        resetDepartureProtocolUploadAttachmentFileStatus: (state) => {
            state.departureProtocolUploadAttachmentFileStatus = 'idle'
        },
        resetDepartureProtocolObjAttValidationStatus: (state) => {
            state.departureProtocolObjAttValidationStatus = 'idle'
        },

    },
    extraReducers(builder) {
        builder

            // [DEPARTURE PROTOCOL]
            // GET ALL
            .addCase(GetAllDepartureProtocols.pending, (state) => {
                state.getAllDepartureProtocolsStatus = 'loading'
            })
            .addCase(GetAllDepartureProtocols.fulfilled, (state, action) => {
                state.getAllDepartureProtocolsStatus = 'successfully'
                state.getAllDepartureProtocolsResponse = action.payload
            })
            .addCase(GetAllDepartureProtocols.rejected, (state) => {
                state.getAllDepartureProtocolsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetDepartureProtocolById.pending, (state) => {
                state.getDepartureProtocolByIdStatus = 'loading'
            })
            .addCase(GetDepartureProtocolById.fulfilled, (state, action) => {
                state.getDepartureProtocolByIdStatus = 'successfully'
                state.getDepartureProtocolByIdResponse = action.payload
            })
            .addCase(GetDepartureProtocolById.rejected, (state) => {
                state.getDepartureProtocolByIdStatus = 'failed'
            })

            // CREATION
            .addCase(DepartureProtocolCreation.pending, (state) => {
                state.departureProtocolCreationStatus = 'loading'
            })
            .addCase(DepartureProtocolCreation.fulfilled, (state, action) => {
                state.departureProtocolCreationStatus = 'successfully'
                state.departureProtocolCreationResponse = action.payload
            })
            .addCase(DepartureProtocolCreation.rejected, (state) => {
                state.departureProtocolCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(DepartureProtocolUpdate.pending, (state) => {
                state.departureProtocolUpdateStatus = 'loading'
            })
            .addCase(DepartureProtocolUpdate.fulfilled, (state, action) => {
                state.departureProtocolUpdateStatus = 'successfully'
                state.departureProtocolUpdateResponse = action.payload
            })
            .addCase(DepartureProtocolUpdate.rejected, (state) => {
                state.departureProtocolUpdateStatus = 'failed'
            })

            // VALIDATION
            .addCase(DepartureProtocolValidation.pending, (state) => {
                state.departureProtocolValidationStatus = 'loading'
            })
            .addCase(DepartureProtocolValidation.fulfilled, (state) => {
                state.departureProtocolValidationStatus = 'successfully'
            })
            .addCase(DepartureProtocolValidation.rejected, (state) => {
                state.departureProtocolValidationStatus = 'failed'
            })

            // VALIDATION (Obj/Att)
            .addCase(DepartureProtocolObjAttValidation.pending, (state) => {
                state.departureProtocolObjAttValidationStatus = 'loading'
            })
            .addCase(DepartureProtocolObjAttValidation.fulfilled, (state) => {
                state.departureProtocolObjAttValidationStatus = 'successfully'
            })
            .addCase(DepartureProtocolObjAttValidation.rejected, (state) => {
                state.departureProtocolObjAttValidationStatus = 'failed'
            })

            // [FILE] DELETE
            .addCase(DepartureProtocolRemoveFile.pending, (state) => {
                state.departureProtocolRemoveFileStatus = 'loading'
            })
            .addCase(DepartureProtocolRemoveFile.fulfilled, (state) => {
                state.departureProtocolRemoveFileStatus = 'successfully'
            })
            .addCase(DepartureProtocolRemoveFile.rejected, (state) => {
                state.departureProtocolRemoveFileStatus = 'failed'
            })

            // [ATT FILE] UPLOAD
            .addCase(DepartureProtocolUploadAttachmentFile.pending, (state) => {
                state.departureProtocolUploadAttachmentFileStatus = 'loading'
            })
            .addCase(DepartureProtocolUploadAttachmentFile.fulfilled, (state) => {
                state.departureProtocolUploadAttachmentFileStatus = 'successfully'
            })
            .addCase(DepartureProtocolUploadAttachmentFile.rejected, (state) => {
                state.departureProtocolUploadAttachmentFileStatus = 'failed'
            })

            // [DEPARTURE PROTOCOL OBJECT]
            // GET ALL
            .addCase(GetAllDepartureProtocolObjects.pending, (state) => {
                state.getAllDepartureProtocolObjectsStatus = 'loading'
            })
            .addCase(GetAllDepartureProtocolObjects.fulfilled, (state, action) => {
                state.getAllDepartureProtocolObjectsStatus = 'successfully'
                state.getAllDepartureProtocolObjectsResponse = action.payload
            })
            .addCase(GetAllDepartureProtocolObjects.rejected, (state) => {
                state.getAllDepartureProtocolObjectsStatus = 'failed'
            })

            // [DEPARTURE PROTOCOL ATTACHMENT]
            // GET ALL
            .addCase(GetAllDepartureProtocolAttachments.pending, (state) => {
                state.getAllDepartureProtocolAttachmentsStatus = 'loading'
            })
            .addCase(GetAllDepartureProtocolAttachments.fulfilled, (state, action) => {
                state.getAllDepartureProtocolAttachmentsStatus = 'successfully'
                state.getAllDepartureProtocolAttachmentsResponse = action.payload
            })
            .addCase(GetAllDepartureProtocolAttachments.rejected, (state) => {
                state.getAllDepartureProtocolAttachmentsStatus = 'failed'
            })

            // [ENTRY PROTOCOL ATTACHMENT]
            // DELETE
            .addCase(DepartureProtocolAttachmentDelete.pending, (state) => {
                state.departureProtocolAttachmentDeleteStatus = 'loading'
            })
            .addCase(DepartureProtocolAttachmentDelete.fulfilled, (state, action) => {
                state.departureProtocolAttachmentDeleteStatus = 'successfully'
            })
            .addCase(DepartureProtocolAttachmentDelete.rejected, (state) => {
                state.departureProtocolAttachmentDeleteStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetDepartureProtocolAttachmentById.pending, (state) => {
                state.getDepartureProtocolAttachmentByIdStatus = 'loading'
            })
            .addCase(GetDepartureProtocolAttachmentById.fulfilled, (state, action) => {
                state.getDepartureProtocolAttachmentByIdStatus = 'successfully'
                state.getDepartureProtocolAttachmentByIdResponse = action.payload
            })
            .addCase(GetDepartureProtocolAttachmentById.rejected, (state) => {
                state.getDepartureProtocolAttachmentByIdStatus = 'failed'
            })

            // CREATION
            .addCase(DepartureProtocolAttachmentCreation.pending, (state) => {
                state.departureProtocolAttachmentCreationStatus = 'loading'
            })
            .addCase(DepartureProtocolAttachmentCreation.fulfilled, (state) => {
                state.departureProtocolAttachmentCreationStatus = 'successfully'
            })
            .addCase(DepartureProtocolAttachmentCreation.rejected, (state) => {
                state.departureProtocolAttachmentCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(DepartureProtocolAttachmentUpdate.pending, (state) => {
                state.departureProtocolAttachmentUpdateStatus = 'loading'
            })
            .addCase(DepartureProtocolAttachmentUpdate.fulfilled, (state) => {
                state.departureProtocolAttachmentUpdateStatus = 'successfully'
            })
            .addCase(DepartureProtocolAttachmentUpdate.rejected, (state) => {
                state.departureProtocolAttachmentUpdateStatus = 'failed'
            })

    }
})

export const {
    setDepartureProtocol,
    setPPCustomerIds,
    setPPCustomersNotRegistered,
    setPPDepartureChannel,
    setPPDepartureObjectId,
    setPPHeadquarter,
    setPPLinkExistingProtocol,
    setPPNation,
    setPPObjectSpec,
    setPProtocolDate,
    setPPReceiver,
    setPPRegion,
    setPPSend,
    setPPTotalPage,
    setPPUserId,
    setPPUsersVisibility,
    setUpdatePPHeadquarter,
    setUpdatePPNation,
    setUpdatePPRegion,
    setDepartureProtocolIdToUpdate,
    setFromCreationToUpdate,
    setUpdatePPCustomerIds,
    setUpdatePPCustomersNotRegistered,
    setUpdatePPDepartureAttachmentIds,
    setUpdatePPDepartureAttachmentIdsByIndex,
    setPPObjCoding,
    setPPObjDescription,
    setUpdatePPDepartureChannel,
    setUpdatePPDepartureObjectId,
    setUpdatePPLinkExistingProtocol,
    setUpdatePPObjectSpec,
    setUpdatePPSend,
    setUpdatePPTotalPage,
    setUpdatePPUsersVisibility,
    setUpdatepPSender,
    setCorriere,
    setDataBolgetta,
    setNumRaccomandata,
    setPostaElettronica,
    setRaccomandataMano,
    setDataReceive,
    setDataRicezioneCompiutaGiacenza,
    setDataRitornoErratoIndirizzo,
    setNumeroRaccomandataErratoIndirizzo,
    addUpdatePPDepartureAttachmentId,
    addDefaultUpdatePPDepartureAttachmentIds,
    setUpdateCorriere,
    setUpdateDataBolgetta,
    setUpdateDataReceive,
    setUpdateDataRicezioneCompiutaGiacenza,
    setUpdateDataRitornoErratoIndirizzo,
    setUpdateNumRaccomandata,
    setUpdateNumeroRaccomandataErratoIndirizzo,
    setUpdatePostaElettronica,
    setUpdateRaccomandataMano,
    setPEC,
    setRaccomandataAr,
    setUpdatePEC,
    setUpdateRaccomandataAr,
    setUpdatePPProtocolDate,

    // ERRORS
    setValidateDepartureObjectId,
    setValidateHeadquarter,
    setValidateProtocolDate,
    setValidateReceiver,
    setValidateCoding,
    setValidateDescription,

    //FILE
    setFileToUpdate,
    addPPFileAndAtt,
    setPPFileAndAtt,
    resetPPFileAndAtt,
    setUpdatedFile,
    setUpdatedPPAttachmentFile,

    // REQUEST (EP ATT CREATION)
    setPPAttCoding,
    setPPAttDescription,
    resetPPAttCreationRequest,

    setPPAttDescriptionUpdate,
    setPPAttCodingUpdate,
    resetPPAttRequestUpdate,
    setAttachtoUpdate,

    //FILTERS
    setGetAllPPFilterAttachmentId,
    setGetAllPPFilterClient,
    setGetAllPPFilterFrom,
    setGetAllPPFilterHeadquarter,
    setGetAllPPFilterNumber,
    setGetAllPPFilterObjectId,
    setGetAllPPFilterObjectSpec,
    setGetAllPPFilterSender,
    setGetAllPPFilterTo,
    setGetAllPPFilterYear,
    setGetAllPPFilterdepartureMeans,
    setGetAllPPFilterPage,
    setGetAllPPFilterProtocolDate,
    setGetAllPPAttItemsPerPage,
    setGetAllPPAttOrder,
    setGetAllPPAttPage,
    setGetAllPPAttSort,
    resetGetAllPPAtt,
    resetGetAllPPFilters,

    //FILTERS EXCEL
    setFromReportExcelDeparture,
    setHeadQuarterReportExcelDeparture,
    setToReportExcelDeparture,

    // STATUS
    resetDepartureProtocols,
    resetDepartureProtocolCreationRequest,
    resetDepartureProtocolCreationStatus,
    resetDepartureProtocolUpdateRequest,
    resetDepartureProtocolUpdateStatus,
    resetPPObjCreationRequest,
    resetDepartureProtocolAttachmentCreationStatus,
    resetDepartureProtocolAttachmentDeleteStatus,
    resetDepartureProtocolAttachmentUpdateStatus,
    resetDepartureProtocolDeleteStatus,
    resetDepartureProtocolObjectCreationStatus,
    resetDepartureProtocolObjectDeleteStatus,
    resetDepartureProtocolObjectUpdateStatus,
    resetGetAllDepartureProtocolAttachmentsStatus,
    resetGetAllDepartureProtocolObjectsStatus,
    resetGetAllDepartureProtocolsStatus,
    resetGetDepartureProtocolAttachmentByIdStatus,
    resetGetDepartureProtocolByIdStatus,
    resetGetDepartureProtocolObjectByIdStatus,
    resetDepartureProtocolErrors,
    resetDepartureProtocolValidationStatus,
    resetDepartureProtocolRemoveFileStatus,
    resetDepartureProtocolUploadAttachmentFileStatus,
    resetDepartureProtocolObjAttValidationStatus,
    resetDepartureProtocolObjAttErrors,
    resetGetAllPPFiltersDocuments

} = DepartureProtocol.actions

export default DepartureProtocol.reducer