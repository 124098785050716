import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { PromiseStatuses } from "../../lib/utils"
import { BiologicalActivityDTO, ClienteEditCreationDTO, FindAllCustomersFilters, GetAddOnByIdResponseDTO, GetAddOnsResponseDTO, GetAllRappLegaleResponseDTO, GetCustomerByCodeDTO, GetCustomerByCompanyNameResponseDTO, GetRapplegaleByIdDTO, NewCustomerAnagraficaRequest, PostUpdateAddOnRequestDTO, RappLegaleEditCreationDTO, contactsByIdResponseDTO, contactsEditCreateRequestDTO, corporateByIdCenterDTO, corporateCenterDTO, findAllCustomersDTO, getCustomersByIdResponseDTO, ggAddonsDTO } from "./dto";
import { NewClientiService } from "./service";
import { act } from "react";

interface ClientiState {
    selectedTab: number
    clienteEditCreateRequest: ClienteEditCreationDTO
    rappLegaleEditCreateRequest: RappLegaleEditCreationDTO
    contactsCreateRequest: contactsEditCreateRequestDTO
    corporateCenters: corporateCenterDTO[];
    listCorporateCentersEdit: corporateCenterDTO[];
    NewCustomerAnagraficaRequest: NewCustomerAnagraficaRequest
    corporateCentersUpdate: corporateByIdCenterDTO[];
    customerCreateResponse?: number
    getCustomersResponse?: findAllCustomersDTO
    GetRappLegaleByIdResponse?: GetRapplegaleByIdDTO
    GetAllRappLegaleResponse?: GetAllRappLegaleResponseDTO
    GetContactsByIdResponse?: contactsByIdResponseDTO

    GetCorporateCenterByIdResponse?: corporateByIdCenterDTO
    GetCorporateCenterByIdStatus: PromiseStatuses
    GetCorporateCenterByIdEditResponse?: corporateByIdCenterDTO
    GetCorporateCenterByIdEditStatus: PromiseStatuses

    GetContactsByIdResponseStatus: PromiseStatuses
    customerCreateResponseStatus: PromiseStatuses
    customerEditStatus: PromiseStatuses
    rappLegaleCreateResponseStatus: PromiseStatuses
    rappLegaleUpdateStatus: PromiseStatuses
    rappLegaleDeleteStatus: PromiseStatuses
    rappLegaleDeactivateStatus: PromiseStatuses
    contactsCreateResponseStatus: PromiseStatuses
    corporateCenterCreateResponseStatus: PromiseStatuses
    getCustomersByIdResponseStatus: PromiseStatuses
    getCustomersByCodeResponseStatus: PromiseStatuses
    getCustomersByCompanyNameStatus: PromiseStatuses
    GetRappLegaleByIdResponseStatus: PromiseStatuses
    GetAllRappLegaleStatus: PromiseStatuses
    getCustomersResponseStatus: PromiseStatuses
    customerId?: string
    userId?: string
    selectedCustomerId: string
    selectedDocCustomerId: string
    selectedUserId: string
    selectedRappLegale?: GetRapplegaleByIdDTO | null
    getCustomersByIdResponse?: getCustomersByIdResponseDTO
    getCustomersByCodeResponse?: GetCustomerByCodeDTO[]
    getCustomersByCompanyNameResponse?: GetCustomerByCompanyNameResponseDTO[]
    companyCenterEditCreateRequest: corporateCenterDTO;
    selectedIdCorporateCenter: string
    activitiesRequest: string[];
    prodCategoriesRequest: string[];
    residuoZeroType: string;
    percResiduoContr?: number;
    residuoZeroReqAgg: string;
    residuoZeroTypology: string;
    idsCorporateCentersResponse?: string[]
    idsLegalRap?: string[]
    editLegalRaps: GetRapplegaleByIdDTO[]
    globalGapStandard: string;
    globalGapPropParallele?: string;

    // IFA
    globalGapEdizione?: string;
    globalGapApplicationField?: string;
    globalGapPlantCategory?: string;
    globalGapOpzioneCertIFA?: string;
    globalGapManipolazioneIFA?: string;

    // CoC
    globalGapOpzioneCertCoC?: string;
    globalGapEtichettaturaCoC?: string;
    globalGapLicenzaGNNCoC?: string;
    globalGapTrasformazioneParzialeCoC?: string;

    // add-on
    addOnRequest: ggAddonsDTO;
    getAddOnByIdStatus: PromiseStatuses;
    getAddOnByIdResponse?: GetAddOnByIdResponseDTO;

    getAddOnsStatus: PromiseStatuses;
    getAddOnsResponse?: GetAddOnsResponseDTO;

    postAddOnStatus: PromiseStatuses;
    postAddOnRequest: PostUpdateAddOnRequestDTO;
    postAddOnResponse?: string;

    updateAddOnStatus: PromiseStatuses;
    updateAddOnRequest: PostUpdateAddOnRequestDTO;

    deleteAddOnStatus: PromiseStatuses;
    indexToDelete?: number;

    //validate
    errorMessages: string[]
    createCustomerErrors: {
        companyName: boolean
        legalForm: boolean
        vat: boolean
        cuaa: boolean
        address: boolean
        countryRegion: boolean
        province: boolean
        city: boolean
        cap: boolean
        headQuarters: boolean
        norm: boolean
        startingDate: boolean
        clientType: boolean
        errorLabel: string
    },
    validateNewCustomerStatus: PromiseStatuses
    //validate Rapp Legale
    createLegalRappErrors: {
        fiscalCode: boolean
        sex: boolean
        birthdate: boolean
        province: boolean
        countryRegion: boolean
        city: boolean
        contactAddress: boolean
        contactCountryRegion: boolean
        contactProvince: boolean
        contactCity: boolean
        errorLabel: string
    },
    validateNewLegalRappStatus: PromiseStatuses
    //filters
    filters: FindAllCustomersFilters

    //status
    editCorporateCenterStatus: PromiseStatuses

    // global flags
    onEditCustomer: boolean;
    onSettingClientTypology: boolean;
    onEditRappLegalePopup: boolean;
}

const initialState: ClientiState = {
    selectedTab: 0,
    errorMessages: [],
    activitiesRequest: [""],
    prodCategoriesRequest: [""],
    residuoZeroType: '',
    residuoZeroReqAgg: '',
    residuoZeroTypology: '',
    globalGapStandard: '',
    customerCreateResponseStatus: 'idle',
    GetCorporateCenterByIdEditStatus: 'idle',
    rappLegaleCreateResponseStatus: 'idle',
    contactsCreateResponseStatus: 'idle',
    corporateCenterCreateResponseStatus: 'idle',
    getCustomersResponseStatus: 'idle',
    getCustomersByIdResponseStatus: 'idle',
    getCustomersByCodeResponseStatus: 'idle',
    getCustomersByCompanyNameStatus: 'idle',
    GetRappLegaleByIdResponseStatus: 'idle',
    GetAllRappLegaleStatus: 'idle',
    rappLegaleUpdateStatus: 'idle',
    rappLegaleDeleteStatus: 'idle',
    rappLegaleDeactivateStatus: 'idle',
    GetContactsByIdResponseStatus: 'idle',
    GetCorporateCenterByIdStatus: 'idle',
    selectedIdCorporateCenter: '',
    validateNewCustomerStatus: 'idle',
    validateNewLegalRappStatus: 'idle',
    customerEditStatus: 'idle',
    editCorporateCenterStatus: 'idle',
    selectedCustomerId: '',
    selectedDocCustomerId: '',
    selectedUserId: '',
    corporateCenters: [],
    listCorporateCentersEdit: [],
    corporateCentersUpdate: [],
    editLegalRaps: [],
    //filters
    filters: {
        headquarter: undefined,
        active: undefined,
        order: true,
        itemsPerPage: 30,
        page: 0
    },
    NewCustomerAnagraficaRequest: {
        companyName: '',
        legalForm: '',
        vat: '',
        cuaa: '',
        address: '',
        countryRegion: '',
        province: '',
        city: '',
        cap: ''
    },
    clienteEditCreateRequest: {
        companyName: '',
        legalForm: '',
        cuaa: '',
        vat: '',
        address: '',
        countryRegion: '',
        province: '',
        city: '',
        zipCode: '',
        rpAddress: '',
        codeType: '',
        rpCountryRegion: '',
        rpProvince: '',
        rpCity: '',
        rpZipCode: '',
        adresseeCode: '',
        accountingCode: '',
        bankDetails: '',
        entryReason: '',
        norm: '',
        headQuarters: [],
        biologicalActivity: [],
        clientType: "",
        sqnpiAdhesionType: [],
        sqProductionChain: [],
        ggAddOns: []
    },
    rappLegaleEditCreateRequest: {
        fiscalCode: '',
        surname: '',
        name: '',
        sex: '',
        birthdate: new Date(),
        state: 'IT',
        countryRegion: '',
        province: '',
        city: '',
        contactAddress: '',
        contactCountryRegion: '',
        contactProvince: '',
        contactCity: '',
        contactZipCode: '',
        phone: '',
        mobilePhone: '',
        email: '',
        fax: '',
        note: '',
        activationDate: new Date(),
        userId: ''
    },
    contactsCreateRequest: {
        phone: '',
        officePhone: '',
        mobilePhone: '',
        fax: '',
        email: '',
        personalEmail: '',
        pec: '',
        otherEmail: '',
        note: '',
        userId: ''
    },
    companyCenterEditCreateRequest: {
        id: '',
        countryRegion: '',
        province: '',
        city: '',
        address: '',
        activationDate: new Date(),
        userId: ''
    },

    //validate Customer
    createCustomerErrors: {
        companyName: false,
        legalForm: false,
        vat: false,
        cuaa: false,
        address: false,
        countryRegion: false,
        province: false,
        city: false,
        cap: false,
        headQuarters: false,
        norm: false,
        startingDate: false,
        clientType: false,
        errorLabel: ''
    },
    createLegalRappErrors: {
        fiscalCode: false,
        sex: false,
        birthdate: false,
        province: false,
        countryRegion: false,
        city: false,
        contactAddress: false,
        contactCountryRegion: false,
        contactProvince: false,
        contactCity: false,
        errorLabel: ''
    },

    // ADD-ON
    addOnRequest: {
        addModule: '',
        starting: new Date(),
        addOn: ''
    },

    getAddOnByIdStatus: 'idle',
    getAddOnsStatus: 'idle',
    postAddOnStatus: 'idle',
    updateAddOnStatus: 'idle',
    deleteAddOnStatus: 'idle',

    postAddOnRequest: {
        addModule: '',
        starting: '',
        active: true,
        customerDataId: '',
        addOn: ''
    },

    updateAddOnRequest: {
        addModule: '',
        starting: '',
        active: true,
        customerDataId: '',
        addOn: ''
    },

    // global flags
    onEditCustomer: false,
    onSettingClientTypology: false,
    onEditRappLegalePopup: false
}

export const validateNewCustomer = createAsyncThunk(
    'clienti/validateNewCustomerAnagrafica',
    async (customer: ClienteEditCreationDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(setValidateNewCompanyName(false))
        thunkApi.dispatch(setValidateNewLegalForm(false))
        thunkApi.dispatch(setValidateNewVat(false))
        thunkApi.dispatch(setValidateNewCuaa(false))
        thunkApi.dispatch(setValidateNewAddress(false))
        thunkApi.dispatch(setValidateNewNorm(false))
        thunkApi.dispatch(setValidateNewActivationDate(false))
        thunkApi.dispatch(setValidateHeadQuarters(false))
        thunkApi.dispatch(setValidateNewClientType(false))

        thunkApi.dispatch(setValidateNewCustomerStatus('idle'))
        thunkApi.dispatch(setCleanErrorMessages())
        if (!customer.companyName || customer.companyName === '') {
            thunkApi.dispatch(setAddMessageError('Ragione Sociale Anagrafica*'))
            thunkApi.dispatch(setValidateNewCompanyName(true))
            isValid = false
        }

        if (!customer.legalForm || customer.legalForm === '') {
            thunkApi.dispatch(setAddMessageError('Forma Giuridica Anagrafica*'))
            thunkApi.dispatch(setValidateNewLegalForm(true))
            isValid = false
        }

        if (!customer.vat || customer.vat === '') {
            thunkApi.dispatch(setAddMessageError('Partita Iva Anagrafica*'))
            thunkApi.dispatch(setValidateNewVat(true))
            isValid = false
        }

        if (!customer.cuaa || customer.cuaa === '') {
            thunkApi.dispatch(setAddMessageError('Cuaa Anagrafica*'))
            thunkApi.dispatch(setValidateNewCuaa(true))
            isValid = false
        }

        if (!customer.address || customer.address === '') {
            thunkApi.dispatch(setAddMessageError('Indirrizzo sede legale Anagrafica*'))
            thunkApi.dispatch(setValidateNewAddress(true))
            isValid = false
        }

        if (customer.headQuarters.length === 0) {
            thunkApi.dispatch(setAddMessageError('Sede di competenza*'))
            thunkApi.dispatch(setValidateHeadQuarters(true))
            isValid = false
        }

        if (!customer.norm || customer.norm === '') {
            thunkApi.dispatch(setAddMessageError('Seleziona una norma*'))
            thunkApi.dispatch(setValidateNewNorm(true))
            isValid = false
        }

        if (!customer.startingDate) {
            thunkApi.dispatch(setAddMessageError('Seleziona data inizio*'))
            thunkApi.dispatch(setValidateNewActivationDate(true))
            isValid = false
        }

        if (!customer.startingDate) {
            thunkApi.dispatch(setAddMessageError('Seleziona Tipologia Cliente*'))
            thunkApi.dispatch(setValidateNewClientType(true))
            isValid = false
        }


        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
    }
)

//validate Legal Rapp
export const validateNewLegalRapp = createAsyncThunk(
    'clienti/validateNewLegalRapp',
    async (legalRapp: RappLegaleEditCreationDTO, thunkApi): Promise<void> => {
        let isValid = true

        thunkApi.dispatch(setValidateNewLegalRappFiscalCode(false))
        thunkApi.dispatch(setValidateNewLegalRappSex(false))
        thunkApi.dispatch(setValidateNewLegalRappBirthDate(false))
        thunkApi.dispatch(setValidateNewLegalRappProvince(false))
        thunkApi.dispatch(setValidateNewLegalRappCountryRegion(false))
        thunkApi.dispatch(setValidateNewLegalRappCity(false))
        thunkApi.dispatch(setValidateNewLegalRappContactAddress(false))
        thunkApi.dispatch(setValidateNewLegalRappContactCountryRegion(false))
        thunkApi.dispatch(setValidateNewLegalRappContactCity(false))
        thunkApi.dispatch(setValidateNewLegalRappContactProvince(false))
        thunkApi.dispatch(setValidateNewLegalRappStatus('idle'))

        if (!legalRapp.fiscalCode || legalRapp.fiscalCode === '') {
            thunkApi.dispatch(setValidateNewLegalRappFiscalCode(true))
            thunkApi.dispatch(setAddMessageError('Codice Fiscale Rappresentante Legale*'))
            isValid = false
        }

        if (!legalRapp.sex || legalRapp.sex === '') {
            thunkApi.dispatch(setValidateNewLegalRappSex(true))
            thunkApi.dispatch(setAddMessageError('Sesso Rappresentante Legale*'))
            isValid = false
        }

        if (!legalRapp.birthdate) {
            thunkApi.dispatch(setValidateNewLegalRappBirthDate(true))
            thunkApi.dispatch(setAddMessageError('Data di nascita*'))
            isValid = false
        }

        if (!legalRapp.province || legalRapp.province === '') {
            thunkApi.dispatch(setValidateNewLegalRappProvince(true))
            thunkApi.dispatch(setAddMessageError('Provincia di nascita Rappresentante legale*'))
            isValid = false
        }

        if (!legalRapp.countryRegion || legalRapp.countryRegion === '') {
            thunkApi.dispatch(setValidateNewLegalRappCountryRegion(true))
            thunkApi.dispatch(setAddMessageError('Regione di nascita Rappresentante legale*'))
            isValid = false
        }

        if (!legalRapp.city || legalRapp.city === '') {
            thunkApi.dispatch(setValidateNewLegalRappCity(true))
            thunkApi.dispatch(setAddMessageError('Città di nascita Rappresentante legale*'))
            isValid = false
        }

        if (!legalRapp.contactAddress || legalRapp.contactAddress === '') {
            thunkApi.dispatch(setValidateNewLegalRappContactAddress(true))
            thunkApi.dispatch(setAddMessageError('Indirizzo Rappresentante legale*'))
            isValid = false
        }
        if (!legalRapp.contactCountryRegion || legalRapp.contactCountryRegion === '') {
            thunkApi.dispatch(setValidateNewLegalRappContactCountryRegion(true))
            isValid = false
        }
        if (!legalRapp.contactProvince || legalRapp.contactProvince === '') {
            thunkApi.dispatch(setValidateNewLegalRappContactCity(true))
            isValid = false
        }
        if (!legalRapp.contactCity || legalRapp.contactCity === '') {
            thunkApi.dispatch(setValidateNewLegalRappContactProvince(true))
            isValid = false
        }
        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
    }
)

export const CustomerCreation = createAsyncThunk(
    'clienti/CustomerCreation',
    async (request: ClienteEditCreationDTO, thunkApi): Promise<number> => {
        const clientiService = NewClientiService()

        return clientiService.CustomerCreation(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const EditCustomerRequest = createAsyncThunk(
    'clienti/EditCustomerRequest',
    async (request: { id: string, data: ClienteEditCreationDTO }, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()

        return clientiService.EditCustomerRequest(request.id, request.data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetCustomers = createAsyncThunk(
    'clienti/GetCustomers',
    async (filters: FindAllCustomersFilters, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()

        return clientiService.GetCustomers(filters)
    },
)

export const GetCustomersById = createAsyncThunk(
    'clienti/GetCustomersById',
    async (id: string, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()
        return clientiService.GetCustomersById(id)
    },
)

export const GetCustomersByCode = createAsyncThunk(
    'clienti/GetCustomersByCode',
    async (code: string, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()
        return clientiService.GetCustomersByCode(code)
    },
)

export const GetCustomersByCompanyName = createAsyncThunk(
    'clienti/GetCustomersByName',
    async (code: string, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()
        return clientiService.GetCustomerByCompanyName(code)
    },
)

export const RappLegaleCreation = createAsyncThunk(
    'clienti/createRappLegale',
    async (request: RappLegaleEditCreationDTO, thunkApi): Promise<void> => {
        const clientiService = NewClientiService()

        return clientiService.createRappLegale(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const EditCustomerContacts = createAsyncThunk(
    'clienti/EditCustomerContacts',
    async (request: { id: string, data: contactsEditCreateRequestDTO }, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()

        return clientiService.EditCustomerContacts(request.id, request.data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)


export const EditLegalRapRequest = createAsyncThunk(
    'clienti/EditLegalRapRequest',
    async (request: { id: string, data: RappLegaleEditCreationDTO }, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()

        return clientiService.EditLegalRapRequest(request.id, request.data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetAllRappLegale = createAsyncThunk(
    'clienti/GetAllRappLegale',
    async (thunkApi): Promise<GetAllRappLegaleResponseDTO> => {
        const clientiService = NewClientiService()

        return clientiService.GetRappLegales()
    }
)

export const GetRappLegaleById = createAsyncThunk(
    'clienti/GetRappLegaleById',
    async (id: string, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()

        return clientiService.GetRappLegaleById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const UpdateRappLegale = createAsyncThunk(
    'clienti/UpdateRappLegale',
    async (request: { id: string, data: RappLegaleEditCreationDTO }, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()

        return clientiService.UpdateRappLegaleById(request.id, request.data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const DeleteRappLegale = createAsyncThunk(
    'clienti/deleteRappLegale',
    async (id: string, thunkApi): Promise<void> => {
        const clientiService = NewClientiService();

        return clientiService.DeleteRappLegaleById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const DeactivateRappLegale = createAsyncThunk(
    'clienti/deactivateRappLegale',
    async (id: string, thunkApi): Promise<void> => {
        const clientiService = NewClientiService();

        return clientiService.DeactivateRappLegaleById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const ContactsCreation = createAsyncThunk(
    'clienti/createContacts',
    async (request: contactsEditCreateRequestDTO, thunkApi): Promise<void> => {
        const clientiService = NewClientiService()

        return clientiService.createContacts(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetContactsById = createAsyncThunk(
    'clienti/GetContactsById',
    async (id: string, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()
        return clientiService.GetContactsById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetCorporateCenterById = createAsyncThunk(
    'clienti/GetCorporateCenterById',
    async (id: string, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()
        return clientiService.GetCorporateCenterById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetCorporateCenterByIdEdit = createAsyncThunk(
    'clienti/GetCorporateCenterByIdEdit',
    async (id: string, thunkApi): Promise<any> => {
        const clientiService = NewClientiService()
        return clientiService.GetCorporateCenterById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const CenterCompanyCreation = createAsyncThunk(
    'clienti/createCenterCompany',
    async (request: corporateCenterDTO, thunkApi): Promise<void> => {
        const clientiService = NewClientiService()

        return clientiService.createCompanyCenter(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const EditCompanyCreation = createAsyncThunk(
    'clienti/editCompanyCenter',
    async (request: { id: string, data: corporateCenterDTO }, thunkApi): Promise<void> => {
        const clientiService = NewClientiService()

        return clientiService.editCompanyCenter(request.id, request.data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetAddOnById = createAsyncThunk(
    'clienti/getAddOnById',
    async (id: string, thunkApi): Promise<GetAddOnByIdResponseDTO> => {
        const clientiService = NewClientiService();

        return clientiService.getAddOnById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const GetAddOns = createAsyncThunk(
    'clienti/getAddons',
    async (thunkApi): Promise<GetAddOnsResponseDTO> => {
        const clientiService = NewClientiService();

        return clientiService.getAddOns()
    }
)

export const PostAddOn = createAsyncThunk(
    'clienti/postAddOn',
    async (data: PostUpdateAddOnRequestDTO, thunkApi): Promise<string> => {
        const clientiService = NewClientiService();

        return clientiService.postAddOn(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const UpdateAddOn = createAsyncThunk(
    'clienti/updateAddOn',
    async (request: { id: string, data: PostUpdateAddOnRequestDTO }, thunkApi): Promise<void> => {
        const clientiService = NewClientiService();

        return clientiService.updateAddOn(request.id, request.data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const DeleteAddOn = createAsyncThunk(
    'clienti/deleteAddOn',
    async (id: string, thunkApi): Promise<void> => {
        const clientiService = NewClientiService();

        return clientiService.deleteAddOn(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

const ClientiSlice = createSlice({
    name: 'clienti/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setRagSocialeAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.companyName = action.payload
        },
        setCuaaAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.cuaa = action.payload
        },
        setPIvaAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.vat = action.payload
        },
        setFormaGiuridicaAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.legalForm = action.payload
        },
        setAddressAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.address = action.payload
        },
        setRegioneAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.countryRegion = action.payload
        },
        setProvinciaAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.province = action.payload
        },
        setComuneAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.city = action.payload
        },
        setCapAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.zipCode = action.payload
        },
        setRpAddressAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.rpAddress = action.payload
        },
        setRpRegioneAnagrafica: (state, action) => {

            state.clienteEditCreateRequest.rpCountryRegion = action.payload
        },
        setRpProvinciaAnagrafica: (state, action) => {

            state.clienteEditCreateRequest.rpProvince = action.payload
        },
        setRpComuneAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.rpCity = action.payload
        },
        setRpCapAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.rpZipCode = action.payload
        },
        setCodDestinatarioAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.accountingCode = action.payload
        },
        setCodContabileAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.adresseeCode = action.payload
        },
        setCoordBancarieAnagrafica: (state, action) => {
            state.clienteEditCreateRequest.bankDetails = action.payload
        },
        setStartingDate: (state, action) => {
            state.clienteEditCreateRequest.startingDate = action.payload
            state.rappLegaleEditCreateRequest.activationDate = action.payload
            state.companyCenterEditCreateRequest.activationDate = action.payload
        },
        setEndingDate: (state, action) => {
            state.clienteEditCreateRequest.endingDate = action.payload
        },
        setEntryReason: (state, action) => {
            state.clienteEditCreateRequest.entryReason = action.payload
        },
        setExitReason: (state, action) => {
            state.clienteEditCreateRequest.exitReason = action.payload
        },

        setClientType: (state, action) => {
            state.clienteEditCreateRequest.clientType = action.payload
        },

        setCodeType: (state, action) => {
            state.clienteEditCreateRequest.codeType = action.payload
        },

        setExistingCode: (state, action) => {
            state.clienteEditCreateRequest.existingCode = action.payload
        },

        setOperatorGroup: (state, action) => {
            state.clienteEditCreateRequest.operatorsGroup = action.payload
        },
        setLeaderId: (state, action) => {
            state.clienteEditCreateRequest.leaderId = action.payload
        },
        addEditLegalRapp: (state, action) => {
            state.editLegalRaps.push(action.payload)
        },
        setEditLegalRapp: (state, action) => {
            state.editLegalRaps = action.payload
        },
        setSelectedLegalRapp: (state, action) => {
            state.rappLegaleEditCreateRequest = action.payload
            state.selectedRappLegale = action.payload
        },
        //validate messages
        setAddMessageError: (state, action) => {
            state.errorMessages.push(action.payload)
        },

        setCleanErrorMessages: (state) => {
            state.errorMessages = []
        },
        //validate Anagrafica
        setValidateNewCompanyName: (state, action) => {
            state.createCustomerErrors.companyName = action.payload
        },
        setValidateNewVat: (state, action) => {
            state.createCustomerErrors.vat = action.payload
        },
        setValidateNewLegalForm: (state, action) => {
            state.createCustomerErrors.legalForm = action.payload
        },
        setValidateNewCuaa: (state, action) => {
            state.createCustomerErrors.cuaa = action.payload
        },
        setValidateNewAddress: (state, action) => {
            state.createCustomerErrors.address = action.payload
        },
        setValidateHeadQuarters: (state, action) => {
            state.createCustomerErrors.headQuarters = action.payload
        },
        setValidateNewNorm: (state, action) => {
            state.createCustomerErrors.norm = action.payload
        },
        setValidateNewActivationDate: (state, action) => {
            state.createCustomerErrors.startingDate = action.payload
        },
        setValidateNewClientType: (state, action) => {
            state.createCustomerErrors.clientType = action.payload
        },
        setValidateNewCustomerStatus: (state, action) => {
            state.validateNewCustomerStatus = action.payload
        },

        //rapp legale
        setFiscalCode: (state, action) => {
            state.rappLegaleEditCreateRequest.fiscalCode = action.payload
        },
        setSurname: (state, action) => {
            state.rappLegaleEditCreateRequest.surname = action.payload
        },
        setName: (state, action) => {
            state.rappLegaleEditCreateRequest.name = action.payload
        },
        setSex: (state, action) => {
            state.rappLegaleEditCreateRequest.sex = action.payload
        },
        setAddress: (state, action) => {
            state.rappLegaleEditCreateRequest.contactAddress = action.payload
        },
        setBirthDate: (state, action) => {
            state.rappLegaleEditCreateRequest.birthdate = action.payload
        },
        setMobilePhone: (state, action) => {
            state.rappLegaleEditCreateRequest.mobilePhone = action.payload
        },
        setCountryRegion: (state, action) => {
            state.rappLegaleEditCreateRequest.countryRegion = action.payload
        },
        setProvince: (state, action) => {
            state.rappLegaleEditCreateRequest.province = action.payload
        },
        setCity: (state, action) => {
            state.rappLegaleEditCreateRequest.city = action.payload
        },
        setContactAddress: (state, action) => {
            state.rappLegaleEditCreateRequest.contactAddress = action.payload
        },
        setContactCountryRegion: (state, action) => {
            state.rappLegaleEditCreateRequest.contactCountryRegion = action.payload
        },
        setContactProvince: (state, action) => {
            state.rappLegaleEditCreateRequest.contactProvince = action.payload
        },
        setContactCity: (state, action) => {
            state.rappLegaleEditCreateRequest.contactCity = action.payload
        },
        setContactZipCode: (state, action) => {
            state.rappLegaleEditCreateRequest.contactZipCode = action.payload
        },
        setPhone: (state, action) => {
            state.rappLegaleEditCreateRequest.phone = action.payload
        },
        setRappLegaleMobilePhone: (state, action) => {
            state.rappLegaleEditCreateRequest.mobilePhone = action.payload
        },
        setEmail: (state, action) => {
            state.rappLegaleEditCreateRequest.email = action.payload
        },
        setFax: (state, action) => {
            state.rappLegaleEditCreateRequest.fax = action.payload
        },
        setActivationDate: (state, action) => {
            state.rappLegaleEditCreateRequest.activationDate = action.payload
        },
        setDeActivationDate: (state, action) => {
            state.rappLegaleEditCreateRequest.deactivationDate = action.payload
        },
        setNote: (state, action) => {
            state.rappLegaleEditCreateRequest.note = action.payload
        },
        setUserId: (state, action) => {
            state.rappLegaleEditCreateRequest.userId = action.payload.toString()
            state.contactsCreateRequest.userId = action.payload.toString()
            state.companyCenterEditCreateRequest.userId = action.payload.toString()
        },
        setIdsLegalRapp: (state, action) => {
            state.idsLegalRap = action.payload

        },
        //validate Rapp Legale
        setValidateNewLegalRappFiscalCode: (state, action) => {
            state.createLegalRappErrors.fiscalCode = action.payload
        },
        setValidateNewLegalRappSex: (state, action) => {
            state.createLegalRappErrors.sex = action.payload
        },
        setValidateNewLegalRappBirthDate: (state, action) => {
            state.createLegalRappErrors.birthdate = action.payload
        },
        setValidateNewLegalRappProvince: (state, action) => {
            state.createLegalRappErrors.province = action.payload
        },
        setValidateNewLegalRappCountryRegion: (state, action) => {
            state.createLegalRappErrors.countryRegion = action.payload
        },
        setValidateNewLegalRappCity: (state, action) => {
            state.createLegalRappErrors.city = action.payload
        },
        setValidateNewLegalRappContactAddress: (state, action) => {
            state.createLegalRappErrors.contactAddress = action.payload
        },
        setValidateNewLegalRappContactCountryRegion: (state, action) => {
            state.createLegalRappErrors.contactCountryRegion = action.payload
        },
        setValidateNewLegalRappContactCity: (state, action) => {
            state.createLegalRappErrors.contactCity = action.payload
        },
        setValidateNewLegalRappContactProvince: (state, action) => {
            state.createLegalRappErrors.contactProvince = action.payload
        },
        setValidateNewLegalRappStatus: (state, action) => {
            state.validateNewLegalRappStatus = action.payload
        },

        //rubrica
        setContactPhone: (state, action) => {
            state.contactsCreateRequest.phone = action.payload
        },
        setContactOfficePhone: (state, action) => {
            state.contactsCreateRequest.officePhone = action.payload
        },
        setContactMobilePhone: (state, action) => {
            state.contactsCreateRequest.mobilePhone = action.payload
        },
        setContactFax: (state, action) => {
            state.contactsCreateRequest.fax = action.payload
        },
        setContactEmail: (state, action) => {
            state.contactsCreateRequest.email = action.payload
        },
        setContactPersonalEmail: (state, action) => {
            state.contactsCreateRequest.personalEmail = action.payload
        },
        setContactPec: (state, action) => {
            state.contactsCreateRequest.pec = action.payload
        },
        setContactOtherEmail: (state, action) => {
            state.contactsCreateRequest.otherEmail = action.payload
        },
        setContactNote: (state, action) => {
            state.contactsCreateRequest.note = action.payload
        },
        //centro aziendale
        setSelectedIDCorporateCenter: (state, action) => {
            state.selectedIdCorporateCenter = action.payload
        },

        setCodeCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.code = action.payload
        },
        setRegioneCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.countryRegion = action.payload
        },
        setProvinciaCorporateCenter: (state, action) => {

            state.companyCenterEditCreateRequest.province = action.payload
        },
        setComuneCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.city = action.payload
        },
        setCapCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.zipCode = action.payload
        },
        setAddressCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.address = action.payload
        },
        setReferenteCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.referrer = action.payload
        },
        setRecapitoCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.phone = action.payload
        },
        setEmailCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.email = action.payload
        },
        setPecCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.pec = action.payload
        },
        setLatCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.latitude = action.payload
        },
        setLongCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.longitude = action.payload
        },
        setActivationDateCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.activationDate = action.payload
        },
        setDeactivationDateCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.deactivationDate = action.payload
        },
        setSelectedCustomerId: (state, action) => {
            state.selectedCustomerId = action.payload
        },
        setSelectedDocCustomerId: (state, action) => {
            state.selectedDocCustomerId = action.payload
        },
        selectedUserId: (state, action) => {
            state.selectedUserId = action.payload
        },
        setDescriptionCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.description = action.payload
        },
        setIdCorporateCenter: (state, action) => {
            state.companyCenterEditCreateRequest.id = action.payload
        },
        addCorporateCenter: (state) => {
            state.corporateCenters.push(state.companyCenterEditCreateRequest)
        },

        listCorporateCenter: (state, action) => {
            state.listCorporateCentersEdit.push(action.payload)
        },

        setIdsCorporateCenterResponse: (state, action) => {
            state.idsCorporateCentersResponse = action.payload

        },

        resetIdsCorporateCenterResponse: (state) => {
            state.listCorporateCentersEdit = []
        },

        pushCorporateCenter: (state, action) => {
            state.corporateCentersUpdate.push(action.payload)
        },
        resetCustomerByIdStatus: (state, action) => {
            state.getCustomersByIdResponseStatus = action.payload
        },
        resetGetCustomersByCompanyName: (state) => {
            state.getCustomersByCompanyNameStatus = 'idle'
        },
        resetCustomerEditStatus: (state) => {
            state.customerEditStatus = 'idle'
        },

        resetCreateStatus: (state, action) => {
            state.customerCreateResponseStatus = action.payload
        },
        resetCreateRappLegaleStatus: (state, action) => {
            state.rappLegaleCreateResponseStatus = action.payload
        },
        resetGetRappLegaleByIdStatus: (state) => {
            state.GetRappLegaleByIdResponseStatus = 'idle'
        },
        resetCreateEditRapprLegale: (state) => {
            state.rappLegaleEditCreateRequest = {
                fiscalCode: '',
                surname: '',
                name: '',
                sex: '',
                birthdate: new Date(),
                state: 'IT',
                countryRegion: '',
                province: '',
                city: '',
                contactAddress: '',
                contactCountryRegion: '',
                contactProvince: '',
                contactCity: '',
                contactZipCode: '',
                phone: '',
                mobilePhone: '',
                email: '',
                fax: '',
                note: '',
                activationDate: new Date(),
                userId: ''
            }
        },
        resetGetAllRappLegaleStatus: (state) => {
            state.GetAllRappLegaleStatus = 'idle'
        },
        resetContactsStatus: (state, action) => {
            state.contactsCreateResponseStatus = action.payload
        },
        resetCreateCompanyCenterStatus: (state, action) => {
            state.corporateCenterCreateResponseStatus = action.payload
        },
        resetCreateCompanyCenterByIdStatus: (state, action) => {
            state.GetCorporateCenterByIdStatus = action.payload
        },
        resetCreateCompanyCenterByIdEditStatus: (state, action) => {
            state.GetCorporateCenterByIdEditStatus = action.payload
        },

        resetEditCompanyCenterStatus: (state, action) => {
            state.editCorporateCenterStatus = action.payload
        },

        removeCorporateCenter: (state, action) => {
            state.corporateCenters.splice(action.payload, 1)
        },

        resetUpdateRappLegaleStatus: (state) => {
            state.rappLegaleUpdateStatus = 'idle'
        },
        resetDeleteRappLegaleStatus: (state) => {
            state.rappLegaleDeleteStatus = 'idle'
        },
        resetDeactivateRappLegaleStatus: (state) => {
            state.rappLegaleDeactivateStatus = 'idle'
        },
        //RightBar
        setNormCustomer: (state, action) => {
            state.clienteEditCreateRequest.norm = action.payload
        },
        resetGetCustomersByCodeStatus: (state, action) => {
            state.getCustomersByCodeResponseStatus = action.payload
        },
        setEntryOdc: (state, action) => {
            state.clienteEditCreateRequest.entryOdc = action.payload
        },
        setExitOdc: (state, action) => {
            state.clienteEditCreateRequest.exitOdc = action.payload
        },
        setTakeover: (state, action) => {
            state.clienteEditCreateRequest.takeoverDetails = action.payload
        },

        // Biological Activities
        setBiologicalActivity: (state, action) => {
            state.clienteEditCreateRequest.biologicalActivity = action.payload
        },
        setBiologicalActivityActivityByIndex: (state, action) => {
            state.clienteEditCreateRequest.biologicalActivity[action.payload.index].activity = action.payload.data
        },
        setBiologicalActivityCategoryByIndex: (state, action) => {
            state.clienteEditCreateRequest.biologicalActivity[action.payload.index].category = action.payload.data
        },
        setBiologicalActivityProductsByIndex: (state, action) => {
            state.clienteEditCreateRequest.biologicalActivity[action.payload.index].products = action.payload.data
        },
        addDefaultBiologicalActivity: (state) => {
            state.clienteEditCreateRequest.biologicalActivity.push({
                activity: '',
                category: [],
                products: []
            } as BiologicalActivityDTO)
        },

        //prod integrata
        setProductSqnpiSubjectType: (state, action) => {
            state.clienteEditCreateRequest.sqnpiSubjectType = action.payload
        },
        setProductSqnpiAdhesionType: (state, action) => {
            state.clienteEditCreateRequest.sqnpiAdhesionType = action.payload
        },

        setAuthorityOffices: (state, action) => {
            state.clienteEditCreateRequest.headQuarters = action.payload
        },
        //residuo zero
        setResiduoZeroType: (state, action) => {
            state.clienteEditCreateRequest.zrCertificationType = action.payload
        },
        setPercResiduoContr: (state, action) => {
            state.clienteEditCreateRequest.zrTargetThreshold = action.payload
        },
        setResiduoZeroReqAgg: (state, action) => {
            state.clienteEditCreateRequest.zrAddingRequirements = action.payload
        },
        setResiduoZeroTypology: (state, action) => {
            state.clienteEditCreateRequest.zrType = action.payload
        },

        // qualità sicura
        setSqProductionChain: (state, action) => {
            state.clienteEditCreateRequest.sqProductionChain = action.payload
        },

        //GLobalGAP
        setGlobalGapStandard: (state, action) => {
            state.clienteEditCreateRequest.ggStandard = action.payload
        },

        setGgn: (state, action) => {
            state.clienteEditCreateRequest.ggGgn = action.payload
        },

        // GLobalGAP IFA

        setGlobalGapEdizione: (state, action) => {
            state.clienteEditCreateRequest.ggEdition = action.payload
        },
        setGlobalGapApplicationField: (state, action) => {
            state.clienteEditCreateRequest.ggApplicationField = action.payload
        },
        setGlobalGapPlantCategory: (state, action) => {
            state.clienteEditCreateRequest.ggCategory = action.payload
        },
        setGlobalGapOpzioneCertIFA: (state, action) => {
            state.clienteEditCreateRequest.ggIFACertificationOption = action.payload
        },
        setGlobalGapManipolazioneIFA: (state, action) => {
            state.clienteEditCreateRequest.ggManipulation = action.payload
        },
        setGlobalGapPropParallele: (state, action) => {
            state.clienteEditCreateRequest.ggParallelProperty = action.payload
        },

        // CoC
        setGlobalGapOpzioneCertCoC: (state, action) => {
            state.clienteEditCreateRequest.ggCOCCertificationOption = action.payload
        },
        setGlobalGapEtichettaturaCoC: (state, action) => {
            state.clienteEditCreateRequest.ggLabelling = action.payload
        },
        setGlobalGapLicenzaGNNCoC: (state, action) => {
            state.clienteEditCreateRequest.ggLicenseGnnLabelLogo = action.payload
        },
        setGlobalGapTrasformazioneParzialeCoC: (state, action) => {
            state.clienteEditCreateRequest.ggPartialTransformation = action.payload
        },
        setAddOnRequestModule: (state, action) => {
            state.addOnRequest.addModule = action.payload
        },
        setAddOnRequestStartDate: (state, action) => {
            state.addOnRequest.starting = action.payload
        },
        setAddOnRequestEndDate: (state, action) => {
            state.addOnRequest.ending = action.payload
        },
        setAddOnRequestNotes: (state, action) => {
            state.addOnRequest.addOn = action.payload
        },
        addAdOn: (state) => {
            state.clienteEditCreateRequest.ggAddOns.push(state.addOnRequest)
        },
        addCustomAddOn: (state, action) => {
            state.clienteEditCreateRequest.ggAddOns.push(action.payload)
        },
        resetAddOnRequest: (state) => {
            state.addOnRequest = {
                addModule: '',
                starting: new Date(),
                addOn: ''
            }
        },
        removeAddOn: (state, action) => {
            state.clienteEditCreateRequest.ggAddOns.splice(action.payload, 1)
        },
        setAddOns: (state, action) => {
            state.clienteEditCreateRequest.ggAddOns = action.payload
        },

        // GET ADD-ON
        resetGetAddOnByIdStatus: (state) => {
            state.getAddOnByIdStatus = 'idle'
        },
        resetGetAddOnsStatus: (state) => {
            state.getAddOnsStatus = 'idle'
        },

        // POST ADD-ON
        resetPostAddOnStatus: (state) => {
            state.postAddOnStatus = 'idle'
        },
        setPostAddOnRequestStarting: (state, action) => {
            state.postAddOnRequest.starting = action.payload
        },
        setPostAddOnRequestEnding: (state, action) => {
            state.postAddOnRequest.ending = action.payload
        },
        setPostAddOnRequestNotes: (state, action) => {
            state.postAddOnRequest.addOn = action.payload
        },
        setPostAddOnRequestModule: (state, action) => {
            state.postAddOnRequest.addModule = action.payload
        },
        setPostAddOnRequestActive: (state, action) => {
            state.postAddOnRequest.active = action.payload
        },
        setPostAddOnRequestCustomerDataId: (state, action) => {
            state.postAddOnRequest.customerDataId = action.payload
        },

        // UPDATE ADD-ON
        resetUpdateAddOnStatus: (state) => {
            state.updateAddOnStatus = 'idle'
        },
        setUpdateAddOnRequestStarting: (state, action) => {
            state.updateAddOnRequest.starting = action.payload
        },
        setUpdateAddOnRequestEnding: (state, action) => {
            state.updateAddOnRequest.ending = action.payload
        },
        setUpdateAddOnRequestNotes: (state, action) => {
            state.updateAddOnRequest.addOn = action.payload
        },
        setUpdateAddOnRequestModule: (state, action) => {
            state.updateAddOnRequest.addModule = action.payload
        },
        setUpdateAddOnRequestActive: (state, action) => {
            state.updateAddOnRequest.active = action.payload
        },
        setUpdateAddOnRequestCustomerDataId: (state, action) => {
            state.updateAddOnRequest.customerDataId = action.payload
        },

        // DELETE ADD-ON
        resetDeleteAddOnStatus: (state) => {
            state.deleteAddOnStatus = 'idle'
        },

        //filters
        setFindAllCustomersFiltersNorm: (state, action) => {
            state.filters.norm = action.payload
        },
        setFindAllCustomersFiltersCompanyName: (state, action) => {
            state.filters.companyName = action.payload
        },
        setFindAllCustomersFiltersSearch: (state, action) => {
            state.filters.search = action.payload
        },
        setFindAllCustomersFiltersPage: (state, action) => {
            state.filters.page = action.payload
        },
        setHeadQuarterFilter: (state, action) => {
            state.filters.headquarter = action.payload
        },
        setStatusFilter: (state, action) => {
            if (action.payload === 'active') {
                state.filters.active = true
            } else {
                state.filters.active = false
            }
        },
        resetFilters: (state) => {
            state.filters = {
                itemsPerPage: 10, 
                search: '',
                page: 0
            }
        },
        // global flags
        setOnEditCustomer: (state, action) => {
            state.onEditCustomer = action.payload
        },
        setOnSettingClientTypology: (state, action) => {
            state.onSettingClientTypology = action.payload
        },
        setOnEditRappLegalePopup: (state, action) => {
            state.onEditRappLegalePopup = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(CustomerCreation.pending, (state) => {
                state.customerCreateResponseStatus = 'loading'
            })
            .addCase(CustomerCreation.fulfilled, (state, action) => {
                state.customerId = action.payload.toString()
                state.customerCreateResponseStatus = 'successfully'
                // state.rappLegaleEditCreateRequest.userId = action.payload.toString()
                // state.contactsCreateRequest.userId = action.payload.toString()
                // state.corporateCenters.map(center => {
                //     center.userId = action.payload.toString()
                // }
                // )
            })
            .addCase(CustomerCreation.rejected, (state, action) => {
                state.customerCreateResponseStatus = 'failed'

            })

            // EDIT CUSTOMER
            // EditCustomerRequest
            .addCase(EditCustomerRequest.pending, (state) => {
                state.customerEditStatus = 'loading'
            })
            .addCase(EditCustomerRequest.fulfilled, (state, action) => {
                state.customerEditStatus = 'successfully'
            })
            .addCase(EditCustomerRequest.rejected, (state, action) => {
                state.customerEditStatus = 'failed'
            })

            .addCase(RappLegaleCreation.pending, (state) => {
                state.rappLegaleCreateResponseStatus = 'loading'
            })
            .addCase(RappLegaleCreation.fulfilled, (state, action) => {

                state.rappLegaleCreateResponseStatus = 'successfully'
            })
            .addCase(RappLegaleCreation.rejected, (state, action) => {
                state.rappLegaleCreateResponseStatus = 'failed'
            })

            .addCase(ContactsCreation.pending, (state) => {
                state.contactsCreateResponseStatus = 'loading'
            })
            .addCase(ContactsCreation.fulfilled, (state, action) => {

                state.contactsCreateResponseStatus = 'successfully'
            })
            .addCase(ContactsCreation.rejected, (state, action) => {
                state.contactsCreateResponseStatus = 'failed'
            })

            .addCase(CenterCompanyCreation.pending, (state) => {
                state.corporateCenterCreateResponseStatus = 'loading'
            })
            .addCase(CenterCompanyCreation.fulfilled, (state, action) => {

                state.corporateCenterCreateResponseStatus = 'successfully'
            })
            .addCase(CenterCompanyCreation.rejected, (state, action) => {
                state.corporateCenterCreateResponseStatus = 'failed'
            })

            .addCase(GetCustomers.pending, (state) => {
                state.getCustomersResponseStatus = 'loading'
            })
            .addCase(GetCustomers.fulfilled, (state, action) => {
                state.getCustomersResponseStatus = 'successfully'
                state.getCustomersResponse = action.payload
            })
            .addCase(GetCustomers.rejected, (state, action) => {
                state.getCustomersResponseStatus = 'failed'
            })

            .addCase(GetCustomersByCompanyName.pending, (state) => {
                state.getCustomersByCompanyNameStatus = 'loading'
            })
            .addCase(GetCustomersByCompanyName.fulfilled, (state, action) => {
                state.getCustomersByCompanyNameStatus = 'successfully'
                state.getCustomersByCompanyNameResponse = action.payload.data
            })
            .addCase(GetCustomersByCompanyName.rejected, (state, action) => {
                state.getCustomersByCompanyNameStatus = 'failed'
            })

            .addCase(GetCustomersById.pending, (state) => {
                state.getCustomersByIdResponseStatus = 'loading'
            })
            .addCase(GetCustomersById.fulfilled, (state, action) => {
                state.getCustomersByIdResponse = action.payload
                if (action.payload.userId) {
                    state.userId = action.payload.userId.toString()
                    state.rappLegaleEditCreateRequest.userId = action.payload.userId.toString()
                    state.contactsCreateRequest.userId = action.payload.userId.toString()
                    state.corporateCenters.map(center => {
                        center.userId = action.payload.userId.toString()
                    }
                    )
                }
                state.getCustomersByIdResponseStatus = 'successfully'

            })
            .addCase(GetCustomersById.rejected, (state, action) => {
                state.getCustomersByIdResponseStatus = 'failed'
            })

            //get customer by code

            .addCase(GetCustomersByCode.rejected, (state, action) => {
                state.getCustomersByCodeResponseStatus = 'failed'
            })

            .addCase(GetCustomersByCode.pending, (state) => {
                state.getCustomersByCodeResponseStatus = 'loading'
            })
            .addCase(GetCustomersByCode.fulfilled, (state, action) => {
                state.getCustomersByCodeResponseStatus = 'successfully'
                state.getCustomersByCodeResponse = action.payload.data

            })

            .addCase(GetRappLegaleById.pending, (state) => {
                state.GetRappLegaleByIdResponseStatus = 'loading'
            })
            .addCase(GetRappLegaleById.fulfilled, (state, action) => {
                state.GetRappLegaleByIdResponseStatus = 'successfully'
                state.GetRappLegaleByIdResponse = action.payload
            })
            .addCase(GetRappLegaleById.rejected, (state, action) => {
                state.GetRappLegaleByIdResponseStatus = 'failed'
            })

            .addCase(UpdateRappLegale.pending, (state) => {
                state.rappLegaleUpdateStatus = 'loading'
            })
            .addCase(UpdateRappLegale.fulfilled, (state, action) => {
                state.rappLegaleUpdateStatus = 'successfully'
            })
            .addCase(UpdateRappLegale.rejected, (state, action) => {
                state.rappLegaleUpdateStatus = 'failed'
            })

            .addCase(DeleteRappLegale.pending, (state) => {
                state.rappLegaleDeleteStatus = 'loading'
            })
            .addCase(DeleteRappLegale.fulfilled, (state, action) => {
                state.rappLegaleDeleteStatus = 'successfully'
            })
            .addCase(DeleteRappLegale.rejected, (state, action) => {
                state.rappLegaleDeleteStatus = 'failed'
            })

            .addCase(DeactivateRappLegale.pending, (state) => {
                state.rappLegaleDeactivateStatus = 'loading'
            })
            .addCase(DeactivateRappLegale.fulfilled, (state, action) => {
                state.rappLegaleDeactivateStatus = 'successfully'
            })
            .addCase(DeactivateRappLegale.rejected, (state, action) => {
                state.rappLegaleDeactivateStatus = 'failed'
            })

            .addCase(GetAllRappLegale.pending, (state) => {
                state.GetAllRappLegaleStatus = 'loading'
            })
            .addCase(GetAllRappLegale.fulfilled, (state, action) => {
                state.GetAllRappLegaleStatus = 'successfully'
                state.GetAllRappLegaleResponse = action.payload
            })
            .addCase(GetAllRappLegale.rejected, (state) => {
                state.GetAllRappLegaleStatus = 'failed'
            })

            .addCase(GetContactsById.pending, (state) => {
                state.GetContactsByIdResponseStatus = 'loading'
            })
            .addCase(GetContactsById.fulfilled, (state, action) => {
                state.GetContactsByIdResponseStatus = 'successfully'
                state.GetContactsByIdResponse = action.payload
            })
            .addCase(GetContactsById.rejected, (state, action) => {
                state.GetContactsByIdResponseStatus = 'failed'
            })

            .addCase(GetCorporateCenterById.pending, (state) => {
                state.GetCorporateCenterByIdStatus = 'loading'
            })
            .addCase(GetCorporateCenterById.fulfilled, (state, action) => {
                state.GetCorporateCenterByIdResponse = action.payload
                state.GetCorporateCenterByIdStatus = 'successfully'
            })
            .addCase(GetCorporateCenterById.rejected, (state, action) => {
                state.GetCorporateCenterByIdStatus = 'failed'
            })

            .addCase(GetCorporateCenterByIdEdit.pending, (state) => {
                state.GetCorporateCenterByIdEditStatus = 'loading'
            })
            .addCase(GetCorporateCenterByIdEdit.fulfilled, (state, action) => {
                state.GetCorporateCenterByIdEditResponse = action.payload
                state.GetCorporateCenterByIdEditStatus = 'successfully'
            })
            .addCase(GetCorporateCenterByIdEdit.rejected, (state, action) => {
                state.GetCorporateCenterByIdEditStatus = 'failed'
            })

            .addCase(EditCompanyCreation.pending, (state) => {
                state.editCorporateCenterStatus = 'loading'
            })
            .addCase(EditCompanyCreation.fulfilled, (state, action) => {
                state.editCorporateCenterStatus = 'successfully'
            })
            .addCase(EditCompanyCreation.rejected, (state, action) => {
                state.editCorporateCenterStatus = 'failed'
            })

            //validate anagrafica 

            .addCase(validateNewCustomer.pending, (state) => {
                state.validateNewCustomerStatus = 'loading'
            })
            .addCase(validateNewCustomer.fulfilled, (state) => {
                state.validateNewCustomerStatus = 'successfully'
            })
            .addCase(validateNewCustomer.rejected, (state) => {
                state.validateNewCustomerStatus = 'failed'
            })

            //validate legal rapp 
            .addCase(validateNewLegalRapp.pending, (state) => {
                state.validateNewLegalRappStatus = 'loading'
            })
            .addCase(validateNewLegalRapp.fulfilled, (state) => {
                state.validateNewLegalRappStatus = 'successfully'
            })
            .addCase(validateNewLegalRapp.rejected, (state) => {
                state.validateNewLegalRappStatus = 'failed'
            })

            // ADD-ON GET
            .addCase(GetAddOnById.pending, (state) => {
                state.getAddOnByIdStatus = 'loading'
            })
            .addCase(GetAddOnById.fulfilled, (state, action) => {
                state.getAddOnByIdStatus = 'successfully'
                state.getAddOnByIdResponse = action.payload
            })
            .addCase(GetAddOnById.rejected, (state) => {
                state.getAddOnByIdStatus = 'failed'
            })

            // ADD-ON GET ALL
            .addCase(GetAddOns.pending, (state) => {
                state.getAddOnsStatus = 'loading'
            })
            .addCase(GetAddOns.fulfilled, (state, action) => {
                state.getAddOnsStatus = 'successfully'
                state.getAddOnsResponse = action.payload
            })
            .addCase(GetAddOns.rejected, (state) => {
                state.getAddOnsStatus = 'failed'
            })

            // ADD-ON POST
            .addCase(PostAddOn.pending, (state) => {
                state.postAddOnStatus = 'loading'
            })
            .addCase(PostAddOn.fulfilled, (state, action) => {
                state.postAddOnStatus = 'successfully'
                state.postAddOnResponse = action.payload
            })
            .addCase(PostAddOn.rejected, (state) => {
                state.postAddOnStatus = 'failed'
            })

            // ADD-ON UPDATE
            .addCase(UpdateAddOn.pending, (state) => {
                state.updateAddOnStatus = 'loading'
            })
            .addCase(UpdateAddOn.fulfilled, (state) => {
                state.updateAddOnStatus = 'successfully'
            })
            .addCase(UpdateAddOn.rejected, (state) => {
                state.updateAddOnStatus = 'failed'
            })

            // ADD-ON DELETE
            .addCase(DeleteAddOn.pending, (state) => {
                state.deleteAddOnStatus = 'loading'
            })
            .addCase(DeleteAddOn.fulfilled, (state) => {
                state.deleteAddOnStatus = 'successfully'
            })
            .addCase(DeleteAddOn.rejected, (state) => {
                state.deleteAddOnStatus = 'failed'
            })

    },
})

export const {
    setSelectedTab,
    setRagSocialeAnagrafica,
    setCuaaAnagrafica,
    setPIvaAnagrafica,
    setAddressAnagrafica,
    setCodContabileAnagrafica,
    setCoordBancarieAnagrafica,
    setFormaGiuridicaAnagrafica,
    setCodDestinatarioAnagrafica,
    setRegioneAnagrafica,
    setProvinciaAnagrafica,
    setComuneAnagrafica,
    setCapAnagrafica,
    setRpCapAnagrafica,
    setRpRegioneAnagrafica,
    setRpAddressAnagrafica,
    setRpProvinciaAnagrafica,
    setRpComuneAnagrafica,
    setStartingDate,
    setEndingDate,
    setEntryReason,
    setExitReason,
    setSelectedCustomerId,
    setClientType,
    setOperatorGroup,
    setLeaderId,
    setCodeType,
    setExistingCode,
    //rp
    setFiscalCode,
    setSurname,
    setName,
    setSex,
    setBirthDate,
    setCountryRegion,
    setProvince,
    setCity,
    setContactAddress,
    setContactProvince,
    setContactCity,
    setContactZipCode,
    setPhone,
    setEmail,
    setFax,
    setActivationDate,
    setDeActivationDate,
    setAddress,
    setRappLegaleMobilePhone,
    setNote,
    setContactCountryRegion,
    setUserId,
    setIdsLegalRapp,
    //rubrica
    setContactPhone,
    setContactOfficePhone,
    setContactMobilePhone,
    setContactFax,
    setContactEmail,
    setContactPersonalEmail,
    setContactPec,
    setContactOtherEmail,
    setContactNote,

    //filters
    setHeadQuarterFilter,
    setStatusFilter,
    resetFilters,

    setMobilePhone,

    //centro aziendale
    resetIdsCorporateCenterResponse,
    setSelectedIDCorporateCenter,
    setCodeCorporateCenter,
    setRegioneCorporateCenter,
    setProvinciaCorporateCenter,
    setComuneCorporateCenter,
    setCapCorporateCenter,
    setAddressCorporateCenter,
    setReferenteCorporateCenter,
    setRecapitoCorporateCenter,
    setEmailCorporateCenter,
    setPecCorporateCenter,
    setLatCorporateCenter,
    setLongCorporateCenter,
    setActivationDateCorporateCenter,
    setDeactivationDateCorporateCenter,
    setDescriptionCorporateCenter,
    addCorporateCenter,
    listCorporateCenter,
    pushCorporateCenter,
    resetCreateCompanyCenterStatus,
    resetCreateCompanyCenterByIdStatus,
    resetCreateCompanyCenterByIdEditStatus,
    setIdsCorporateCenterResponse,
    setIdCorporateCenter,
    //utils
    setFindAllCustomersFiltersPage,
    setFindAllCustomersFiltersSearch,
    setFindAllCustomersFiltersNorm,
    setFindAllCustomersFiltersCompanyName,
    selectedUserId,
    resetCustomerByIdStatus,
    resetGetCustomersByCompanyName,
    resetCreateStatus,
    resetCreateRappLegaleStatus,
    resetGetRappLegaleByIdStatus,
    resetCustomerEditStatus,
    resetContactsStatus,
    removeCorporateCenter,
    //validate messages
    setAddMessageError,
    setCleanErrorMessages,
    //validate anagrafica
    setValidateNewCompanyName,
    setValidateNewLegalForm,
    setValidateNewVat,
    setValidateNewCuaa,
    setValidateNewAddress,
    setValidateHeadQuarters,
    setValidateNewNorm,
    setValidateNewActivationDate,
    setValidateNewCustomerStatus,
    // validateLegalRapp
    setValidateNewClientType,
    setValidateNewLegalRappFiscalCode,
    setValidateNewLegalRappSex,
    setValidateNewLegalRappBirthDate,
    setValidateNewLegalRappProvince,
    setValidateNewLegalRappCountryRegion,
    setValidateNewLegalRappCity,
    setValidateNewLegalRappContactAddress,
    setValidateNewLegalRappContactCountryRegion,
    setValidateNewLegalRappContactCity,
    setValidateNewLegalRappContactProvince,
    setValidateNewLegalRappStatus,
    //right bar
    resetGetCustomersByCodeStatus,
    setNormCustomer,
    setAuthorityOffices,
    setEntryOdc,
    setExitOdc,
    setTakeover,

    // Biological Activity
    setBiologicalActivity,
    setBiologicalActivityActivityByIndex,
    setBiologicalActivityCategoryByIndex,
    setBiologicalActivityProductsByIndex,
    addDefaultBiologicalActivity,

    //residuo zero
    setResiduoZeroType,
    setPercResiduoContr,
    setResiduoZeroReqAgg,
    setResiduoZeroTypology,
    //qualità sicura
    setSqProductionChain,
    //globalGap
    setGgn,
    setGlobalGapStandard,
    setGlobalGapEdizione,
    setGlobalGapApplicationField,
    setGlobalGapPlantCategory,
    setGlobalGapOpzioneCertIFA,
    setGlobalGapManipolazioneIFA,
    setGlobalGapPropParallele,
    setGlobalGapOpzioneCertCoC,
    setGlobalGapEtichettaturaCoC,
    setGlobalGapLicenzaGNNCoC,
    setGlobalGapTrasformazioneParzialeCoC,
    //prod integrata
    setProductSqnpiSubjectType,
    setProductSqnpiAdhesionType,
    //add-on
    setAddOnRequestModule,
    setAddOnRequestStartDate,
    setAddOnRequestEndDate,
    setAddOnRequestNotes,
    addAdOn,
    addCustomAddOn,
    resetAddOnRequest,
    removeAddOn,
    setAddOns,
    resetGetAddOnByIdStatus,
    resetPostAddOnStatus,
    setPostAddOnRequestStarting,
    setPostAddOnRequestEnding,
    setPostAddOnRequestNotes,
    setPostAddOnRequestModule,
    setPostAddOnRequestActive,
    setPostAddOnRequestCustomerDataId,
    resetUpdateAddOnStatus,
    setUpdateAddOnRequestStarting,
    setUpdateAddOnRequestEnding,
    setUpdateAddOnRequestNotes,
    setUpdateAddOnRequestModule,
    setUpdateAddOnRequestActive,
    setUpdateAddOnRequestCustomerDataId,
    resetDeleteAddOnStatus,
    setOnEditCustomer,
    resetGetAddOnsStatus,
    setOnSettingClientTypology,
    addEditLegalRapp,
    setEditLegalRapp,
    setSelectedLegalRapp,
    resetCreateEditRapprLegale,
    resetGetAllRappLegaleStatus,
    setOnEditRappLegalePopup,
    resetUpdateRappLegaleStatus,
    resetDeleteRappLegaleStatus,
    resetEditCompanyCenterStatus,

    setSelectedDocCustomerId

} = ClientiSlice.actions

export default ClientiSlice.reducer