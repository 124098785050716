import { EntryProtocolAttachmentDTO, EntryProtocolCreationUpdateDTO, EntryProtocolDTO, EntryProtocolObjectDTO, GetAllEntryProtocolAttachmentsDTO, GetAllEntryProtocolAttachmentsFiltersDTO, GetAllEntryProtocolsDTO, GetAllEntryProtocolsFiltersDTO, ReportRequestDTO, ReportRequestExcelDTO } from "./dto";
import { EntryProtocolServiceImpl } from "./serviceImpl";

export interface EntryProtocolService {
    
    // ENTRY PROTOCOL
    CreateEntryProtocol(data: EntryProtocolCreationUpdateDTO): Promise<string>
    UpdateEntryProtocol(data: FormData, id: string): Promise<string>
    DeleteEntryProtocol(id: string): Promise<void>
    GetAllEntryProtocols(filters: GetAllEntryProtocolsFiltersDTO): Promise<GetAllEntryProtocolsDTO>
    GetEntryProtocolById(id: string): Promise<EntryProtocolDTO>

    // ENTRY PROTOCOL FILE
    RemoveFileFromProtocol(protocolId: string): Promise<void>
    UploadAttachmentFile(data: FormData, id: string): Promise<void>

    // ENTRY PROTOCOL OBJECT
    CreateEntryProtocolObject(data: EntryProtocolObjectDTO): Promise<void>
    UpdateEntryProtocolObject(data: EntryProtocolObjectDTO, id: string): Promise<void>
    DeleteEntryProtocolObject(id: string): Promise<void>
    GetAllEntryProtocolObjects(): Promise<EntryProtocolObjectDTO[]>
    GetEntryProtocolObjectById(id: string): Promise<EntryProtocolObjectDTO>

    // ENTRY PROTOCOL ATTACHMENT
    CreateEntryProtocolAttachment(data: EntryProtocolAttachmentDTO): Promise<void>
    UpdateEntryProtocolAttachment(data: EntryProtocolAttachmentDTO, id: string): Promise<void>
    DeleteEntryProtocolAttachment(id: string): Promise<void>
    GetAllEntryProtocolAttachments(filters: GetAllEntryProtocolAttachmentsFiltersDTO): Promise<GetAllEntryProtocolAttachmentsDTO>
    GetEntryProtocolAttachmentById(id: string): Promise<EntryProtocolAttachmentDTO>
   
    //REPORT
    GetDistinta(filters: ReportRequestDTO): Promise<any>
    GetProtExcel(filters: ReportRequestExcelDTO): Promise<any>
}

export function NewEntryProtocolService(): EntryProtocolService {
    return new EntryProtocolServiceImpl();
}