import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import Toggle from "../../../ui/molecules/toggle";
import { normativaCategories } from "../../../utils";
import { EntryProtocolAttachmentUpdate, setUpdateEPAttBio, setUpdateEPAttCoding, setUpdateEPAttDeliberationGG26, setUpdateEPAttDeliberationM24, setUpdateEPAttDescription, setUpdateEPAttGenerics, setUpdateEPAttGlobalGap, setUpdateEPAttSqnpi, setUpdateEPDeliberationGeneric, setUpdateEPDeliberationSQNPI } from "../../protocol/entry/slice";

interface Props {
    close: () => void
}

export function PopupEntryEdit(props: Props) {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    return (
        <div>
            <div className="bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ borderTopWidth: 1 }}>

                <div className='pb-3' style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div className='pb-3' style={{ flex: '0.5' }}>
                        <Input
                            label={'Descrizione'}
                            placeholder="Specifica descrizione..."
                            
                            defaultValue={entryProtocolState.entryProtocolAttachmentUpdateRequest.description}
                            // value={}
                            onChangeText={(text) => {
                                dispatch(setUpdateEPAttDescription(text))
                            }}
                        />
                    </div>

                    <div className='pb-3' style={{ flex: '0.5' }}>
                        <Input
                            label={'Codifica'}
                            placeholder="Specifica codifica..."
                            value={entryProtocolState.entryProtocolAttachmentUpdateRequest.coding}
                            defaultValue={entryProtocolState.entryProtocolAttachmentUpdateRequest.coding}
                            // value={}
                            onChangeText={(text) => {
                                dispatch(setUpdateEPAttCoding(text))
                            }}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left mb-3">
                            <span className="input-label">Presente in Cartella Aziendale</span>
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='Agricoltura biologica'
                                checked={entryProtocolState.entryProtocolAttachmentUpdateRequest.bio}
                                onChange={(value) => { dispatch(setUpdateEPAttBio(value)) }}
                            />
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='Global G.A.P.'
                                checked={entryProtocolState.entryProtocolAttachmentUpdateRequest.globalGap}
                                onChange={(value) => { dispatch(setUpdateEPAttGlobalGap(value)) }}
                            />
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='SQNPI'
                                checked={entryProtocolState.entryProtocolAttachmentUpdateRequest.sqnpi}
                                onChange={(value) => { dispatch(setUpdateEPAttSqnpi(value)) }}
                            />
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='Generica'
                                onChange={(value) => { dispatch(setUpdateEPAttGenerics(value)) }}
                            />
                        </div>
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left mb-3">
                            <span className="input-label">Delibera</span>
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='M24 - Agricoltura Biologica'
                                checked={entryProtocolState.entryProtocolAttachmentUpdateRequest.deliberationM24}
                                onChange={(value) => { dispatch(setUpdateEPAttDeliberationM24(value)) }}
                            />
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='GG26 - Global G.A.P.'
                                checked={entryProtocolState.entryProtocolAttachmentUpdateRequest.deliberationGG26}
                                onChange={(value) => { dispatch(setUpdateEPAttDeliberationGG26(value)) }}
                            />
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='Delibera SQNPI'
                                checked={entryProtocolState.entryProtocolAttachmentUpdateRequest.deliberationGeneric}
                                onChange={(value) => { dispatch(setUpdateEPDeliberationSQNPI(value)) }}
                            />
                        </div>

                        <div className='mb-3'>
                            <Toggle
                                placeholder='Delibera generica'
                                checked={entryProtocolState.entryProtocolAttachmentUpdateRequest.deliberationSQNPI}
                                onChange={(value) => { dispatch(setUpdateEPDeliberationGeneric(value)) }}
                            />
                        </div>

                    </div>
                </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                />
                <div className="pl-2">
                    <Button
                        size="md"
                        variant="solid"
                        iconPosition="off"
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            if (entryProtocolState.entryProtocolAttachmentUpdateRequest.id) {
                                dispatch(EntryProtocolAttachmentUpdate({ body: entryProtocolState.entryProtocolAttachmentUpdateRequest, id: entryProtocolState.entryProtocolAttachmentUpdateRequest.id }))
                            }

                        }}
                    />
                </div>
            </div>
        </div>
    )
}