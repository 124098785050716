import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { CDXM026DTO, CDXM026DTOResponse, CDXM026ErrorsDTO, GetAllCDXM026ResponseDTO } from "./dataService/dto";
import { NewCDXM026Service } from "./dataService/service";

interface CDXM026State {
    CDXInstances: CDXM026DTOResponse[],

    // REQUEST
    CDXM026CreationRequest: CDXM026DTO,
    CDXM026UpdateRequest: CDXM026DTO
    CDXM026CompanyName?: string
    CDXM026IdToUpdate: string

    // REQUEST (ERROR)
    CDXM026Errors: CDXM026ErrorsDTO

    // STATUS
    CDXM026CreationStatus: PromiseStatuses
    CDXM026UpdateStatus: PromiseStatuses
    CDXM026DeleteStatus: PromiseStatuses
    getAllCDXM026Status: PromiseStatuses
    getCDXM026ByIdStatus: PromiseStatuses
    CDXM026ValidationStatus: PromiseStatuses

    // RESPONSE
    getAllCDXM026Response?: GetAllCDXM026ResponseDTO
    getCDXM026ByIdResponse?: CDXM026DTOResponse
}

const initialState: CDXM026State = {
    CDXInstances: [],

    // REQUEST
    CDXM026CreationRequest: {
        starting: new Date(),
        ending: null,
        protocolIdEntry: 'string',
        note: '',
        personalManagementId: '',
        active: true
    },
    CDXM026UpdateRequest: {
        starting: new Date(),
        ending: null,
        protocolIdEntry: 'string',
        note: '',
        active: true
    },
    CDXM026IdToUpdate: '',

    // REQUEST (ERROR)
    CDXM026Errors: {
        client: false,
        clientRelationshipType: false,
        operator: false,
        operatorRelationshipType: false,
        odc: false,
        protocolIdEntry: false
    },

    // STATUS
    CDXM026CreationStatus: 'idle',
    CDXM026UpdateStatus: 'idle',
    CDXM026DeleteStatus: 'idle',
    getAllCDXM026Status: 'idle',
    getCDXM026ByIdStatus: 'idle',
    CDXM026ValidationStatus: 'idle'
}

export const GetAllCDXM026 = createAsyncThunk(
    'PM/CDXM026/GetAll',
    async (request, thunkApi): Promise<GetAllCDXM026ResponseDTO> => {
        const CDXM026Service = NewCDXM026Service();

        return CDXM026Service.GetAllCDXM026().catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetCDXM026ById = createAsyncThunk(
    'PM/CDXM026/getById',
    async (id: string, thunkApi): Promise<CDXM026DTOResponse> => {
        const CDXM026Service = NewCDXM026Service();

        return CDXM026Service.GetCDXM026ById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CDXM026Creation = createAsyncThunk(
    'PM/CDXM026/create',
    async (body: CDXM026DTO, thunkApi): Promise<void> => {
        const CDXM026Service = NewCDXM026Service();

        return CDXM026Service.CreateCDXM026(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CDXM026Update = createAsyncThunk(
    'PM/CDXM026/update',
    async (request: {body: CDXM026DTO, id: string}, thunkApi): Promise<void> => {
        const CDXM026Service = NewCDXM026Service();

        return CDXM026Service.UpdateCDXM026(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CDXM026Delete = createAsyncThunk(
    'PM/CDXM026/delete',
    async (id: string, thunkApi): Promise<void> => {
        const CDXM026Service = NewCDXM026Service();

        return CDXM026Service.DeleteCDXM026(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CDXM026Validation = createAsyncThunk(
    'PM/CDXM026/Validate',
    async (request: CDXM026DTO, thunkApi): Promise<void> => {
        
        const ClientGroupPredicate = (): boolean => {
            return request.clientRelationshipType !== undefined ||
            request.client !== undefined
        }

        const OperatorGroupPredicate = (): boolean => {
            return request.operator !== undefined ||
            request.operatorRelationshipType !== undefined ||
            request.odc !== undefined
        }

        let isValid = true
        thunkApi.dispatch(resetCDXM026Errors())

        if((request.client !== undefined && OperatorGroupPredicate()) ||
             (request.client === undefined && !OperatorGroupPredicate())
        ) {
            thunkApi.dispatch(setValidateClient(true))
            isValid = false
        }

        if((request.clientRelationshipType !== undefined && OperatorGroupPredicate()) ||
            (request.clientRelationshipType === undefined && !OperatorGroupPredicate())
        ) {
            thunkApi.dispatch(setValidateClientRelationshipType(true))
            isValid = false
        }

        if((request.operator !== undefined && ClientGroupPredicate()) ||
            (request.operator === undefined && !ClientGroupPredicate())
        ) {
            thunkApi.dispatch(setValidateOperator(true))
            isValid = false
        }

        if((request.operatorRelationshipType !== undefined && ClientGroupPredicate()) ||
            (request.operatorRelationshipType === undefined && !ClientGroupPredicate())
        ) {
            thunkApi.dispatch(setValidateOperatorRelationshipType(true))
            isValid = false
        }

        if((request.odc !== undefined && ClientGroupPredicate()) ||
            (request.odc === undefined && !ClientGroupPredicate())
         ) {
            thunkApi.dispatch(setValidateOdc(true))
            isValid = false
        }

        if(ClientGroupPredicate() && OperatorGroupPredicate()) {
            thunkApi.dispatch(setValidateErrorLabel('Campi clienti CODEX e operatori non assoggettati non possono essere entrambi presenti'))
            isValid = false
        }
        
        if(request.protocolIdEntry === undefined) {
            thunkApi.dispatch(setValidateProtocolIdEntry(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

const PMCDXM026 = createSlice({
    name: 'PM/CDXM026Slice',
    initialState,
    reducers: {
        resetCDXM026Instances: (state) => {
            state.CDXInstances = []
        },
        addCDXM026Instance: (state, action) => {
            state.CDXInstances.push(action.payload)
        },
        setCDXM026Instances: (state, action) => {
            state.CDXInstances = action.payload
        },

        // REQUEST
        setCDXClient: (state, action) => {
            if(action.payload === '')
                state.CDXM026CreationRequest.client = undefined
            else
                state.CDXM026CreationRequest.client = action.payload
        },
        setCDXClientRelationshipType: (state, action) => {
            if(action.payload === '')
                state.CDXM026CreationRequest.clientRelationshipType = undefined
            else
            state.CDXM026CreationRequest.clientRelationshipType = action.payload
        },
        setCDXOperator: (state, action) => {
            if(action.payload === '')
                state.CDXM026CreationRequest.operator = undefined
            else
            state.CDXM026CreationRequest.operator = action.payload
        },
        setCDXOperatorRelationshipType: (state, action) => {
            if(action.payload === '')
                state.CDXM026CreationRequest.operatorRelationshipType = undefined
            else
            state.CDXM026CreationRequest.operatorRelationshipType = action.payload
        },
        setCDXSupervisory: (state, action) => {
            if(action.payload === '')
                state.CDXM026CreationRequest.odc = undefined
            else
            state.CDXM026CreationRequest.odc = action.payload
        },
        setCDXProtocolIdEntry: (state, action) => {
            state.CDXM026CreationRequest.protocolIdEntry = action.payload
        },
        setCDXStartingDate: (state, action) => {
            state.CDXM026CreationRequest.starting = action.payload
        },
        setCDXEndingDate: (state, action) => {
            state.CDXM026CreationRequest.ending = action.payload
        },
        setCDXNote: (state, action) => {
            state.CDXM026CreationRequest.note = action.payload
        },
        setCDXActive: (state, action) => {
            state.CDXM026CreationRequest.active = action.payload
        },
        setPMId: (state, action) => {
            state.CDXM026CreationRequest.personalManagementId = action.payload
        },
        resetCDXM026CreationRequest: (state) => {
            state.CDXM026CreationRequest = {
                client: undefined,
                clientRelationshipType: undefined,
                operator: undefined,
                operatorRelationshipType: undefined,
                odc: undefined,
                starting: new Date(),
                ending: null,
                protocolIdEntry: 'string',
                note: '',
                personalManagementId: state.CDXM026CreationRequest.personalManagementId,
                active: true
            }
        },

        // REQUEST (UPDATE)
        setCDXCompanyName: (state, action) => {
            state.CDXM026CompanyName = action.payload
        },
        setCDXIdToUpdate: (state, action) => {
            state.CDXM026IdToUpdate = action.payload
        },
        setUpdateCDXClient: (state, action) => {
            state.CDXM026UpdateRequest.client = action.payload
        },
        setUpdateCDXClientRelationshipType: (state, action) => {
            state.CDXM026UpdateRequest.clientRelationshipType = action.payload
        },
        setUpdateCDXOperator: (state, action) => {
            state.CDXM026UpdateRequest.operator = action.payload
        },
        setUpdateCDXOperatorRelationshipType: (state, action) => {
            state.CDXM026UpdateRequest.operatorRelationshipType = action.payload
        },
        setUpdateCDXSupervisory: (state, action) => {
            state.CDXM026UpdateRequest.odc = action.payload
        },
        setUpdateCDXProtocolIdEntry: (state, action) => {
            state.CDXM026UpdateRequest.protocolIdEntry = action.payload
        },
        setUpdateCDXStartingDate: (state, action) => {
            state.CDXM026UpdateRequest.starting = action.payload
        },
        setUpdateCDXEndingDate: (state, action) => {
            state.CDXM026UpdateRequest.ending = action.payload
        },
        setUpdateCDXNote: (state, action) => {
            state.CDXM026UpdateRequest.note = action.payload
        },
        setUpdateCDXActive: (state, action) => {
            state.CDXM026UpdateRequest.active = action.payload
        },
        resetCDXM026UpdateRequest: (state) => {
            state.CDXM026UpdateRequest = {
                client: undefined,
                clientRelationshipType: undefined,
                operator: undefined,
                operatorRelationshipType: undefined,
                odc: undefined,
                starting: new Date(),
                ending: null,
                protocolIdEntry: 'string',
                note: '',
                active: true
            }
        },

        // REQUEST (ERROR)
        setValidateClient: (state, action) => {
            state.CDXM026Errors.client = action.payload
        },
        setValidateClientRelationshipType: (state, action) => {
            state.CDXM026Errors.clientRelationshipType = action.payload
        },
        setValidateOperator: (state, action) => {
            state.CDXM026Errors.operator = action.payload
        },
        setValidateOperatorRelationshipType: (state, action) => {
            state.CDXM026Errors.operatorRelationshipType = action.payload
        },
        setValidateOdc: (state, action) => {
            state.CDXM026Errors.odc = action.payload
        },
        setValidateProtocolIdEntry: (state, action) => {
            state.CDXM026Errors.protocolIdEntry = action.payload
        },
        setValidateErrorLabel: (state, action) => {
            state.CDXM026Errors.errorLabel = action.payload
        },
        resetCDXM026Errors: (state) => {
            state.CDXM026Errors = {
                client: false,
                clientRelationshipType: false,
                operator: false,
                operatorRelationshipType: false,
                odc: false,
                protocolIdEntry: false,
                errorLabel: undefined
            }
        },

        // STATUS
        resetCDXM026CreationStatus: (state) => {
            state.CDXM026CreationStatus = 'idle'
        },
        resetCDXM026UpdateStatus: (state) => {
            state.CDXM026UpdateStatus = 'idle'
        },
        resetCDXM026DeleteStatus: (state) => {
            state.CDXM026DeleteStatus = 'idle'
        },
        resetGetAllCDXM026Status: (state) => {
            state.getAllCDXM026Status = 'idle'
        },
        resetGetCDXM026ByIdStatus: (state) => {
            state.getCDXM026ByIdStatus = 'idle'
        },
        resetCDXM026ValidationStatus: (state) => {
            state.CDXM026ValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder
            // GET ALL
            .addCase(GetAllCDXM026.pending, (state) => {
                state.getAllCDXM026Status = 'loading'
            })
            .addCase(GetAllCDXM026.fulfilled, (state, action) => {
                state.getAllCDXM026Status = 'successfully'
                state.getAllCDXM026Response = action.payload
            })
            .addCase(GetAllCDXM026.rejected, (state) => {
                state.getAllCDXM026Status = 'failed'
            })

            // GET BY ID
            .addCase(GetCDXM026ById.pending, (state) => {
                state.getCDXM026ByIdStatus = 'loading'
            })
            .addCase(GetCDXM026ById.fulfilled, (state, action) => {
                state.getCDXM026ByIdStatus = 'successfully'
                state.getCDXM026ByIdResponse = action.payload
            })
            .addCase(GetCDXM026ById.rejected, (state) => {
                state.getCDXM026ByIdStatus = 'failed'
            })

            // CREATION
            .addCase(CDXM026Creation.pending, (state) => {
                state.CDXM026CreationStatus = 'loading'
            })
            .addCase(CDXM026Creation.fulfilled, (state, action) => {
                state.CDXM026CreationStatus = 'successfully'
            })
            .addCase(CDXM026Creation.rejected, (state) => {
                state.CDXM026CreationStatus = 'failed'
            })

            // UPDATE
            .addCase(CDXM026Update.pending, (state) => {
                state.CDXM026UpdateStatus = 'loading'
            })
            .addCase(CDXM026Update.fulfilled, (state, action) => {
                state.CDXM026UpdateStatus = 'successfully'
            })
            .addCase(CDXM026Update.rejected, (state) => {
                state.CDXM026UpdateStatus = 'failed'
            })

            // DELETE
            .addCase(CDXM026Delete.pending, (state) => {
                state.CDXM026DeleteStatus = 'loading'
            })
            .addCase(CDXM026Delete.fulfilled, (state, action) => {
                state.CDXM026DeleteStatus = 'successfully'
            })
            .addCase(CDXM026Delete.rejected, (state) => {
                state.CDXM026DeleteStatus = 'failed'
            })

            // VALIDATION
            .addCase(CDXM026Validation.pending, (state) => {
                state.CDXM026ValidationStatus = 'loading'
            })
            .addCase(CDXM026Validation.fulfilled, (state, action) => {
                state.CDXM026ValidationStatus = 'successfully'
            })
            .addCase(CDXM026Validation.rejected, (state) => {
                state.CDXM026ValidationStatus = 'failed'
            })
    },
})

export const {
    resetCDXM026Instances,
    addCDXM026Instance,
    setCDXM026Instances,

    // REQUEST
    setCDXActive,
    setCDXClient,
    setCDXClientRelationshipType,
    setCDXEndingDate,
    setCDXNote,
    setCDXOperator,
    setCDXOperatorRelationshipType,
    setCDXStartingDate,
    setCDXSupervisory,
    setCDXProtocolIdEntry,
    setPMId,
    resetCDXM026CreationRequest,

    // REQUEST (UPDATE)
    setCDXCompanyName,
    setCDXIdToUpdate,
    setUpdateCDXActive,
    setUpdateCDXClient,
    setUpdateCDXClientRelationshipType,
    setUpdateCDXEndingDate,
    setUpdateCDXNote,
    setUpdateCDXOperator,
    setUpdateCDXOperatorRelationshipType,
    setUpdateCDXProtocolIdEntry,
    setUpdateCDXStartingDate,
    setUpdateCDXSupervisory,
    resetCDXM026UpdateRequest,

    // REQUEST (ERROR)
    setValidateClient,
    setValidateClientRelationshipType,
    setValidateOdc,
    setValidateOperator,
    setValidateOperatorRelationshipType,
    setValidateProtocolIdEntry,
    setValidateErrorLabel,
    resetCDXM026Errors,

    // STATUS
    resetCDXM026CreationStatus,
    resetCDXM026UpdateStatus,
    resetCDXM026DeleteStatus,
    resetGetAllCDXM026Status,
    resetGetCDXM026ByIdStatus,
    resetCDXM026ValidationStatus

} = PMCDXM026.actions

export default PMCDXM026.reducer