import axios from "axios";
import { RequirementDetailDTO, RequirementDTO } from "./dto";
import { RequirementService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class RequirementServiceImpl implements RequirementService {

    // REQUIREMENT

    public CreateRequirement(data: RequirementDTO): Promise<void> {
        return axios.post("/api/pm-requirement",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateRequirement(data: RequirementDTO, id: string): Promise<void> {
        return axios.put("/api/pm-requirement/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteRequirement(id: string): Promise<void> {
        return axios.delete("/api/pm-requirement/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllRequirements(): Promise<RequirementDTO[]> {
        return axios.get("/api/pm-requirement", {
            params: {
                page: 0,
                itemsPerPage: 0
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data.data)
    }

    public GetRequirementById(id: string): Promise<RequirementDTO> {
        return axios.get("/api/pm-requirement/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    // REQUIREMENT DETAIL

    public CreateRequirementDetail(data: RequirementDetailDTO): Promise<void> {
        return axios.post("/api/pm-requirement-detail",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateRequirementDetail(data: RequirementDetailDTO, id: string): Promise<void> {
        return axios.put("/api/pm-requirement-detail/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteRequirementDetail(id: string): Promise<void> {
        return axios.delete("/api/pm-requirement-detail/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllRequirementDetails(): Promise<RequirementDetailDTO[]> {
        return axios.get("/api/pm-requirement-detail", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data.data)
    }

    public GetRequirementDetailById(id: string): Promise<RequirementDetailDTO> {
        return axios.get("/api/pm-requirement-detail/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}