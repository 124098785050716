import { useEffect, useState } from 'react';
import RadioButtonGroup from '../../../../ui/molecules/RadioButtonGroup'
import './styles.scss'
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks';
import { addPMResource, resetPMResources, setTrainingAttendanceRegister, setTrainingCredits, setTrainingEndingDate, setTrainingHeadquarter, setTrainingOtherData, setTrainingParticipants, setTrainingProtocolHeadquarter, setTrainingProtocolNumber, setTrainingProtocolYear, setTrainingStartingDate, setTrainingTopicsCovered, setTrainingWebinar } from '../slice';
import { SelectCustom } from '../../../../ui/molecules/select';
import { headquartersOptions, OptionType, WebinarOptions, yearOptions } from '../../../../utils';
import Input from '../../../../ui/molecules/input';
import { format } from 'date-fns';
import { ActivityAdd } from './components/activity';
import { InternalTeachersAdd } from './components/intTeachers';
import { ExternalTeachersAdd } from './components/extTeachers';
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus } from '../../slice';
import { GetByIdAnagraphic, resetGetByIdStatus } from '../../boxAnagrafica/slice';
import MultiSelectCheckbox from '../../../../ui/molecules/multiSelectCheckbox';
import TextArea from '../../../../ui/molecules/textArea';
import { Calendar } from '../../../../ui/molecules/calendar';
import { ProtocolSearch } from '../../../../ui/molecules/ProtocolSearch';

export function TrainingPopupAdd() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const PMState = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)
    const [selectedOption, setSelectedOption] = useState<WebinarOptions | null>(WebinarOptions.WEBINAR);
    const [participants, setParticipants] = useState<OptionType[]>([])

    useEffect(() => {
        dispatch(resetPMResources())
        dispatch(GetAllPersonalManagement(PMState.filters))
    }, [])

    useEffect(() => {
        if (PMState.getAllPersonalManagementResourcesStatus === 'successfully' &&
            PMState.getAllPersonalManagementResourcesResponse !== undefined
        ) {
            dispatch(resetGetAllPersonalManagementResourcesStatus())
            const data = PMState.getAllPersonalManagementResourcesResponse.data
            for (let i = 0; i < data.length; i++) {
                dispatch(GetByIdAnagraphic(String(data[i].anagraphicId)))
            }
        }
    }, [PMState.getAllPersonalManagementResourcesStatus])

    useEffect(() => {
        if (anagraficaState.GetByIdAnagraphicStatus === 'successfully' &&
            anagraficaState.getByIdAnagraphicRequest !== undefined
        ) {
            dispatch(resetGetByIdStatus('idle'))
            const data = anagraficaState.getByIdAnagraphicRequest
            dispatch(addPMResource({ value: data.personalManagementId, label: `${data.name} ${data.surname}` }))
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])

    const handleOptionChange = (value: WebinarOptions) => {
        setSelectedOption(value);
        if (value === 'WEBINAR') {
            dispatch(setTrainingWebinar(true))
        }
        else {
            dispatch(setTrainingWebinar(false))
        }
    };

    return (
        <div className="popup">

            {/* FIRST ROW */}
            <div className='pb-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>

                <div style={{ flex: '0.15' }}>
                    <RadioButtonGroup
                        name={'webinar'}
                        options={Object.values(WebinarOptions)}
                        selectedValue={selectedOption}
                        onChange={handleOptionChange}
                        optionLabels={{
                            [WebinarOptions.WEBINAR]: 'Webinar',
                            [WebinarOptions.SEDE]: 'In Presenza',
                        }}
                    />
                </div>

                <div style={{ flex: '0.85' }}>
                    <Input
                        error={trainingState.trainingErrors.sede}
                        disabled={selectedOption === WebinarOptions.WEBINAR}
                        supportingText={trainingState.trainingErrors.sede ? 'Specifica sede' : undefined}
                        label={'Specifica sede'}
                        placeholder={'Specifica sede'}
                        value={trainingState.trainingCreationRequest.sede}
                        defaultValue={trainingState.trainingCreationRequest.sede}
                        onChangeText={(text) => { dispatch(setTrainingHeadquarter(text)) }}
                    />
                   
                </div>

            </div>

            {/* SECOND ROW */}
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>

                <div style={{ flex: '0.4' }}>
                    <div className="text-left">
                        <span className="input-label">Dal</span>
                    </div>
                    <Calendar
                        selected={trainingState.trainingCreationRequest.starting}
                        onChange={(date) => {
                            dispatch(setTrainingStartingDate(format(new Date(date), 'yyyy-MM-dd')))
                        }}
                    />
                </div>

                <div style={{ flex: '0.4' }}>
                    <div className="text-left">
                        <span className="input-label">Al</span>
                    </div>
                    <Calendar
                        selected={trainingState.trainingCreationRequest.ending}
                        onChange={(date) => {
                            dispatch(setTrainingEndingDate(format(new Date(date), 'yyyy-MM-dd')))
                        }}
                    />
                </div>

                <div style={{ flex: '0.2' }}>
                    <Input
                        error={trainingState.trainingErrors.credits}
                        supportingText={trainingState.trainingErrors.credits ? 'Crediti mancanti' : undefined}
                        label={'Crediti'}
                        placeholder="Specificare numero crediti"
                        value={trainingState.trainingCreationRequest.credits}
                        defaultValue={trainingState.trainingCreationRequest.credits}
                        onChangeText={(text) => { dispatch(setTrainingCredits(text)) }}
                    />
                </div>

            </div>

            {
                trainingState.trainingErrors.activities && (
                    <div className="gap-[8px] text-left bg-red-300" style={{ margin: '12px', borderRadius: '8px' }}>
                        <span className="text-red-700 fieldsetTitle" style={{ padding: '15px 12px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Attività o campi dell'attività mancanti!
                        </span>
                    </div>
                )
            }

            {/* THIRD ROW (ATTIVITA FORMATIVA) */}
            <ActivityAdd />

            {
                trainingState.trainingErrors.teacher && (
                    <div className="gap-[8px] text-left bg-red-300" style={{ margin: '12px', borderRadius: '8px' }}>
                        <span className="text-red-700 fieldsetTitle" style={{ padding: '15px 12px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Deve essere presente almeno un insegnante, che sia interno od esterno.
                        </span>
                    </div>
                )
            }

            {/* FOURTH ROW (DOCENTI INTERNI) */}
            <InternalTeachersAdd />

            {/* FIFTH ROW (DOCENTI ESTERNI) */}
            <ExternalTeachersAdd />

            {/* SIXTH ROW (PARTECIPANTI) */}
            <div className='pb-5 pt-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div style={{ flex: '1' }}>
                    <div className="text-left pb-1">
                        <span className="input-label">Partecipanti</span>
                    </div>
                    <MultiSelectCheckbox
                        error={trainingState.trainingErrors.participants}
                        errorLabel="Non ci sono partecipanti!"
                        onChange={(e) => {
                            setParticipants(e.value)
                            dispatch(setTrainingParticipants(e.value))
                        }}
                        value={participants}
                        option={trainingState.PMResources}
                        placeholder="Partecipanti"
                        selectAllLabel="Tutti"
                    />
                </div>
            </div>

            {/* SEVENTH ROW (PROTOCOLLO) */}
            <div className="text-left pt-5" style={{ flex: '0.5' }}>
                <span className="subtitle">{`Registro Presenze - CDX RPA`}</span>
            </div>
            <div className='pb-5 pt-5' style={{ gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                <ProtocolSearch
                    type="entry"
                    errorLabel={trainingState.trainingErrors.attendanceRegister ? 'Protocollo mancante' : undefined}
                    onSelect={(value) => dispatch(setTrainingAttendanceRegister(value))} 
                    />
            </div>

            {/* EIGHTH ROW (ARGOMENTI TRATTATI) */}
            <div className='pb-5 pt-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div style={{ flex: '1' }}>
                    <div className="text-left">
                        <span className="input-label">Argomenti trattati</span>
                    </div>
                    <TextArea
                        error={trainingState.trainingErrors.topicsCovered}
                        supportingText={trainingState.trainingErrors.topicsCovered ? 'Non è stato inserito alcun argomento' : undefined}
                        placeholder='Descrivi in dettaglio gli argomenti trattati'
                        onChangeText={(value) => dispatch(setTrainingTopicsCovered(value))} />
                </div>
            </div>

            {/* NINETH ROW (ALTRI DATI) */}
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                <div style={{ flex: '1' }}>
                    <div className="text-left">
                        <span className="input-label">Altri dati</span>
                    </div>
                    <TextArea onChangeText={(value) => dispatch(setTrainingOtherData(value))} />
                </div>
            </div>

        </div>
    )
}