import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import KeycloakReducer from '../keycloak/slice';
import LayoutReducer from '../../ui/layout/slice';
import UserReducer from '../../pages/users/slice';
import ClientiReducer from '../../pages/clienti/slice';
import BusinessTechinicianReducer from '../../pages/businessTechnician/slice';
import PersonalManagementReducer from '../../pages/gestionePersonale/slice'
import Re10AnagraficaSliceReducer from '../../pages/gestionePersonale/boxAnagrafica/slice'
import PMevaluationsReducer from '../../pages/gestionePersonale/boxValutazioni/slice'
import PMcontractsReducer from '../../pages/gestionePersonale/boxContratti/slice'
import PMotherDataReducer from '../../pages/gestionePersonale/boxOtherData/slice'
import PMCDXM026Reducer from '../../pages/gestionePersonale/boxCDXM026/slice'
import PMotherInfoReducer from '../../pages/gestionePersonale/boxOtherInfo/slice'
import PMFunctionsReducer from '../../pages/gestionePersonale/boxFunzioni/slice'
import PMrequirementsReducer from '../../pages/gestionePersonale/boxRequisiti/slice'
import PMtrainingReducer from '../../pages/gestionePersonale/boxFormazione/slice'
import EntryProtocolReducer from '../../pages/protocol/entry/slice'
import ObjectStorageReducer from '../../objectStorage/slice'
import DepartureProtocolReducer from '../../pages/protocol/departure/slice'
import DocumentManagementReducer from '../../pages/gestioneDocumenti/slice'
import DocumentReducer from '../../pages/gestioneDocumenti/uploadedDocuments/slice'
import CartellaAziendaleReducer from '../../pages/cartellaAziendale/slice'
import EntryAttachmentManagementReducer from '../../pages/gestioneAllegati/entry/dataService/slice'

export const store = configureStore({
    reducer: {
        keycloak: KeycloakReducer,
        layout: LayoutReducer,
        users: UserReducer,
        clienti: ClientiReducer,
        businessTechinician: BusinessTechinicianReducer,
        personalManagement: PersonalManagementReducer,
        PMAnagrafica: Re10AnagraficaSliceReducer,
        PMFunctions: PMFunctionsReducer,
        PMEvaluations: PMevaluationsReducer,
        PMContracts: PMcontractsReducer,
        PMOtherData: PMotherDataReducer,
        PMCDXM026: PMCDXM026Reducer,
        PMOtherInfo: PMotherInfoReducer,
        PMRequirement: PMrequirementsReducer,
        PMTraining: PMtrainingReducer,
        entryProtocol: EntryProtocolReducer,
        objectStorage: ObjectStorageReducer,
        departureProtocol: DepartureProtocolReducer,
        documentManagement: DocumentManagementReducer,
        document: DocumentReducer,
        cartellaAziendale: CartellaAziendaleReducer,
        entryAttachManagement: EntryAttachmentManagementReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

