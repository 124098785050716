import { Fragment } from "react/jsx-runtime";
import Input from "../../../ui/molecules/input";
import Button from "../../../ui/molecules/button";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { DepartureProtocolAttachmentCreation, DepartureProtocolObjAttValidation, GetAllDepartureProtocolAttachments, resetDepartureProtocolAttachmentCreationStatus, resetDepartureProtocolObjAttErrors, resetPPAttCreationRequest, setPPAttCoding, setPPAttDescription } from "../departure/slice";

interface Props {
    close: () => void
}

export function AddAttachmentPopupDeparture(props: Props) {
    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)

    useEffect(() => {
        if(departureProtocolState.departureProtocolObjAttValidationStatus === 'successfully') {
            dispatch(resetDepartureProtocolObjAttErrors())
            dispatch(DepartureProtocolAttachmentCreation(departureProtocolState.departureProtocolAttachmentCreationRequest))
        }
    }, [departureProtocolState.departureProtocolObjAttValidationStatus])

    useEffect(() => {
        if(departureProtocolState.departureProtocolAttachmentCreationStatus === 'successfully') {
            dispatch(resetDepartureProtocolAttachmentCreationStatus())
            dispatch(resetPPAttCreationRequest())
            dispatch(GetAllDepartureProtocolAttachments({
                itemsPerPage: 0,
                page: 0
            }))
            props.close();
        }
    }, [departureProtocolState.departureProtocolAttachmentCreationStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                {/* FIRST ROW */}
                <div className="pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={departureProtocolState.departureProtocolObjAttErrors.coding}
                            supportingText={departureProtocolState.departureProtocolObjAttErrors.coding ? 'Codifica mancante' : undefined}
                            label={'Codifica'}
                            placeholder="Specificare codifica..."
                            value={departureProtocolState.departureProtocolAttachmentCreationRequest.coding}
                            onChangeText={(text) => dispatch(setPPAttCoding(text))}
                            />
                    
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={departureProtocolState.departureProtocolObjAttErrors.description}
                            supportingText={departureProtocolState.departureProtocolObjAttErrors.description ? 'Descrizione mancante' : undefined}
                            label={'Descrizione'}
                            placeholder="Specificare descrizione..."
                            value={departureProtocolState.departureProtocolAttachmentCreationRequest.description}
                            onChangeText={(text) => dispatch(setPPAttDescription(text))}
                            />
                    
                    </div>
                </div>

            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetPPAttCreationRequest())
                        dispatch(resetDepartureProtocolObjAttErrors())
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Aggiungi"
                        color="orange"
                        onClick={() => {
                            dispatch(DepartureProtocolObjAttValidation(departureProtocolState.departureProtocolAttachmentCreationRequest))
                        }}
                        />
                </div>
            </div>

        </Fragment>
    )
}