import { CompanyFolderDetailBulkUpdateDTO, CompanyFolderDetailCreationDTO, CompanyFolderDetailDTO, CompanyFolderDetailUpdateDTO, GetAllCompanyFoldersFiltersDTO } from "./dto"
import { CartellaAziendaleServiceImpl } from "./serviceImpl"

export interface CartellaAziendaleService {
    CreateCompanyFolderDetail(data: CompanyFolderDetailCreationDTO): Promise<void>
    UpdateCompanyFolderDetail(data: CompanyFolderDetailUpdateDTO, id: string): Promise<void>
    UpdateAllCompanyFolderDetails(data: CompanyFolderDetailBulkUpdateDTO[]): Promise<void>
    GetAllCompanyFolderDetails(filters: GetAllCompanyFoldersFiltersDTO): Promise<CompanyFolderDetailDTO[]>
    GetCompanyFolderDetailById(id: string): Promise<CompanyFolderDetailDTO>
    DeleteCompanyFolderDetail(id: string): Promise<void>
}

export function NewCartellaAziendaleService (): CartellaAziendaleService {
    return new CartellaAziendaleServiceImpl()
}