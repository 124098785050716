import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { formatterDate, OptionType } from "../../../utils";
import { GetFileById, GetFileByIdToZip, resetGetFileByIdStatus, resetGetFileByIdToZipResponses, resetGetFileByIdToZipStatus, setGetFileByIdToZipRequest } from "../../../objectStorage/slice";
import { MagnifyingIcon } from "../../../ui/icons/magnifying";
import { DownloadIcon } from "../../../ui/icons/DownloadIcon";
import { GetAllDepartureProtocolAttachments, GetAllDepartureProtocolObjects, GetAllDepartureProtocols, resetGetAllDepartureProtocolAttachmentsStatus, resetGetAllDepartureProtocolObjectsStatus, resetGetAllDepartureProtocolsStatus, resetGetAllPPFilters, resetGetAllPPFiltersDocuments, setGetAllPPFilterAttachmentId, setGetAllPPFilterClient, setGetAllPPFilterObjectId, setGetAllPPFilterObjectSpec, setGetAllPPFilterProtocolDate } from "../../protocol/departure/slice";
import { Calendar } from "../../../ui/molecules/calendar";
import { SelectCustom } from "../../../ui/molecules/select";
import Input from "../../../ui/molecules/input";
import Button from "../../../ui/molecules/button";
import { downloadZip } from "../../../objectStorage/utils";

interface Props {
    selectedCustomer: string
}

export function DepartureDocuments(props: Props) {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const [objects, setObjects] = useState<OptionType[]>([])
    const [attachments, setAttachments] = useState<OptionType[]>([])
    const [fileName, setFileName] = useState<string | null>(null)
    const [protocolName, setProtocolName] = useState<string | undefined>(undefined)

    const handleOnDownload = (fileIds: string[]) => {
        dispatch(resetGetFileByIdToZipResponses())
        dispatch(setGetFileByIdToZipRequest(fileIds))
    }

    useEffect(() => {
        if (objectStorageState.getFileObjectByIdStatus === 'successfully') {
            // dispatch(setUpdatedFile(objectStorageState.getFileObjectByIdResponse))
        }

    }, [objectStorageState.getFileObjectByIdStatus])

    useEffect(() => {
        dispatch(setGetAllPPFilterClient(props.selectedCustomer))
        dispatch(GetAllDepartureProtocolObjects())
        dispatch(GetAllDepartureProtocolAttachments({
            itemsPerPage: 0,
            page: 0
        }))
    }, [])

    // useEffect(() => {
    //     if(departureProtocolState.getAllDepartureProtocolFilters.client)
    //         dispatch(GetAllDepartureProtocols(departureProtocolState.getAllDepartureProtocolFilters))
    // }, [departureProtocolState.getAllDepartureProtocolFilters.client])

    useEffect(() => {
        if (departureProtocolState.getAllDepartureProtocolsStatus === 'successfully') {
            dispatch(resetGetAllDepartureProtocolsStatus())
        }
    }, [departureProtocolState.getAllDepartureProtocolsStatus])

    useEffect(() => {
        if (departureProtocolState.getAllDepartureProtocolObjectsStatus === 'successfully' &&
            departureProtocolState.getAllDepartureProtocolObjectsResponse !== undefined
        ) {
            dispatch(resetGetAllDepartureProtocolObjectsStatus())
            const data = departureProtocolState.getAllDepartureProtocolObjectsResponse
            const newObjects: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setObjects(newObjects)
        }
    }, [departureProtocolState.getAllDepartureProtocolObjectsStatus])

    useEffect(() => {
        if (departureProtocolState.getAllDepartureProtocolAttachmentsStatus === 'successfully' &&
            departureProtocolState.getAllDepartureProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllDepartureProtocolAttachmentsStatus())
            const data = departureProtocolState.getAllDepartureProtocolAttachmentsResponse
            const newAtts: OptionType[] = data.data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setAttachments(newAtts)
        }
    }, [departureProtocolState.getAllDepartureProtocolAttachmentsStatus])

    useEffect(() => {
        if (objectStorageState.getFileByIdStatus === 'successfully') {
            dispatch(resetGetFileByIdStatus())
            const base64Data = objectStorageState.getFileByIdResponse?.base64;
            if (base64Data && base64Data !== '') {
                const byteCharacters = atob(base64Data);
                const byteNumbers = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
                const blob = new Blob([byteNumbers], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                // Apri una finestra popup con un titolo personalizzato
                const newWindow = window.open('', '_blank', 'width=800,height=600');
                if (newWindow) {
                    newWindow.document.title = fileName!;
                    const iframe = newWindow.document.createElement('iframe');
                    iframe.style.width = '100%';
                    iframe.style.height = '100%';
                    iframe.src = url;
                    newWindow.document.body.appendChild(iframe);
                }
            }
        }
    }, [objectStorageState.getFileByIdStatus]);

    useEffect(() => {
        if (objectStorageState.getFileByIdToZipRequest.length < 1) {
            if (objectStorageState.getFileByIdToZipResponses.length > 0) {
                const download = async () => await downloadZip(objectStorageState.getFileByIdToZipResponses, protocolName)
                download()
                    .finally(() => dispatch(resetGetFileByIdToZipResponses()))
            }
            return
        }

        // [request.length > 0]
        const fileId = objectStorageState.getFileByIdToZipRequest[0]
        dispatch(GetFileByIdToZip(fileId))
    }, [objectStorageState.getFileByIdToZipRequest])

    useEffect(() => {
        if (objectStorageState.getFileByIdToZipStatus === 'successfully') {
            dispatch(resetGetFileByIdToZipStatus())
            if (objectStorageState.getFileByIdToZipRequest.length > 0) {
                dispatch(setGetFileByIdToZipRequest(objectStorageState.getFileByIdToZipRequest.slice(1)))
            }
        }
    }, [objectStorageState.getFileByIdToZipStatus])

    const handleAtts = (subs: string[]) => {
        if (subs === null)
            return '';
        return subs.slice(0, -1)
            .map(s => `${s} - `)
            + subs[subs.length - 1]
    }

    return (
        <div>
            {/* FILTERS */}
            <div className="w-[80%] flex flex-col gap-[16px] filter" style={{ padding: '0px 24px 0px 24px' }}>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.2' }}>
                        <div className="text-left">
                            <span className="input-label">Data</span>
                        </div>
                        <Calendar
                            selected={departureProtocolState.getAllDepartureProtocolFilters.protocolDate}
                            onChange={(date) => dispatch(setGetAllPPFilterProtocolDate(date))}
                        />
                    </div>

                    <div style={{ flex: '0.2' }}>
                        <div className="text-left">
                            <span className="input-label">Oggetto</span>
                        </div>
                        <SelectCustom
                            key={departureProtocolState.getAllDepartureProtocolFilters.objectId || 'default'}
                            defaultValue={departureProtocolState.getAllDepartureProtocolFilters.objectId}
                            placeholder={'Seleziona Oggetto'}
                            options={objects}
                            onChange={(value) => dispatch(setGetAllPPFilterObjectId(value))}
                        />
                    </div>

                    <div style={{ flex: '0.2', paddingTop: '2px' }}>
                        <Input
                            label={'Specifiche Oggetto'}
                            placeholder="Specifiche"
                            onChangeText={(text) => { dispatch(setGetAllPPFilterObjectSpec(text)) }}
                        />
                    </div>

                    <div style={{ flex: '0.2' }}>
                        <div className="text-left">
                            <span className="input-label">Allegato</span>
                        </div>
                        <SelectCustom
                            key={departureProtocolState.getAllDepartureProtocolFilters.attachmentId || 'default'}
                            defaultValue={departureProtocolState.getAllDepartureProtocolFilters.attachmentId}
                            placeholder={'Seleziona Allegato'}
                            options={attachments}
                            onChange={(value) => dispatch(setGetAllPPFilterAttachmentId(value))}

                        />
                    </div>
                    <div style={{ alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <span>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={
                                    () => {
                                        dispatch(resetGetAllPPFiltersDocuments())
                                        dispatch(setGetAllPPFilterClient(props.selectedCustomer))
                                    }
                                }
                            />
                        </span>
                    </div>
                    <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <Button
                            size={"lg"}
                            iconPosition={"off"}
                            variant={"outline"}
                            label="Cerca"
                            color={"blue"}
                            onClick={() => dispatch(GetAllDepartureProtocols(departureProtocolState.getAllDepartureProtocolFilters))}
                        />
                    </div>
                </div>
            </div>

            {/* LISTING */}
            <div className="w-[100%]" style={{ padding: '0px 24px 0px 24px' }}>
                <div style={{ padding: '24px 0' }}>
                    <table id="users-table" className="w-[100%]">
                        <thead className="h-[52px] uppercase">
                            <tr>
                                <th style={{ borderTopLeftRadius: '8px' }}>Azione</th>
                                <th>Sede</th>
                                <th>Data</th>
                                <th>Anno</th>
                                <th>Numero</th>
                                <th>Nome Cliente</th>
                                <th>Oggetto</th>
                                <th>Oggetto Extra</th>
                                <th>Allegati</th>
                                <th>Protocollo Principale</th>
                                <th>Funzionario</th>
                                <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                departureProtocolState.getAllDepartureProtocolsResponse?.data.map((protocol, index) =>
                                    <tr
                                        key={index}
                                        style={{
                                            color: '#4D797F',
                                            background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                        }}
                                    >
                                        <td className="w-[30px]">
                                            <div className="flex justify-content-between align-items-center">
                                                <button onClick={() => {
                                                    setFileName(`${protocol.headQuarter} P${protocol.protocolNumber}`)
                                                    dispatch(GetFileById(protocol.fileId!))
                                                }}>
                                                    <MagnifyingIcon color={"Orange"} size={18} />
                                                </button>

                                                <button onClick={() => {
                                                    setProtocolName(`${protocol.headQuarter} P${protocol.protocolNumber}`)
                                                    handleOnDownload([...protocol.attachmentFile.map(attF => attF.fileInfo.fileId), protocol.fileId!])
                                                }}>
                                                    <DownloadIcon size="36" />
                                                </button>

                                                {/* <div className="pl-3 flex align-items-center">
                                                    <button onClick={() => { }}>
                                                        <TrashIcon color={"Orange"} size={0} />
                                                    </button>
                                                </div> */}
                                            </div>
                                        </td>
                                        <td>{protocol.headQuarter}</td>
                                        <td>{formatterDate(protocol.protocolDate)}</td>
                                        <td>{protocol.year}</td>
                                        <td>{protocol.protocolNumber}</td>
                                        <td>{protocol.receiver}</td>
                                        <td>{`${protocol.departureObject.description} [${protocol.departureObject.coding}]`}</td>
                                        <td>{protocol.objectSpec}</td>
                                        <td>
                                            <ul>{protocol.departureAttachments.length > 0 ?
                                                handleAtts(protocol.departureAttachments.map(att => `[${att.coding}]`)) : ''}
                                            </ul>
                                        </td>
                                        <td>{protocol.linkExistingProtocol}</td>
                                        <td>{`${protocol.user.name} ${protocol.user.surname}`}</td>
                                        <td></td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    {/* <Pagination
                        numbResults={usersState.getUsersResponse ? usersState.getUsersResponse?.total : 0}
                        detailsResults={'utenti'}
                        pages={usersState.getUsersResponse?.totalPage || 1}
                        currentPage={usersState.filters.page} setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))}
                    /> */}
                </div>
            </div>
        </div>
    )
}