import { useEffect, useReducer, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import Button from "../../../ui/molecules/button";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { addCDXM026Instance, CDXM026Delete, GetCDXM026ById, resetCDXM026CreationRequest, resetCDXM026DeleteStatus, resetCDXM026Errors, resetCDXM026Instances, resetCDXM026UpdateRequest, resetGetCDXM026ByIdStatus, setCDXCompanyName, setCDXIdToUpdate, setPMId, setUpdateCDXActive, setUpdateCDXClient, setUpdateCDXClientRelationshipType, setUpdateCDXEndingDate, setUpdateCDXNote, setUpdateCDXOperator, setUpdateCDXOperatorRelationshipType, setUpdateCDXProtocolIdEntry, setUpdateCDXStartingDate, setUpdateCDXSupervisory } from "./slice";
import { PopupForm } from "../../../ui/organisms/popupForm";
import { formatterDate, odc, optionsFormatter } from "../../../utils";
import { CDXM026PopupAdd } from "./boxPopupOptions/add";
import { GetCustomersById, resetCustomerByIdStatus } from "../../clienti/slice";
import { CDXM026DTO, CDXM026DTOResponse } from "./dataService/dto";
import { CDXM026PopupEdit } from "./boxPopupOptions/edit";
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus } from "../slice";

export function BoxCDXM026() {
    const dispatch = useAppDispatch()
    const CDXM026State = useAppSelector(state => state.PMCDXM026)
    const clientiState = useAppSelector(state => state.clienti)
    const PMState = useAppSelector(state => state.personalManagement)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [openPopupEdit, setOpenPopupEdit] = useState<boolean>(false)

    const onEditHandler = (CDX: CDXM026DTOResponse) => {
        if(CDX.client !== null && CDX.clientRelationshipType !== null) {
            dispatch(setCDXCompanyName(CDX.customerCompanyName))
            dispatch(setUpdateCDXClient(CDX.client!))
            dispatch(setUpdateCDXClientRelationshipType(CDX.clientRelationshipType))
        }
        else if(CDX.operator !== null && 
            CDX.operatorRelationshipType !== null &&
            CDX.odc !== null
        ) {
            dispatch(setUpdateCDXOperator(CDX.operator))
            dispatch(setUpdateCDXOperatorRelationshipType(CDX.operatorRelationshipType))
            dispatch(setUpdateCDXSupervisory(CDX.odc))
        }
        dispatch(setUpdateCDXActive(CDX.active))
        dispatch(setUpdateCDXEndingDate(CDX.ending))
        dispatch(setUpdateCDXNote(CDX.note))
        dispatch(setUpdateCDXStartingDate(CDX.starting))
        
        dispatch(setUpdateCDXProtocolIdEntry(CDX.protocolIdEntry !== undefined ? String(CDX.protocolIdEntry) : undefined))
        dispatch(setCDXIdToUpdate(CDX.id))
        setOpenPopupEdit(true)
    }

    useEffect(() => {
        if(PMState.currentPersonalManagementId) {
            dispatch(setPMId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [PMState.currentPersonalManagementId])

    useEffect(() => {
        dispatch(resetCDXM026Instances());
        if (PMState.getByIdPersonalManagementResourcesStatus === 'successfully' &&
            PMState.getByIdPersonalManagementResourcesResponse?.m026Ids) {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.m026Ids.length; index++) {
                dispatch(GetCDXM026ById(PMState.getByIdPersonalManagementResourcesResponse?.m026Ids[index].toString()))
            }
        }
    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(CDXM026State.getCDXM026ByIdStatus === 'successfully' &&
            CDXM026State.getCDXM026ByIdResponse !== undefined
        ) {
            const data = CDXM026State.getCDXM026ByIdResponse
            dispatch(addCDXM026Instance(data))
            dispatch(resetGetCDXM026ByIdStatus())
        }
    }, [CDXM026State.getCDXM026ByIdStatus])

    useEffect(() => {
        if(CDXM026State.CDXM026DeleteStatus === 'successfully') {
            dispatch(resetCDXM026DeleteStatus());
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [CDXM026State.CDXM026DeleteStatus])

    return (
        <div style={{padding: '24px'}}>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
                    <span id="title-eval-tab">CDX M026</span>
                    <Button 
                        size="md" 
                        iconPosition="off" 
                        variant="outline" 
                        color="orange" 
                        label="Aggiungi CDX M026" 
                        onClick={() => setOpenPopup(true)}
                    />
                </div>

                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px'}}>CLIENTE</th>
                            <th>REGIONE</th>
                            <th>COD. OP.</th>
                            <th>DATA INGRESSO</th>
                            <th>DATA USCITA</th>
                            <th>TIPOLOGIA RAPPORTO</th>
                            <th>DATA INIZIO</th>
                            <th>DATA FINE</th>
                            <th>PROTOCOLLO M026</th>
                            <th>NOTE</th>
                            <th>ODC</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {CDXM026State.CDXInstances.map(CDX =>
                            <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                            key={CDX.id}
                            >
                                <td className="text-neutral-700">
                                    {CDX.customerCompanyName || CDX.operator}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.countryRegion}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.clientCode}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.startingDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.endingDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.clientRelationshipType || CDX.operatorRelationshipType}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.starting)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.ending!)}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.protocolEntryInfo}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.note}
                                </td>
                                <td className="text-neutral-700">
                                    {optionsFormatter(CDX.odc!, odc)}
                                </td>
                                
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">

                                        <button onClick={() => {
                                            onEditHandler(CDX)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-5 flex align-items-center">
                                            <button onClick={() => dispatch(CDXM026Delete(CDX.id!))}>
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {
                openPopup && (
                    <PopupForm title="Aggiungi CDX M026" close={() => {
                        dispatch(resetCDXM026CreationRequest())
                        dispatch(resetCDXM026Errors())
                        setOpenPopup(false)
                        }}>
                        <CDXM026PopupAdd close={() => setOpenPopup(false)} />
                    </PopupForm>
                )
            }
            {
                openPopupEdit && (
                    <PopupForm title="Modifica CDX M026" close={() => {
                        dispatch(resetCDXM026UpdateRequest());
                        dispatch(resetCDXM026Errors())
                        setOpenPopupEdit(false)
                        }}>
                        <CDXM026PopupEdit close={() => setOpenPopupEdit(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}