import { ReactElement, ReactNode } from "react"
import { HomeIcon } from "../../icons/home"
import { UsersIcon } from "../../icons/users"
import { GestPersonaleIcon } from "../../icons/gestPersonaleIcon"
import { ProtocolIcon } from "../../icons/entryProtocolIcon"
import { DocumentIcon } from "../../icons/documentIcon"
import { AttachmentIcon } from "../../icons/AttachmentIcon"
import { FolderIcon } from "../../icons/FolderIcon"

interface MenuItemDetails {
    label: string;
    subMenu?: boolean;
    subMenuItem?: SubMenuItem[]
}

interface SubMenuItem {
    label: string;
    link: string;
    icon?: ReactNode
}

export enum MenuItems {
    DASHBOARD = 'DASHBOARD',
    USERS = 'USERS',
    CLIENTI = 'CLIENTI',
    TECNICO_AZIENDALE = 'TECNICO_AZIENDALE',
    GESTIONE_PERSONALE = 'GESTIONE_PERSONALE',
    PROTOCOLLO = 'PROTOCOLLO',
    GESTIONE_DOCUMENTI = 'GESTIONE_DOCUMENTI',
    GESTIONE_ALLEGATI = 'GESTIONE_ALLEGATI',
    CARTELLA_AZIENDALE = 'CARTELLA_AZIENDALE'
}

export const MenuItemsLabelMap = new Map<MenuItems, MenuItemDetails>([
    [MenuItems.DASHBOARD, { label: 'Dashboard' }],
    [MenuItems.USERS, { label: 'Utenti' }],
    [MenuItems.CLIENTI, { label: 'Clienti' }],
    [MenuItems.TECNICO_AZIENDALE, { label: 'Tecnico Aziendale' }],
    [
        MenuItems.GESTIONE_PERSONALE,
        {
            label: 'Gestione Personale',
            subMenu: true,
            subMenuItem: [
                { label: 'Elenco Personale', link: '/gestionePersonale', },
                { label: 'Formazione', link: '/formazione' }
            ]
        }
    ],
    [
        MenuItems.PROTOCOLLO,
        {
            label: 'Protocollo',
            subMenu: true,
            subMenuItem: [
                { label: 'Entrata', link: '/entryProtocol', },
                { label: 'Partenza', link: '/departureProtocol' },
                { label: 'Report', link: '/reportProtocol' }
            ]
        }
    ],
    [MenuItems.GESTIONE_DOCUMENTI, { label: 'Gestione Documenti' }],
    [
        MenuItems.GESTIONE_ALLEGATI,
        {
            label: 'Gestione Allegati',
            subMenu: true,
            subMenuItem: [
                { label: 'Entrata', link: '/gaEntrata', },
                { label: 'Partenza', link: '/gaPartenza' }
            ]
        }
    ],
    [MenuItems.CARTELLA_AZIENDALE, { label: 'Cartella Aziendale' }],
])

export const MenuItemsHrefMap = new Map<MenuItems, string>([
    [MenuItems.DASHBOARD, '/'],
    [MenuItems.USERS, '/users'],
    [MenuItems.CLIENTI, '/clienti'],
    [MenuItems.TECNICO_AZIENDALE, '/tecnico-aziendale'],
    [MenuItems.GESTIONE_PERSONALE, '/gestionePersonale'],
    [MenuItems.PROTOCOLLO, '/protocol'],
    [MenuItems.GESTIONE_DOCUMENTI, '/gestioneDocumenti'],
    [MenuItems.GESTIONE_ALLEGATI, '/gestioneAllegati'],
    [MenuItems.CARTELLA_AZIENDALE, '/cartellaAziendale'],
])

export const PathMenuItemMap = new Map<string, MenuItems>([
    ['dashboard', MenuItems.DASHBOARD],
    ['users', MenuItems.USERS],
    ['clienti', MenuItems.CLIENTI],
    ['tecnico_aziendale', MenuItems.TECNICO_AZIENDALE],
])

export function MenuItemsIconsMap(menuItem: MenuItems, color: string, size: number): ReactElement {
    const map = new Map<MenuItems, ReactElement>([
        [MenuItems.DASHBOARD, <HomeIcon color={color} size={size} />],
        [MenuItems.USERS, <UsersIcon color={color} size={size} />],
        [MenuItems.CLIENTI, <UsersIcon color={color} size={size} />],
        [MenuItems.TECNICO_AZIENDALE, <UsersIcon color={'#fff'} size={size} />],
        [MenuItems.GESTIONE_PERSONALE, <GestPersonaleIcon color={'#fff'} size={size} />],
        [MenuItems.PROTOCOLLO, <ProtocolIcon size={size} />],
        [MenuItems.GESTIONE_DOCUMENTI, <DocumentIcon size={size} />],
        [MenuItems.GESTIONE_ALLEGATI, <AttachmentIcon />],
        [MenuItems.CARTELLA_AZIENDALE, <FolderIcon />]
    ])

    return (map.get(menuItem)!)
}