import { Layout } from "../../../ui/layout";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { RightBar } from "../../../ui/organisms/rightBar";
import { AttachmentsListEntry } from "./list";
import { AttachmentRight } from "./right";

export function GestioneAllegatiEntrata() {
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Allegati', 'Entrata']}
            noPaddingLat
            headerLabel="Gestione Allegati Entrata"
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <AttachmentsListEntry />
                
                <div className="bg-brandPrimary-100" style={{ flex: 0.3, borderTopRightRadius: '20px' }}>
                    <RightBar>
                        <AttachmentRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
}