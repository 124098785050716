import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { EntryProtocolAttachmentDTO, EntryProtocolAttachmentFileDTO, EntryProtocolCreationUpdateDTO, EntryProtocolDTO, EntryProtocolErrorsDTO, EntryProtocolFileAndAttachmentRequestDTO, EntryProtocolObjAttErrorsDTO, EntryProtocolObjectDTO, GetAllEntryProtocolAttachmentsDTO, GetAllEntryProtocolAttachmentsFiltersDTO, GetAllEntryProtocolsDTO, GetAllEntryProtocolsFiltersDTO, ReportRequestDTO, ReportRequestExcelDTO } from "./dataService/dto";
import { NewEntryProtocolService } from "./dataService/service";
import { GetObjectByIdDTO } from "../../../objectStorage/dataService/dto";

interface EntryProtocolState {
    entryProtocols: EntryProtocolDTO[]

    // REQUEST
    entryProtocolCreationRequest: EntryProtocolCreationUpdateDTO
    entryProtocolUpdateRequest: EntryProtocolCreationUpdateDTO
    entryProtocolIdToUpdate: string
    fromCreationToUpdate: boolean

    entryProtocolObjectCreationRequest: EntryProtocolObjectDTO
    entryProtocolObjectUpdateRequest: EntryProtocolObjectDTO
    entryProtocolObjectIdToUpdate: string

    fileToUpdate: File | null
    updatedFile?: GetObjectByIdDTO
    entryProtocolUpdateFileAndAtt: EntryProtocolFileAndAttachmentRequestDTO[]
    updatedEntryProtocolFileAndAtts?: EntryProtocolAttachmentFileDTO[]

    entryProtocolAttachmentCreationRequest: EntryProtocolAttachmentDTO
    entryProtocolAttachmentUpdateRequest: EntryProtocolAttachmentDTO
    entryProtocolAttachmentIdToUpdate: string

    // ERROR (TO DO)
    entryProtocolErrors: EntryProtocolErrorsDTO
    entryProtocolObjAttErrors: EntryProtocolObjAttErrorsDTO

    // FILTERS
    getAllEntryProtocolFilters: GetAllEntryProtocolsFiltersDTO
    getAllEntryProtocolAttachmentsFilters: GetAllEntryProtocolAttachmentsFiltersDTO
    reportRequestFilters: ReportRequestDTO
    reportExcelFilters: ReportRequestExcelDTO

    // STATUS
    entryProtocolCreationStatus: PromiseStatuses
    entryProtocolUpdateStatus: PromiseStatuses
    entryProtocolDeleteStatus: PromiseStatuses
    getAllEntryProtocolsStatus: PromiseStatuses
    getEntryProtocolByIdStatus: PromiseStatuses

    entryProtocolObjectCreationStatus: PromiseStatuses
    entryProtocolObjectUpdateStatus: PromiseStatuses
    entryProtocolObjectDeleteStatus: PromiseStatuses
    getAllEntryProtocolObjectsStatus: PromiseStatuses
    getEntryProtocolObjectByIdStatus: PromiseStatuses

    entryProtocolRemoveFileStatus: PromiseStatuses

    entryProtocolValidationStatus: PromiseStatuses
    entryProtocolObjAttValidationStatus: PromiseStatuses

    entryProtocolAttachmentCreationStatus: PromiseStatuses
    entryProtocolAttachmentUpdateStatus: PromiseStatuses
    entryProtocolAttachmentDeleteStatus: PromiseStatuses
    getAllEntryProtocolAttachmentsStatus: PromiseStatuses
    getEntryProtocolAttachmentByIdStatus: PromiseStatuses

    entryProtocolUploadAttachmentFileStatus: PromiseStatuses

    // RESPONSE
    entryProtocolCreationResponse?: string
    entryProtocolUpdateResponse?: string

    getAllEntryProtocolsResponse?: GetAllEntryProtocolsDTO
    getEntryProtocolByIdResponse?: EntryProtocolDTO

    getAllEntryProtocolObjectsResponse?: EntryProtocolObjectDTO[]
    getEntryProtocolObjectByIdResponse?: EntryProtocolObjectDTO

    getAllEntryProtocolAttachmentsResponse?: GetAllEntryProtocolAttachmentsDTO
    getEntryProtocolAttachmentByIdResponse?: EntryProtocolAttachmentDTO
}

const initialState: EntryProtocolState = {
    entryProtocols: [],
    reportRequestFilters: {},
    reportExcelFilters: {},
    // REQUEST
    entryProtocolCreationRequest: {
        headQuarter: '',
        protocolDate: new Date(),
        region: '',
        sender: '',
        customerIds: [],
        entryObjectId: '',
        entryChannel: '',
        send: new Date(),
        usersVisibility: false,
        destinationOffice: [],
        entryAttachmentIds: [],
        userId: ''
    },
    entryProtocolUpdateFileAndAtt: [],
    entryProtocolUpdateRequest: {
        headQuarter: '',
        region: '',
        sender: '',
        customerIds: [],
        entryObjectId: '',
        entryChannel: '',
        send: new Date(),
        usersVisibility: false,
        destinationOffice: [],
        entryAttachmentIds: []
    },
    entryProtocolIdToUpdate: '',
    fromCreationToUpdate: false,
    fileToUpdate: null,

    entryProtocolErrors: {
        headQuarter: false,
        protocolDate: false,
        region: false,
        sender: false,
        entryObjectId: false,
        totalPage: false,
        entryChannel: false,
        send: false,
        destinationOffice: false
    },
    entryProtocolObjAttErrors: {
        coding: false,
        description: false
    },


    entryProtocolObjectCreationRequest: {
        description: '',
        coding: '',
        status: true
    },
    entryProtocolObjectUpdateRequest: {
        description: '',
        coding: '',
        status: true
    },
    entryProtocolObjectIdToUpdate: '',

    entryProtocolAttachmentCreationRequest: {
        description: '',
        coding: '',
        status: true,
        docM024: false,
        bio: false,
        globalGap: false,
        sqnpi: false,
        generics: false,
        deliberationM24: false,
        deliberationGG26: false,
        deliberationSQNPI: false,
        deliberationGeneric: false
    },
    entryProtocolAttachmentUpdateRequest: {
        description: '',
        coding: '',
        status: true,
        docM024: false,
        bio: false,
        globalGap: false,
        sqnpi: false,
        generics: false,
        deliberationM24: false,
        deliberationGG26: false,
        deliberationSQNPI: false,
        deliberationGeneric: false
    },
    entryProtocolAttachmentIdToUpdate: '',

    // ERRORS (TO DO)

    // FILTERS
    getAllEntryProtocolFilters: {
        itemsPerPage: '50',
        page: 0
    },
    getAllEntryProtocolAttachmentsFilters: {
        itemsPerPage: 0,
        page: 0
    },

    // STATUS
    entryProtocolCreationStatus: 'idle',
    entryProtocolUpdateStatus: 'idle',
    entryProtocolDeleteStatus: 'idle',
    getAllEntryProtocolsStatus: 'idle',
    getEntryProtocolByIdStatus: 'idle',

    entryProtocolObjectCreationStatus: 'idle',
    entryProtocolObjectUpdateStatus: 'idle',
    entryProtocolObjectDeleteStatus: 'idle',
    getAllEntryProtocolObjectsStatus: 'idle',
    getEntryProtocolObjectByIdStatus: 'idle',

    entryProtocolRemoveFileStatus: 'idle',

    entryProtocolValidationStatus: 'idle',
    entryProtocolObjAttValidationStatus: 'idle',

    entryProtocolAttachmentCreationStatus: 'idle',
    entryProtocolAttachmentUpdateStatus: 'idle',
    entryProtocolAttachmentDeleteStatus: 'idle',
    getAllEntryProtocolAttachmentsStatus: 'idle',
    getEntryProtocolAttachmentByIdStatus: 'idle',
    entryProtocolUploadAttachmentFileStatus: 'idle'
}

export const EntryProtocolCreation = createAsyncThunk(
    'protocol/Entry/Creation',
    async (body: EntryProtocolCreationUpdateDTO, thunkApi): Promise<string> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.CreateEntryProtocol(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolUpdate = createAsyncThunk(
    'protocol/Entry/Update',
    async (request:{body: FormData, id: string}, thunkApi): Promise<string> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.UpdateEntryProtocol(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolDelete = createAsyncThunk(
    'protocol/Entry/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.DeleteEntryProtocol(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllEntryProtocols = createAsyncThunk(
    'protocol/Entry/GetAll',
    async (filters: GetAllEntryProtocolsFiltersDTO, thunkApi): Promise<GetAllEntryProtocolsDTO> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.GetAllEntryProtocols(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetDistinta = createAsyncThunk(
    'protocol/Entry/GetDistinta',
    async (filters: ReportRequestDTO, thunkApi): Promise<any> => {
        const entryProtocolService = NewEntryProtocolService()
        return entryProtocolService.GetDistinta(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetProtExcel = createAsyncThunk(
    'protocol/Entry/GetProtExcel',
    async (filters: ReportRequestExcelDTO, thunkApi): Promise<any> => {
        const entryProtocolService = NewEntryProtocolService()
        return entryProtocolService.GetProtExcel(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetEntryProtocolById = createAsyncThunk(
    'protocol/Entry/GetById',
    async (id: string, thunkApi): Promise<EntryProtocolDTO> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.GetEntryProtocolById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolObjectCreation = createAsyncThunk(
    'protocol/Entry/Object/Creation',
    async (body: EntryProtocolObjectDTO, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.CreateEntryProtocolObject(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolObjectUpdate = createAsyncThunk(
    'protocol/Entry/Object/Update',
    async (request:{body: EntryProtocolObjectDTO, id: string}, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.UpdateEntryProtocolObject(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolObjectDelete = createAsyncThunk(
    'protocol/Entry/Object/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.DeleteEntryProtocolObject(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllEntryProtocolObjects = createAsyncThunk(
    'protocol/Entry/Object/GetAll',
    async (thunkApi): Promise<EntryProtocolObjectDTO[]> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.GetAllEntryProtocolObjects()
    },
)

export const GetEntryProtocolObjectById = createAsyncThunk(
    'protocol/Entry/Object/GetById',
    async (id: string, thunkApi): Promise<EntryProtocolObjectDTO> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.GetEntryProtocolObjectById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolAttachmentCreation = createAsyncThunk(
    'protocol/Entry/Attachment/Creation',
    async (body: EntryProtocolAttachmentDTO, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.CreateEntryProtocolAttachment(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolAttachmentUpdate = createAsyncThunk(
    'protocol/Entry/Attachment/Update',
    async (request:{body: EntryProtocolAttachmentDTO, id: string}, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.UpdateEntryProtocolAttachment(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolAttachmentDelete = createAsyncThunk(
    'protocol/Entry/Attachment/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.DeleteEntryProtocolAttachment(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllEntryProtocolAttachments = createAsyncThunk(
    'protocol/Entry/Attachment/GetAll',
    async (filters: GetAllEntryProtocolAttachmentsFiltersDTO, thunkApi): Promise<GetAllEntryProtocolAttachmentsDTO> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.GetAllEntryProtocolAttachments(filters)
    },
)

export const GetEntryProtocolAttachmentById = createAsyncThunk(
    'protocol/Entry/Attachment/GetById',
    async (id: string, thunkApi): Promise<EntryProtocolAttachmentDTO> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.GetEntryProtocolAttachmentById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolRemoveFile = createAsyncThunk(
    'protocol/Entry/RemoveFile',
    async (protocolId: string, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.RemoveFileFromProtocol(protocolId).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolUploadAttachmentFile = createAsyncThunk(
    'protocol/Entry/UploadAttFile',
    async (request: {data: FormData, id: string}, thunkApi): Promise<void> => {
        const entryProtocolService = NewEntryProtocolService()

        return entryProtocolService.UploadAttachmentFile(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EntryProtocolValidation = createAsyncThunk(
    'protocol/Entry/validation',
    async (request: EntryProtocolCreationUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetEntryProtocolErrors())

        if(request.headQuarter === '') {
            thunkApi.dispatch(setValidateHeadquarter(true))
            isValid = false
        }

        if(request.protocolDate === null) {
            thunkApi.dispatch(setValidateProtocolDate(true))
            isValid = false
        }

        if(request.region === '') {
            thunkApi.dispatch(setValidateRegion(true))
            isValid = false
        }

        if(request.sender === '') {
            thunkApi.dispatch(setValidateSender(true))
            isValid = false
        }

        if(request.entryObjectId === '') {
            thunkApi.dispatch(setValidateEntryObjectId(true))
            isValid = false
        }

        if(request.entryChannel === '') {
            thunkApi.dispatch(setValidateEntryChannel(true))
            isValid = false
        }

        if(request.totalPage === undefined) {
            thunkApi.dispatch(setValidateTotalPage(true))
            isValid = false
        }

        if(request.send === null) {
            thunkApi.dispatch(setValidateSend(true))
            isValid = false
        }

        if(request.destinationOffice.length < 1) {
            thunkApi.dispatch(setValidateDestinationOffice(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const EntryProtocolObjAttValidation = createAsyncThunk(
    'protocol/Entry/ObjAtt//validation',
    async (request: EntryProtocolAttachmentDTO | EntryProtocolObjectDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetEntryProtocolObjAttErrors())

        if(request.coding === '') {
            thunkApi.dispatch(setValidateCoding(true))
            isValid = false
        }

        if(request.description === '') {
            thunkApi.dispatch(setValidateDescription(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

const EntryProtocol = createSlice({
    name: 'protocol/entryProtocolSlice',
    initialState,
    reducers: {
        resetEntryProtocols: (state) => {
            state.entryProtocols = []
        },
        addEntryProtocol: (state, action) => {
            state.entryProtocols.push(action.payload)
        },
        setEntryProtocol: (state, action) => {
            state.entryProtocols = action.payload
        },

        // REQUEST (EP CREATION)
        setEPHeadquarter: (state, action) => {
            state.entryProtocolCreationRequest.headQuarter = action.payload
        },
        setEPProtocolDate: (state, action) => {
            state.entryProtocolCreationRequest.protocolDate = action.payload
        },
        setEPNation: (state, action) => {
            state.entryProtocolCreationRequest.nation = action.payload
        },
        setEPRegion: (state, action) => {
            state.entryProtocolCreationRequest.region = action.payload
        },
        setEPSender: (state, action) => {
            state.entryProtocolCreationRequest.sender = action.payload
        },
        setEPCustomerIds: (state, action) => {
            state.entryProtocolCreationRequest.customerIds = action.payload
        },
        setEPCustomersNotRegistered: (state, action) => {
            state.entryProtocolCreationRequest.customersNotRegistered = action.payload
        },
        setEPEntryObjectId: (state, action) => {
            state.entryProtocolCreationRequest.entryObjectId = action.payload
        },
        setEPObjectSpec: (state, action) => {
            state.entryProtocolCreationRequest.objectSpec = action.payload
        },
        setEPTotalPage: (state, action) => {
            state.entryProtocolCreationRequest.totalPage = action.payload
        },
        setEPEntryChannel: (state, action) => {
            state.entryProtocolCreationRequest.entryChannel = action.payload
        },
        setEPSend: (state, action) => {
            state.entryProtocolCreationRequest.send = action.payload
        },
        setEPUsersVisibility: (state, action) => {
            state.entryProtocolCreationRequest.usersVisibility = action.payload
        },
        setEPLinkExistingProtocol: (state, action) => {
            state.entryProtocolCreationRequest.linkExistingProtocol = action.payload
        },
        setEPDestinationOffice: (state, action) => {
            state.entryProtocolCreationRequest.destinationOffice = action.payload
        },
        setEPUserId: (state, action) => {
            state.entryProtocolCreationRequest.userId = action.payload
        },
        resetEntryProtocolCreationRequest: (state) => {
            state.entryProtocolCreationRequest = {
                headQuarter: '',
                protocolDate: new Date(),
                nation: undefined,
                region: '',
                sender: '',
                customerIds: [],
                customersNotRegistered: undefined,
                entryObjectId: '',
                objectSpec: undefined,
                totalPage: undefined,
                entryChannel: '',
                send: new Date(),
                usersVisibility: false,
                linkExistingProtocol: undefined,
                destinationOffice: [],
                entryAttachmentIds: []
            }
        },

        // REQUEST (EP UPDATE)
        setUpdateEPHeadquarter: (state, action) => {
            state.entryProtocolUpdateRequest.headQuarter = action.payload
        },
        setUpdateEPProtocolDate: (state, action) => {
            state.entryProtocolUpdateRequest.protocolDate = action.payload
        },  
        setUpdateEPNation: (state, action) => {
            state.entryProtocolUpdateRequest.nation = action.payload
        },
        setUpdateEPRegion: (state, action) => {
            state.entryProtocolUpdateRequest.region = action.payload
        },
        setUpdateEPSender: (state, action) => {
            state.entryProtocolUpdateRequest.sender = action.payload
        },
        setUpdateEPCustomerIds: (state, action) => {
            state.entryProtocolUpdateRequest.customerIds = action.payload
        },
        setUpdateEPCustomersNotRegistered: (state, action) => {
            state.entryProtocolUpdateRequest.customersNotRegistered = action.payload
        },
        setUpdateEPEntryObjectId: (state, action) => {
            state.entryProtocolUpdateRequest.entryObjectId = action.payload
        },
        setUpdateEPObjectSpec: (state, action) => {
            state.entryProtocolUpdateRequest.objectSpec = action.payload
        },
        setUpdateEPTotalPage: (state, action) => {
            state.entryProtocolUpdateRequest.totalPage = action.payload
        },
        setUpdateEPEntryChannel: (state, action) => {
            state.entryProtocolUpdateRequest.entryChannel = action.payload
        },
        setUpdateEPSend: (state, action) => {
            state.entryProtocolUpdateRequest.send = action.payload
        },
        setUpdateEPUsersVisibility: (state, action) => {
            state.entryProtocolUpdateRequest.usersVisibility = action.payload
        },
        setUpdateEPLinkExistingProtocol: (state, action) => {
            state.entryProtocolUpdateRequest.linkExistingProtocol = action.payload
        },
        setUpdateEPDestinationOffice: (state, action) => {
            state.entryProtocolUpdateRequest.destinationOffice = action.payload
        },
        setUpdateEPEntryAttachmentIds: (state, action) => {
            state.entryProtocolUpdateRequest.entryAttachmentIds = action.payload
        },
        addDefaultUpdateEPEntryAttachmentIds: (state) => {
            state.entryProtocolUpdateRequest.entryAttachmentIds.push('')
        },
        addUpdateEPEntryAttachmentId: (state, action) => {
            state.entryProtocolUpdateRequest.entryAttachmentIds.push(action.payload)
        },
        setUpdateEPEntryAttachmentIdsByIndex: (state, action) => {
            state.entryProtocolUpdateRequest.entryAttachmentIds[action.payload.index] = action.payload.data
        },
        setEntryProtocolIdToUpdate: (state, action) => {
            state.entryProtocolIdToUpdate = action.payload
        },
        setFromCreationToUpdate: (state, action) => {
            state.fromCreationToUpdate = action.payload
        },
        resetEntryProtocolUpdateRequest: (state) => {
            state.entryProtocolUpdateRequest = {
                headQuarter: '',
                nation: undefined,
                region: '',
                sender: '',
                customerIds: [],
                customersNotRegistered: undefined,
                entryObjectId: '',
                objectSpec: undefined,
                totalPage: undefined,
                entryChannel: '',
                send: new Date(),
                usersVisibility: false,
                linkExistingProtocol: undefined,
                destinationOffice: [],
                entryAttachmentIds: []
            }
        },

        // UPDATE FILE & ATT
        addEPFileAndAtt: (state, action) => {
            state.entryProtocolUpdateFileAndAtt.push(action.payload)
        },
        setEPFileAndAtt: (state, action) => {
            state.entryProtocolUpdateFileAndAtt = action.payload
        },
        resetEPFileAndAtt: (state) => {
            state.entryProtocolUpdateFileAndAtt = []
        },
        setUpdatedEPFileAndAtt: (state, action) => {
            state.updatedEntryProtocolFileAndAtts = action.payload
        },

        // FILE
        setFileToUpdate: (state, action) => {
            state.fileToUpdate = action.payload
        },
        setUpdatedFile: (state, action) => {
            state.updatedFile = action.payload
        },

        // ERRORS
        setValidateHeadquarter: (state, action) => {
            state.entryProtocolErrors.headQuarter = action.payload
        },
        setValidateProtocolDate: (state, action) => {
            state.entryProtocolErrors.protocolDate = action.payload
        },
        setValidateRegion: (state, action) => {
            state.entryProtocolErrors.region = action.payload
        },
        setValidateSender: (state, action) => {
            state.entryProtocolErrors.sender = action.payload
        },
        setValidateEntryObjectId: (state, action) => {
            state.entryProtocolErrors.entryObjectId = action.payload
        },
        setValidateEntryChannel: (state, action) => {
            state.entryProtocolErrors.entryChannel = action.payload
        },
        setValidateDestinationOffice: (state, action) => {
            state.entryProtocolErrors.destinationOffice = action.payload
        },
        setValidateTotalPage: (state, action) => {
            state.entryProtocolErrors.totalPage = action.payload
        },
        setValidateSend: (state, action) => {
            state.entryProtocolErrors.send = action.payload
        },
        resetEntryProtocolErrors: (state) => {
            state.entryProtocolErrors = {
                headQuarter: false,
                protocolDate: false,
                region: false,
                sender: false,
                entryObjectId: false,
                totalPage: false,
                entryChannel: false,
                send: false,
                destinationOffice: false
            }
        },

        // ERRORS (OBJ/ATT)
        setValidateCoding: (state, action) => {
            state.entryProtocolObjAttErrors.coding = action.payload
        },
        setValidateDescription: (state, action) => {
            state.entryProtocolObjAttErrors.description = action.payload
        },
        resetEntryProtocolObjAttErrors: (state) => {
            state.entryProtocolObjAttErrors = {
                coding: false,
                description: false
            }
        },

        // REQUEST (EP OBJ CREATION)
        setEPObjDescription: (state, action) => {
            state.entryProtocolObjectCreationRequest.description = action.payload
        },
        setEPObjCoding: (state, action) => {
            state.entryProtocolObjectCreationRequest.coding = action.payload
        },
        resetEPObjCreationRequest: (state) => {
            state.entryProtocolObjectCreationRequest = {
                description: '',
                coding: '',
                status: true
            }
        },

        // REQUEST (EP OBJ UPDATE)
        setUpdateEPObjDescription: (state, action) => {
            state.entryProtocolObjectUpdateRequest.description = action.payload
        },
        setUpdateEPObjCoding: (state, action) => {
            state.entryProtocolObjectUpdateRequest.coding = action.payload
        },
        setEPObjIdToUpdate: (state, action) => {
            state.entryProtocolObjectIdToUpdate = action.payload
        },
        resetEPObjUpdateRequest: (state) => {
            state.entryProtocolObjectUpdateRequest = {
                description: '',
                coding: '',
                status: true
            }
        },

        // REQUEST (EP ATT CREATION)
        setEPAttDescription: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.description = action.payload
        },
        setEPAttCoding: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.coding = action.payload
        },
        setEPAttDocM024: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.docM024 = action.payload
        },
        setEPAttBio: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.bio = action.payload
        },
        setEPAttGlobalGap: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.globalGap = action.payload
        },
        setEPAttSqnpi: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.sqnpi = action.payload
        },
        setEPAttGenerics: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.generics = action.payload
        },
        setEPAttDeliberationM24: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.deliberationM24 = action.payload
        },
        setEPAttDeliberationGeneric: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.deliberationGeneric = action.payload
        },
        setEPAttDeliberationGG26: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.deliberationGG26 = action.payload
        },
        setEPDeliberationSQNPI: (state, action) => {
            state.entryProtocolAttachmentCreationRequest.deliberationSQNPI = action.payload
        },
        resetEPAttCreationRequest: (state) => {
            state.entryProtocolAttachmentCreationRequest = {
                description: '',
                coding: '',
                status: true,
                docM024: false,
                bio: false,
                globalGap: false,
                sqnpi: false,
                generics: false,
                deliberationM24: false,
                deliberationGG26: false,
                deliberationSQNPI: false,
                deliberationGeneric: false
            }
        },

        // REQUEST (EP ATT UPDATE)
        setUpdateEPAttDescription: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.description = action.payload
        },
        setAttachtoUpdate: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest = action.payload
        },
        setUpdateEPAttCoding: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.coding = action.payload
        },
        setUpdateEPAttDocM024: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.docM024 = action.payload
        },
        setUpdateEPAttBio: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.bio = action.payload
        },
        setUpdateEPAttGlobalGap: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.globalGap = action.payload
        },
        setUpdateEPAttSqnpi: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.sqnpi = action.payload
        },
        setUpdateEPAttGenerics: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.generics = action.payload
        },
        setUpdateEPAttDeliberationM24: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.deliberationM24 = action.payload
        },
        setUpdateEPAttDeliberationGG26: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.deliberationGG26 = action.payload
        },
        setUpdateEPDeliberationSQNPI: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.deliberationSQNPI = action.payload
        },
        setUpdateEPDeliberationGeneric: (state, action) => {
            state.entryProtocolAttachmentUpdateRequest.deliberationGeneric = action.payload
        },
        setEPAttIdToUpdate: (state, action) => {
            state.entryProtocolAttachmentIdToUpdate = action.payload
        },
        resetEPAttUpdateRequest: (state) => {
            state.entryProtocolAttachmentUpdateRequest = {
                description: '',
                coding: '',
                status: true,
                docM024: false,
                bio: false,
                globalGap: false,
                sqnpi: false,
                generics: false,
                deliberationM24: false,
                deliberationGG26: false,
                deliberationSQNPI: false,
                deliberationGeneric: false
            }
        },

        // FILTERS
        setGetAllEPFilterHeadquarter: (state, action) => {
            state.getAllEntryProtocolFilters.headquarter = action.payload
        },
        setGetAllEPFilterNumber: (state, action) => {
            state.getAllEntryProtocolFilters.number = action.payload
        },
        setGetAllEPFilterYear: (state, action) => {
            state.getAllEntryProtocolFilters.year = action.payload
        },
        setGetAllEPFilterentryMeans: (state, action) => {
            state.getAllEntryProtocolFilters.entryMeans = action.payload
        },
        setGetAllEPFilterFrom: (state, action) => {
            state.getAllEntryProtocolFilters.from = action.payload
        },
        setGetAllEPFilterTo: (state, action) => {
            state.getAllEntryProtocolFilters.to = action.payload
        },
        setGetAllEPFilterProtocolDate: (state, action) => {
            state.getAllEntryProtocolFilters.protocolDate = action.payload
        },
        setGetAllEPFilterClient: (state, action) => {
            state.getAllEntryProtocolFilters.client = action.payload
        },
        setGetAllEPFilterSender: (state, action) => {
            state.getAllEntryProtocolFilters.sender = action.payload
        },
        setGetAllEPFilterAttachmentId: (state, action) => {
            state.getAllEntryProtocolFilters.attachmentId = action.payload
        },
        setGetAllEPFilterObjectId: (state, action) => {
            state.getAllEntryProtocolFilters.objectId = action.payload
        },
        setGetAllEPFilterObjectSpec: (state, action) => {
            state.getAllEntryProtocolFilters.objectSpec = action.payload !== '' ? action.payload : undefined
        },
        setGetAllEPFilterPage: (state, action) => {
            state.getAllEntryProtocolFilters.page = action.payload
        },
        resetGetAllEPFilters: (state) => {
            state.getAllEntryProtocolFilters = {
                number: '',
                client: '',
                sender: '',
                objectSpec: '',
                itemsPerPage: '50',
                page: 0
            }
        },

        // FILTERS ATTACHMENTS
        setGetAllEPAttItemsPerPage: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.itemsPerPage = action.payload
        },
        setGetAllEPAttSort: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.sort = action.payload
        },
        setGetAllEPAttOrder: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.order = action.payload
        },
        setGetAllEPAttPage: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.page = action.payload
        },
        setGetAllEPAttBio: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.bio = action.payload
        },
        setGetAllEPAttGenerics: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.generics = action.payload
        },
        setGetAllEPAttGlobalGap: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.globalGap = action.payload
        },
        setGetAllEPAttSqnpi: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.sqnpi = action.payload
        },
        setGetAllEPAttDeliberationGG26: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.deliberationGG26 = action.payload
        },
        setGetAllEPAttDeliberationM24: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.deliberationM24 = action.payload
        },
        setGetAllEPAttDeliberationGeneric: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.deliberationGeneric = action.payload
        },
        setGetAllEPAttDeliberationSQNPI: (state, action) => {
            state.getAllEntryProtocolAttachmentsFilters.deliberationSQNPI = action.payload
        },
        resetGetAllEPAtt: (state) => {
            state.getAllEntryProtocolAttachmentsFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        resetGetAllEPFiltersDocuments: (state) => {
            state.getAllEntryProtocolFilters = {
                number: '',
                sender: '',
                objectSpec: '',
                itemsPerPage: '50'
            }
        },
        // FILTERS REPORT
        setAttachmentsReport: (state, action) => {
            state.reportRequestFilters.attachments = action.payload
        },
        setHeadQuarterReport: (state, action) => {
            state.reportRequestFilters.headQuarterOffice = action.payload
        },
        setStartNumberReport: (state, action) => {
            state.reportRequestFilters.startProtocoloNumber = action.payload
        },
        setEndNumberReport: (state, action) => {
            state.reportRequestFilters.endProtocolNumber = action.payload
        },
        setEntryChannelReport: (state, action) => {
            state.reportRequestFilters.entryChannel = action.payload
        },
        setYearReport: (state, action) => {
            state.reportRequestFilters.year = action.payload
        },
        setDestinationOfficeReport: (state, action) => {
            state.reportRequestFilters.destinationOffice = action.payload
        },

        // FILTERS EXCEL 
        setHeadQuarterReportExcel: (state, action) => {
            state.reportExcelFilters.headQuarterOffice = action.payload
        },
        setFromReportExcel: (state, action) => {
            state.reportExcelFilters.from = action.payload
        },
        setToReportExcel: (state, action) => {
            state.reportExcelFilters.to = action.payload
        },
        
        // STATUS
        resetEntryProtocolCreationStatus: (state) => {
            state.entryProtocolCreationStatus = 'idle'
        },
        resetEntryProtocolUpdateStatus: (state) => {
            state.entryProtocolUpdateStatus = 'idle'
        },
        resetEntryProtocolDeleteStatus: (state) => {
            state.entryProtocolDeleteStatus = 'idle'
        },
        resetGetAllEntryProtocolsStatus: (state) => {
            state.getAllEntryProtocolsStatus = 'idle'
        },
        resetGetEntryProtocolByIdStatus: (state) => {
            state.getEntryProtocolByIdStatus = 'idle'
        },
        resetEntryProtocolObjectCreationStatus: (state) => {
            state.entryProtocolObjectCreationStatus = 'idle'
        },
        resetEntryProtocolObjectUpdateStatus: (state) => {
            state.entryProtocolObjectUpdateStatus = 'idle'
        },
        resetEntryProtocolObjectDeleteStatus: (state) => {
            state.entryProtocolObjectDeleteStatus = 'idle'
        },
        resetGetAllEntryProtocolObjectsStatus: (state) => {
            state.getAllEntryProtocolObjectsStatus = 'idle'
        },
        resetGetEntryProtocolObjectByIdStatus: (state) => {
            state.getEntryProtocolObjectByIdStatus = 'idle'
        },
        resetEntryProtocolAttachmentCreationStatus: (state) => {
            state.entryProtocolAttachmentCreationStatus = 'idle'
        },
        resetEntryProtocolAttachmentUpdateStatus: (state) => {
            state.entryProtocolAttachmentUpdateStatus = 'idle'
        },
        resetEntryProtocolAttachmentDeleteStatus: (state) => {
            state.entryProtocolAttachmentDeleteStatus = 'idle'
        },
        resetGetAllEntryProtocolAttachmentsStatus: (state) => {
            state.getAllEntryProtocolAttachmentsStatus = 'idle'
        },
        resetGetEntryProtocolAttachmentByIdStatus: (state) => {
            state.getEntryProtocolAttachmentByIdStatus = 'idle'
        },
        resetEntryProtocolValidationStatus: (state) => {
            state.entryProtocolValidationStatus = 'idle'
        },
        resetEntryProtocolObjAttValidationStatus: (state) => {
            state.entryProtocolObjAttValidationStatus = 'idle'
        },
        resetEntryProtocolRemoveFileStatus: (state) => {
            state.entryProtocolRemoveFileStatus = 'idle'
        },
        resetEntryProtocolUploadAttachmentFileStatus: (state) => {
            state.entryProtocolUploadAttachmentFileStatus = 'idle'
        }

    },
    extraReducers(builder) {
        builder

            // [ENTRY PROTOCOL]
            // GET ALL
            .addCase(GetAllEntryProtocols.pending, (state) => {
                state.getAllEntryProtocolsStatus = 'loading'
            })
            .addCase(GetAllEntryProtocols.fulfilled, (state, action) => {
                state.getAllEntryProtocolsStatus = 'successfully'
                state.getAllEntryProtocolsResponse = action.payload
            })
            .addCase(GetAllEntryProtocols.rejected, (state) => {
                state.getAllEntryProtocolsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetEntryProtocolById.pending, (state) => {
                state.getEntryProtocolByIdStatus = 'loading'
            })
            .addCase(GetEntryProtocolById.fulfilled, (state, action) => {
                state.getEntryProtocolByIdStatus = 'successfully'
                state.getEntryProtocolByIdResponse = action.payload
            })
            .addCase(GetEntryProtocolById.rejected, (state) => {
                state.getEntryProtocolByIdStatus = 'failed'
            })

            // CREATION
            .addCase(EntryProtocolCreation.pending, (state) => {
                state.entryProtocolCreationStatus = 'loading'
            })
            .addCase(EntryProtocolCreation.fulfilled, (state, action) => {
                state.entryProtocolCreationStatus = 'successfully'
                state.entryProtocolCreationResponse = action.payload
            })
            .addCase(EntryProtocolCreation.rejected, (state) => {
                state.entryProtocolCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(EntryProtocolUpdate.pending, (state) => {
                state.entryProtocolUpdateStatus = 'loading'
            })
            .addCase(EntryProtocolUpdate.fulfilled, (state, action) => {
                state.entryProtocolUpdateStatus = 'successfully'
                state.entryProtocolUpdateResponse = action.payload
            })
            .addCase(EntryProtocolUpdate.rejected, (state) => {
                state.entryProtocolUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(EntryProtocolDelete.pending, (state) => {
                state.entryProtocolDeleteStatus = 'loading'
            })
            .addCase(EntryProtocolDelete.fulfilled, (state) => {
                state.entryProtocolDeleteStatus = 'successfully'
            })
            .addCase(EntryProtocolDelete.rejected, (state) => {
                state.entryProtocolDeleteStatus = 'failed'
            })

            // [FILE] DELETE
            .addCase(EntryProtocolRemoveFile.pending, (state) => {
                state.entryProtocolRemoveFileStatus = 'loading'
            })
            .addCase(EntryProtocolRemoveFile.fulfilled, (state) => {
                state.entryProtocolRemoveFileStatus = 'successfully'
            })
            .addCase(EntryProtocolRemoveFile.rejected, (state) => {
                state.entryProtocolRemoveFileStatus = 'failed'
            })

            // [FILE ATTACHMENT] UPLOAD
            .addCase(EntryProtocolUploadAttachmentFile.pending, (state) => {
                state.entryProtocolUploadAttachmentFileStatus = 'loading'
            })
            .addCase(EntryProtocolUploadAttachmentFile.fulfilled, (state) => {
                state.entryProtocolUploadAttachmentFileStatus = 'successfully'
            })
            .addCase(EntryProtocolUploadAttachmentFile.rejected, (state) => {
                state.entryProtocolUploadAttachmentFileStatus = 'failed'
            })

            // VALIDATION
            .addCase(EntryProtocolValidation.pending, (state) => {
                state.entryProtocolValidationStatus = 'loading'
            })
            .addCase(EntryProtocolValidation.fulfilled, (state) => {
                state.entryProtocolValidationStatus = 'successfully'
            })
            .addCase(EntryProtocolValidation.rejected, (state) => {
                state.entryProtocolValidationStatus = 'failed'
            })

            // VALIDATION (Obj/Att)
            .addCase(EntryProtocolObjAttValidation.pending, (state) => {
                state.entryProtocolObjAttValidationStatus = 'loading'
            })
            .addCase(EntryProtocolObjAttValidation.fulfilled, (state) => {
                state.entryProtocolObjAttValidationStatus = 'successfully'
            })
            .addCase(EntryProtocolObjAttValidation.rejected, (state) => {
                state.entryProtocolObjAttValidationStatus = 'failed'
            })

            // [ENTRY PROTOCOL OBJECT]
            // GET ALL
            .addCase(GetAllEntryProtocolObjects.pending, (state) => {
                state.getAllEntryProtocolObjectsStatus = 'loading'
            })
            .addCase(GetAllEntryProtocolObjects.fulfilled, (state, action) => {
                state.getAllEntryProtocolObjectsStatus = 'successfully'
                state.getAllEntryProtocolObjectsResponse = action.payload
            })
            .addCase(GetAllEntryProtocolObjects.rejected, (state) => {
                state.getAllEntryProtocolObjectsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetEntryProtocolObjectById.pending, (state) => {
                state.getEntryProtocolObjectByIdStatus = 'loading'
            })
            .addCase(GetEntryProtocolObjectById.fulfilled, (state, action) => {
                state.getEntryProtocolObjectByIdStatus = 'successfully'
                state.getEntryProtocolObjectByIdResponse = action.payload
            })
            .addCase(GetEntryProtocolObjectById.rejected, (state) => {
                state.getEntryProtocolObjectByIdStatus = 'failed'
            })

            // CREATION
            .addCase(EntryProtocolObjectCreation.pending, (state) => {
                state.entryProtocolObjectCreationStatus = 'loading'
            })
            .addCase(EntryProtocolObjectCreation.fulfilled, (state) => {
                state.entryProtocolObjectCreationStatus = 'successfully'
            })
            .addCase(EntryProtocolObjectCreation.rejected, (state) => {
                state.entryProtocolObjectCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(EntryProtocolObjectUpdate.pending, (state) => {
                state.entryProtocolObjectUpdateStatus = 'loading'
            })
            .addCase(EntryProtocolObjectUpdate.fulfilled, (state) => {
                state.entryProtocolObjectUpdateStatus = 'successfully'
            })
            .addCase(EntryProtocolObjectUpdate.rejected, (state) => {
                state.entryProtocolObjectUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(EntryProtocolObjectDelete.pending, (state) => {
                state.entryProtocolObjectDeleteStatus = 'loading'
            })
            .addCase(EntryProtocolObjectDelete.fulfilled, (state) => {
                state.entryProtocolObjectDeleteStatus = 'successfully'
            })
            .addCase(EntryProtocolObjectDelete.rejected, (state) => {
                state.entryProtocolObjectDeleteStatus = 'failed'
            })

            // [ENTRY PROTOCOL ATTACHMENT]
            // GET ALL
            .addCase(GetAllEntryProtocolAttachments.pending, (state) => {
                state.getAllEntryProtocolAttachmentsStatus = 'loading'
            })
            .addCase(GetAllEntryProtocolAttachments.fulfilled, (state, action) => {
                state.getAllEntryProtocolAttachmentsStatus = 'successfully'
                state.getAllEntryProtocolAttachmentsResponse = action.payload
            })
            .addCase(GetAllEntryProtocolAttachments.rejected, (state) => {
                state.getAllEntryProtocolAttachmentsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetEntryProtocolAttachmentById.pending, (state) => {
                state.getEntryProtocolAttachmentByIdStatus = 'loading'
            })
            .addCase(GetEntryProtocolAttachmentById.fulfilled, (state, action) => {
                state.getEntryProtocolAttachmentByIdStatus = 'successfully'
                state.getEntryProtocolAttachmentByIdResponse = action.payload
            })
            .addCase(GetEntryProtocolAttachmentById.rejected, (state) => {
                state.getEntryProtocolAttachmentByIdStatus = 'failed'
            })

            // CREATION
            .addCase(EntryProtocolAttachmentCreation.pending, (state) => {
                state.entryProtocolAttachmentCreationStatus = 'loading'
            })
            .addCase(EntryProtocolAttachmentCreation.fulfilled, (state) => {
                state.entryProtocolAttachmentCreationStatus = 'successfully'
            })
            .addCase(EntryProtocolAttachmentCreation.rejected, (state) => {
                state.entryProtocolAttachmentCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(EntryProtocolAttachmentUpdate.pending, (state) => {
                state.entryProtocolAttachmentUpdateStatus = 'loading'
            })
            .addCase(EntryProtocolAttachmentUpdate.fulfilled, (state) => {
                state.entryProtocolAttachmentUpdateStatus = 'successfully'
            })
            .addCase(EntryProtocolAttachmentUpdate.rejected, (state) => {
                state.entryProtocolAttachmentUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(EntryProtocolAttachmentDelete.pending, (state) => {
                state.entryProtocolAttachmentDeleteStatus = 'loading'
            })
            .addCase(EntryProtocolAttachmentDelete.fulfilled, (state) => {
                state.entryProtocolAttachmentDeleteStatus = 'successfully'
            })
            .addCase(EntryProtocolAttachmentDelete.rejected, (state) => {
                state.entryProtocolAttachmentDeleteStatus = 'failed'
            })
    },
})

export const {
    resetEntryProtocols,
    addEntryProtocol,
    setEntryProtocol,

    // REQUEST (EP CREATION)
    setEPCustomerIds,
    setEPCustomersNotRegistered,
    setEPDestinationOffice,
    setEPEntryChannel,
    setEPEntryObjectId,
    setEPHeadquarter,
    setEPLinkExistingProtocol,
    setEPNation,
    setEPObjectSpec,
    setEPProtocolDate,
    setEPRegion,
    setEPSend,
    setEPSender,
    setEPTotalPage,
    setEPUsersVisibility,
    setEPUserId,
    resetEntryProtocolCreationRequest,

    // REQUEST (EP UPDATE)
    setUpdateEPCustomerIds,
    setUpdateEPCustomersNotRegistered,
    setUpdateEPProtocolDate,
    setUpdateEPDestinationOffice,
    setUpdateEPEntryAttachmentIds,
    setUpdateEPEntryAttachmentIdsByIndex,
    setUpdateEPEntryChannel,
    setUpdateEPEntryObjectId,
    setUpdateEPHeadquarter,
    setUpdateEPLinkExistingProtocol,
    setUpdateEPNation,
    setUpdateEPObjectSpec,
    setUpdateEPRegion,
    setUpdateEPSend,
    setUpdateEPSender,
    setUpdateEPTotalPage,
    setUpdateEPUsersVisibility,
    addDefaultUpdateEPEntryAttachmentIds,
    addUpdateEPEntryAttachmentId,
    setFromCreationToUpdate,
    setEntryProtocolIdToUpdate,
    resetEntryProtocolUpdateRequest,

    // UPDATE FILE & ATT
    addEPFileAndAtt,
    setEPFileAndAtt,
    resetEPFileAndAtt,
    setUpdatedEPFileAndAtt,

    // FILE
    setFileToUpdate,
    setUpdatedFile,

    // ERRORS
    setValidateDestinationOffice,
    setValidateEntryChannel,
    setValidateEntryObjectId,
    setValidateHeadquarter,
    setValidateProtocolDate,
    setValidateRegion,
    setValidateSender,
    setValidateTotalPage,
    setValidateSend,
    setValidateCoding,
    setValidateDescription,
    resetEntryProtocolErrors,
    resetEntryProtocolObjAttErrors,

    // REQUEST (OBJ ATT)
    setEPAttCoding,
    setEPAttDescription,
    setEPAttIdToUpdate,
    setEPObjCoding,
    setEPObjDescription,
    setEPObjIdToUpdate,
    setEPAttBio,
    setEPAttDeliberationGG26,
    setEPAttDeliberationM24,
    setEPAttDeliberationGeneric,
    setGetAllEPAttDeliberationGeneric,
    setUpdateEPDeliberationGeneric,
    setAttachtoUpdate,
    setEPAttDocM024,
    setEPAttGenerics,
    setEPAttGlobalGap,
    setEPAttSqnpi,
    setEPDeliberationSQNPI,
    setUpdateEPAttBio,
    setUpdateEPAttDeliberationGG26,
    setUpdateEPAttDeliberationM24,
    setUpdateEPAttDocM024,
    setUpdateEPAttGenerics,
    setUpdateEPAttGlobalGap,
    setUpdateEPAttSqnpi,
    setUpdateEPDeliberationSQNPI,
    setUpdateEPAttCoding,
    setUpdateEPAttDescription,
    setUpdateEPObjCoding,
    setUpdateEPObjDescription,
    resetEPAttCreationRequest,
    resetEPAttUpdateRequest,
    resetEPObjCreationRequest,
    resetEPObjUpdateRequest,

    // FILTERS
    setGetAllEPFilterAttachmentId,
    setGetAllEPFilterClient,
    setGetAllEPFilterFrom,
    setGetAllEPFilterHeadquarter,
    setGetAllEPFilterNumber,
    setGetAllEPFilterObjectId,
    setGetAllEPFilterObjectSpec,
    setGetAllEPFilterSender,
    setGetAllEPFilterTo,
    setGetAllEPFilterProtocolDate,
    setGetAllEPFilterYear,
    setGetAllEPFilterentryMeans,
    setGetAllEPFilterPage,
    resetGetAllEPFilters,
    setGetAllEPAttItemsPerPage,
    setGetAllEPAttOrder,
    setGetAllEPAttPage,
    setGetAllEPAttSort,
    setGetAllEPAttBio,
    setGetAllEPAttDeliberationGG26,
    setGetAllEPAttDeliberationM24,
    setGetAllEPAttDeliberationSQNPI,
    setGetAllEPAttGenerics,
    setGetAllEPAttGlobalGap,
    setGetAllEPAttSqnpi,
    resetGetAllEPAtt,

    //FILTERS REPORT
    setAttachmentsReport,
    setDestinationOfficeReport,
    setEndNumberReport,
    setEntryChannelReport,
    setHeadQuarterReport,
    setStartNumberReport,
    setYearReport,

    //FILTERS EXCEL
    setFromReportExcel,
    setHeadQuarterReportExcel,
    setToReportExcel,

    // STATUS
    resetEntryProtocolAttachmentCreationStatus,
    resetEntryProtocolAttachmentDeleteStatus,
    resetEntryProtocolAttachmentUpdateStatus,
    resetEntryProtocolCreationStatus,
    resetEntryProtocolDeleteStatus,
    resetEntryProtocolObjectCreationStatus,
    resetEntryProtocolObjectDeleteStatus,
    resetEntryProtocolObjectUpdateStatus,
    resetEntryProtocolUpdateStatus,
    resetGetAllEntryProtocolAttachmentsStatus,
    resetGetAllEntryProtocolObjectsStatus,
    resetGetAllEntryProtocolsStatus,
    resetGetEntryProtocolAttachmentByIdStatus,
    resetGetEntryProtocolByIdStatus,
    resetGetEntryProtocolObjectByIdStatus,
    resetEntryProtocolValidationStatus,
    resetEntryProtocolObjAttValidationStatus,
    resetEntryProtocolRemoveFileStatus,
    resetEntryProtocolUploadAttachmentFileStatus,
    resetGetAllEPFiltersDocuments

} = EntryProtocol.actions

export default EntryProtocol.reducer