import { useEffect, useState } from "react";
import { headquartersOptions, yearOptions } from "../../../utils";
import Input from "../input";
import { SelectCustom } from "../select";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetAllEntryProtocols, GetEntryProtocolById, resetGetAllEntryProtocolsStatus, resetGetEntryProtocolByIdStatus } from "../../../pages/protocol/entry/slice";
import { GetAllDepartureProtocols, GetDepartureProtocolById, resetGetAllDepartureProtocolsStatus, resetGetDepartureProtocolByIdStatus } from "../../../pages/protocol/departure/slice";
import './styles.scss'
import { RoundedCloseIcon } from "../../icons/CloseIcon";
import { NewEntryProtocolService } from "../../../pages/protocol/entry/dataService/service";
import { NewDepartureProtocolService } from "../../../pages/protocol/departure/dataService/service";

interface ProtocolData {
    name: string
    sender: string
    client: string[]
    region: string
    object: string
}

interface Props {
    type: 'entry' | 'departure'
    onSelect?: (value: string | undefined) => void
    defaultValue?: string
    errorLabel?: string
}

export function ProtocolSearch(props: Props) {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const [headquarter, setHeadquarter] = useState<string | undefined>(undefined)
    const [number, setNumber] = useState<string | undefined>(undefined)
    const [protocol, setProtocol] = useState<ProtocolData | null>(null)
    const EntryProtocolService = NewEntryProtocolService()
    const DepartureProtocolService = NewDepartureProtocolService()


    useEffect(() => {
        if(props.defaultValue) {
            if(props.type === 'entry')
                EntryProtocolService.GetEntryProtocolById(props.defaultValue)
                    .then(response => {
                        const data = response
                        const newProtocol: ProtocolData = {
                            name: `${data.headQuarter} E${data.protocolNumber}`,
                            sender: data.sender,
                            region: data.region,
                            client: data.customersInfo !== null ? [...data.customersInfo.map(c => c.companyName), data.customersNotRegistered!] : [],
                            object: data.entryObject.description + `[${data.entryObject.coding}]`
                        }
                        setProtocol(newProtocol)
                        setHeadquarter(data.headQuarter)
                        setNumber(data.protocolNumber)
                    })
            else
                DepartureProtocolService.GetDepartureProtocolById(props.defaultValue)
                    .then(response => {
                        const data = response
                        const newProtocol: ProtocolData = {
                            name: `${data.headQuarter} E${data.protocolNumber}`,
                            sender: data.receiver,
                            region: data.region,
                            client: data.customersInfo !== null ? [...data.customersInfo.map(c => c.companyName), data.customersNotRegistered!] : [],
                            object: data.departureObject.description + `[${data.departureObject.coding}]`
                        }
                        setProtocol(newProtocol)
                        setHeadquarter(data.headQuarter)
                        setNumber(data.protocolNumber)
                    })
        }
    }, [])

    useEffect(() => {
        if(headquarter === undefined || number === undefined){
            setProtocol(null)
            props.onSelect && props.onSelect(undefined)
            return
        }        
        
        if(props.type === 'entry')
            EntryProtocolService.GetAllEntryProtocols({headquarter: headquarter, number: number, itemsPerPage: '50', page: 0})
                .then(response => {
                    const data = response.data
                    if(data.length !== 1) {
                        setProtocol(null)
                        props.onSelect && props.onSelect(undefined)
                        return
                    }
                    const newProtocol: ProtocolData = {
                        name: `${data[0].headQuarter} E${data[0].protocolNumber}`,
                        sender: data[0].sender,
                        region: data[0].region,
                        client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                        object: data[0].entryObject.description + `[${data[0].entryObject.coding}]`
                    }
                    setProtocol(newProtocol)
                    props.onSelect && props.onSelect(data[0].id)
                        })
        else
            DepartureProtocolService.GetAllDepartureProtocols({itemsPerPage: '1', headquarter: headquarter, number: number, page: 0})
                .then(response => {
                    const data = response.data
                    if(data.length !== 1) {
                        setProtocol(null)
                        props.onSelect && props.onSelect(undefined)
                        return
                    }
                    const newProtocol: ProtocolData = {
                        name: `${data[0].headQuarter} D${data[0].protocolNumber}`,
                        sender: data[0].receiver,
                        region: data[0].region,
                        client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                        object: data[0].departureObject.description + `[${data[0].departureObject.coding}]`
                    }
                    setProtocol(newProtocol)
                    props.onSelect && props.onSelect(data[0].id)
                })
    }, [headquarter, number])

    return (
        <div>
            <div style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div style={{ flex: '0.5' }}>
                    <div className="text-left">
                        <span className="input-label">
                            {`Protocollo Richiesta Codex ${props.type === 'entry' ? 'Entrata' : 'Partenza'}`}
                        </span>
                    </div>
                    <SelectCustom
                        placeholder={'Seleziona sede...'}
                        options={headquartersOptions}
                        onChange={(value) => setHeadquarter(value)}
                    />
                </div>

              

                <div style={{ flex: '0.5' }}>
                    <Input
                        label={'Numero'}
                        placeholder="Specificare numero..."
                        onChangeText={(text) => setNumber(text === '' ? undefined : text)}
                    />
                </div>
            </div>

            {
                protocol && (
                    <div className="mt-4" style={{display: 'flex', alignItems: 'center'}}>

                        <div className="vertical-line" />

                        <div className="pr-5" style={{display: 'flex', gap: '24px'}}>
                            <div className="text-left">
                                <div className="protocol-bold-text">Protocollo trovato </div>
                                <div className="protocol-text">{protocol.name}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">{props.type === 'entry' ? 'Mittente ' : 'Destinatario '}</div>
                                <div className="protocol-text">{protocol.sender}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">Regione</div>
                                <div className="protocol-text">{protocol.region}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">Clienti </div>
                                <div className="protocol-text">{protocol.client.map(c => `${c} `)}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">Oggetto </div>
                                <div className="protocol-text">{protocol.object}</div>
                            </div>
                        </div>

                        <button onClick={() => {
                            setProtocol(null)
                            props.onSelect && props.onSelect(undefined)
                        }}>
                            <RoundedCloseIcon color="#166428"/>
                        </button>
                    </div>
                )
            }
            {
                props.errorLabel && (
                    <div>
                        <span className="input-supporting-text" style={{color: '#fd665b', justifyContent:'left', display:'flex'}}>{props.errorLabel}</span>
                    </div>
                )
            }
        </div>
    )
}