import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { formatterDate, normativa, optionsFormatter } from "../../../utils";
import { GetAllTraining, resetGetAllTrainingsStatus } from "./slice";

export function BoxFormazioneSingle() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const PMState = useAppSelector(state => state.personalManagement)

    useEffect(() => {
        dispatch(GetAllTraining({ itemsPerPage: 30, personalManagementId: PMState.currentPersonalManagementId }))

    }, [])

    useEffect(() => {
        if (trainingState.getAllTrainingsStatus === 'successfully' &&
            trainingState.getAllTrainingsResponse !== undefined
        ) {
            dispatch(resetGetAllTrainingsStatus())
        }
    }, [trainingState.getAllTrainingsStatus])

    const handleNorms = (subs: string[]) => {
        if (subs === null)
            return '';
        return subs.slice(0, -1)
            .map(s => `${optionsFormatter(s, normativa)} - `)
            + optionsFormatter(subs[subs.length - 1], normativa)!
    }

    return (
        <div style={{ padding: '24px' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <span id="title-eval-tab">Formazione</span>

                </div>
                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px' }}>Dal/Al</th>
                            <th>Crediti</th>
                            <th>Tipo di formazione</th>
                            <th>Normativa</th>
                            <th>Argomenti Trattati</th>
                            <th>Altri Dati</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            trainingState.getAllTrainingsResponse && trainingState.getAllTrainingsResponse.data.map((training) => {
                                return (
                                    <tr key={training.id}>
                                        <td className="text-box-formazione">
                                            <div>{formatterDate(training.starting)}</div>
                                            <div>{formatterDate(training.ending)}</div>
                                        </td>
                                        <td className="text-box-formazione">
                                            {training.credits}
                                        </td>
                                        <td className="text-box-formazione">
                                            {training.webinar ? 'Webinar' : 'In Presenza'}
                                        </td>
                                        <td className="text-box-formazione">
                                            <ul style={{ listStyleType: 'disc' }}>
                                                {
                                                    training.trainingActivities.map(t => (
                                                        <li>{handleNorms(t.norms!)}</li>
                                                    ))
                                                }
                                            </ul>
                                        </td>
                                        <td className="text-box-formazione">
                                            {training.topicsCovered}
                                        </td>

                                        <td className="text-box-formazione">
                                            {training.otherData}
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}