import { useNavigate } from "react-router-dom";
import { keycloak } from "../../lib/keycloak";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { BoxFormazione } from "./boxFormazione/boxFormazione";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Input from "../../ui/molecules/input";
import { GetAllTraining, resetGetAllTrainingFilters, setGetAllTrainingFiltersDate, setGetAllTrainingFiltersNorm, setGetAllTrainingFiltersPage, setGetAllTrainingFiltersParticipant, setGetAllTrainingFiltersType } from "./boxFormazione/slice";
import { MagnifyingIcon } from "../../ui/icons/magnifying";
import { SelectCustom } from "../../ui/molecules/select";
import { normativa, rolesOptions, trainingActivityOptions, webinarOptionsValue } from "../../utils";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { CalendarIcon } from "../../ui/icons/calendar";
import { Pagination } from "../../ui/organisms/pagination";
import { useEffect, useState } from "react";
import { Calendar } from "../../ui/molecules/calendar";

export function Formazione () {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(trainingState.trainingCreationStatus === 'successfully' &&
            trainingState.trainingUpdateStatus === 'successfully'
        ) {
            setShowSuccess(true)
        }
    }, [trainingState.trainingCreationStatus,
        trainingState.trainingUpdateStatus
    ])

    useEffect(() => {
        if(trainingState.trainingDeleteStatus === 'successfully') {
            setShowDeleteSuccess(true)
        }
    }, [trainingState.trainingDeleteStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
        if (showDeleteSuccess) {
            setTimeout(() => {
                setShowDeleteSuccess(false)
            }, 2000)
        }
    }, [showSuccess, showDeleteSuccess])
    
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Formazione dipendenti', 'Formazione']}
            noPaddingLat
            headerLabel="Formazione dipendenti"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Formazione" color={"orange"}
                        onClick={() => {
                            navigate('/nuovoFormazione')
                        }}
                    />

                </div>
            }
        >
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '0px 0px 0px 0px' }}>

                {/* FILTERS */}
                <div className="flex flex-row gap-[16px]" style={{backgroundColor: '#F2F7F7', padding: '12px'}}>
                    <div style={{display: 'flex', justifyContent:'left', width: '85%', alignItems: 'center'}}>
                        <div style={{width: '23%', paddingRight: 5}}>
                            <Input
                                placeholder="Cerca partecipante"
                                onChangeText={(text) =>
                                    dispatch(setGetAllTrainingFiltersParticipant(text))
                                }
                                startIcon={<MagnifyingIcon />}
                            />
                        </div>
                        <div style={{width: '14%', paddingRight: 5}}>
                            <Calendar
                                selected={trainingState.getAllTrainingFilters.date}
                                onClear={(date) => dispatch(setGetAllTrainingFiltersDate(date))}
                                onChange={(date) => {
                                    dispatch(setGetAllTrainingFiltersDate(format(new Date(date), 'yyyy-MM-dd')))
                                }}
                            />
                        </div>
                        <div style={{width: '12%', paddingRight: 5}}>
                            <SelectCustom
                                key={trainingState.getAllTrainingFilters.type || 'default'}
                                placeholder={'Tipologia'}
                                options={trainingActivityOptions}
                                value={trainingState.getAllTrainingFilters.type}
                                defaultValue={trainingState.getAllTrainingFilters.type}
                                onChange={(value) => {
                                    dispatch(setGetAllTrainingFiltersType(value))
                                }}
                            />
                        </div>
                        <div style={{width: '15%', paddingRight: 5}}>
                            <SelectCustom
                                key={trainingState.getAllTrainingFilters.norm || 'default'}
                                placeholder={'Norma di riferimento'}
                                options={normativa}
                                value={trainingState.getAllTrainingFilters.norm}
                                defaultValue={trainingState.getAllTrainingFilters.norm}
                                onChange={(value) => {
                                    dispatch(setGetAllTrainingFiltersNorm(value))
                                }}
                            />
                        </div>
                        <div style={{display: 'flex', gap: '12px'}}>
                            <Button
                                variant="outline"
                                color="dimgrey"
                                label="Cerca"
                                iconPosition="off"
                                size='md'
                                onClick={() => dispatch(GetAllTraining(trainingState.getAllTrainingFilters))}
                            />
                            <Button
                                size={"md"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={
                                    () => {
                                        dispatch(resetGetAllTrainingFilters())
                                    }
                                }
                            />
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'right'}}>

                        <Pagination
                            numbResults={trainingState.getAllTrainingsResponse?.total || 0}
                            pages={trainingState.getAllTrainingsResponse?.totalPage || 1}
                            currentPage={trainingState.getAllTrainingFilters.page || 0}
                            setPage={
                                (page) => {
                                    dispatch(setGetAllTrainingFiltersPage(page))
                                }
                            }

                        />
                    </div>
                </div>

                <div>
                    {
                        (showSuccess) &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>

                    }
                    {
                        (showDeleteSuccess) &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati eliminati correttamente.
                            </span>
                        </div>

                    }
                </div>

                <BoxFormazione />
            </div>
        
        </Layout>
    )
}