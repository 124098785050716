import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { PaperClipIcon } from "../../../ui/icons/PaperclipIcon"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { Pagination } from "../../../ui/organisms/pagination"
import { PopupForm } from "../../../ui/organisms/popupForm"
import Button from "../../../ui/molecules/button"
import { Alert } from "../../../ui/organisms/alert"
import { PopupEntryEdit } from "../popup/entryEdit"
import { DepartureProtocolAttachmentDelete, GetAllDepartureProtocolAttachments, resetDepartureProtocolAttachmentDeleteStatus, resetPPAttCreationRequest, resetPPAttRequestUpdate, setAttachtoUpdate, setGetAllPPAttPage } from "../../protocol/departure/slice"
import { DepartureProtocolAttachmentDTO } from "../../protocol/departure/dataService/dto"
import { PopupDepartureEdit } from "../popup/departureEdit"

export function AttachmentsListDeparture() {
    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [attToDelete, setAttToDelete] = useState<DepartureProtocolAttachmentDTO | undefined>(undefined)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [showSuccessDelete, setShowSuccessDelete] = useState<boolean>(false)
    const [showSuccessEdit, setShowSuccessEdit] = useState<boolean>(false)
    const [showSuccessCreate, setShowSuccessCreate] = useState<boolean>(false)

    useEffect(() => {
        dispatch(GetAllDepartureProtocolAttachments({ itemsPerPage: 30, page: departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page ? departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page : 0, order: false }))
    }, [departureProtocolState.getAllDepartureProtocolAttachmentsFilters])

    useEffect(() => {
        if (departureProtocolState.departureProtocolAttachmentCreationStatus == 'successfully') {
            dispatch(resetPPAttCreationRequest())
            setShowSuccessCreate(true)
            setTimeout(() => {
                setShowSuccessCreate(false)
            }, 2500);
            dispatch(GetAllDepartureProtocolAttachments({ itemsPerPage: 30, page: departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page ? departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page : 0, order: false }))
        }
    }, [departureProtocolState.departureProtocolAttachmentCreationStatus])

    useEffect(() => {
        if (departureProtocolState.departureProtocolAttachmentUpdateStatus == 'successfully') {
            dispatch(resetPPAttRequestUpdate())
            setShowPopup(false)
            setShowSuccessEdit(true)
            setTimeout(() => {
                setShowSuccessEdit(false)
            }, 2500);
            dispatch(GetAllDepartureProtocolAttachments({ itemsPerPage: 30, page: departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page ? departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page : 0, order: false }))
        }
    }, [departureProtocolState.departureProtocolAttachmentUpdateStatus])

    useEffect(() => {
        if (departureProtocolState.departureProtocolAttachmentDeleteStatus === 'successfully') {
            dispatch(resetDepartureProtocolAttachmentDeleteStatus())
            setShowSuccessDelete(true)
            setTimeout(() => {
                setShowSuccessDelete(false)
            }, 2500);
            setAttToDelete(undefined)
            dispatch(GetAllDepartureProtocolAttachments({ itemsPerPage: 30, page: departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page ? departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page : 0, order: false }))
        }
    }, [departureProtocolState.departureProtocolAttachmentDeleteStatus])

    return (
        <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{ flex: 0.7, padding: '0px 24px 24px 24px', borderTopRightRadius: '20px' }}>
            <div className="w-[100%]">
                {
                    (showSuccessCreate) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Allegato creato correttamente.
                        </span>
                    </div>
                }
                {
                    (showSuccessEdit) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Allegato modificato correttamente.
                        </span>
                    </div>
                }
                <div style={{ padding: '24px 0' }}>
                    <table id="users-table" className="w-[100%]">
                        <thead className="h-[52px] uppercase">
                            <tr>
                                <th style={{ borderTopLeftRadius: '8px', width: '5%' }}></th>
                                <th>Descrizione</th>
                                <th>Codifica</th>
                                <th>Presente in cartella aziendale</th>
                                <th>Delibera</th>
                                <th style={{ borderTopRightRadius: '8px', width: '5%' }}></th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                departureProtocolState.getAllDepartureProtocolAttachmentsResponse?.data.map((att, index) =>
                                    <tr
                                        key={index}
                                        style={{
                                            color: '#4D797F',
                                            background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                        }}
                                    >
                                        <td className="w-[30px]">
                                            <PaperClipIcon />
                                        </td>
                                        <td>{att.description}</td>
                                        <td>{att.coding}</td>
                                        <td>{att.status ? 'SI' : 'NO'}</td>
                                        <td>{`Delibera`}</td>
                                        <td className="w-[20px]">
                                            <div className="flex justify-content-between align-items-center">
                                                <button onClick={() => {
                                                    dispatch(setAttachtoUpdate(att))
                                                    setShowPopup(true)
                                                }}>
                                                    <EditIcon color={"Orange"} size={18} />
                                                </button>

                                                <div className="pl-2 flex align-items-center">
                                                    <button onClick={() => {
                                                        setAttToDelete(att)
                                                        setShowAlert(true)
                                                    }}>
                                                        <TrashIcon color={"Orange"} size={0} />
                                                    </button>
                                                </div>

                                            </div>
                                        </td>


                                    </tr>
                                )}
                        </tbody>
                    </table>
                    <Pagination
                        numbResults={departureProtocolState.getAllDepartureProtocolAttachmentsResponse?.total || 0}
                        pages={departureProtocolState.getAllDepartureProtocolAttachmentsResponse?.totalPage || 1}
                        currentPage={departureProtocolState.getAllDepartureProtocolAttachmentsFilters.page || 0}
                        setPage={
                            (page) => {
                                dispatch(setGetAllPPAttPage(page))
                            }
                        }

                    />
                </div>
            </div>
            {
                (showAlert && attToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare l'allegato?
                                </div>
                                <div className="mt-3 font-bold">
                                    {`${attToDelete.description} - [${attToDelete.coding}]`}
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => dispatch(DepartureProtocolAttachmentDelete(attToDelete.id!))}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
            {
                showPopup && (
                    <PopupForm title="Modifica Allegato" close={() => setShowPopup(false)}>
                        <PopupDepartureEdit close={() => setShowPopup(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}